import { distributorsData } from './distributors.data';
import type { IGetDistributorRequestDTO, IGetDistributorsParams, IUpdateDistributorParams } from './distributors.dto';
import {
  distributorDetailResponseToDistributorDetailForm,
  distributorResponseSalesForce,
  mapDtoToDistributorsDisplay,
} from './distributors.mappers';

export const distributorsService = {
  getDistributors: async (options: Partial<IGetDistributorsParams>) =>
    mapDtoToDistributorsDisplay(await distributorsData.getDistributors(options)),
  getDistributorDetail: async (options: IGetDistributorRequestDTO) =>
    distributorDetailResponseToDistributorDetailForm(await distributorsData.getDistributorDetail(options)),
  getExternalSalesForces: async (options: IGetDistributorRequestDTO) =>
    distributorResponseSalesForce(await distributorsData.getExternalSalesForces(options)),
  updateDistributor: (distributor: IUpdateDistributorParams) => distributorsData.updateDistributor(distributor),
};
