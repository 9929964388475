import type { IBannerForm } from 'core/model/interfaces/banners';
import type { ICustomFile } from 'core/model/interfaces/custom-file';

export const getImageArrayToService = (banners: Array<IBannerForm>): Array<ICustomFile> => {
  const imageList: Array<ICustomFile> = [];
  banners.forEach(banner => {
    imageList.push(banner.desktopImage);
    imageList.push(banner.mobileImage);
  });

  return imageList;
};
