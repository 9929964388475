import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { ICustomVisitDelivery, IDistributorForm } from 'core/model/interfaces/distributors';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useDistributorDetail = (branchCode?: string, distributorCode?: string) => {
  const [distributorDetail, setDistributorDetail] = useState<IDistributorForm | null>(null);
  const [visitDeliveryList, setVisitDeliveryList] = useState<Array<ICustomVisitDelivery>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchDistributorDetail = async () => {
      if (!branchCode || !distributorCode)
        throw new Error('branchCode and distributorCode should not be undefined or null');
      try {
        setLoading(true);
        const { distributorForm, visitDeliveryList } = await apiService.distributors.getDistributorDetail({
          branchCode,
          distributorCode,
        });
        setVisitDeliveryList(visitDeliveryList);
        setDistributorDetail(distributorForm);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo el detalle del operador'));
      } finally {
        setLoading(false);
      }
    };

    fetchDistributorDetail();

    return () => {
      setDistributorDetail(null);
    };
  }, [branchCode, distributorCode]);

  return { distributorDetail, visitDeliveryList, loading, error };
};
