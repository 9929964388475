import { itemsPerPage } from 'core/constants/pagination.constants';
import { staticPagesService } from 'core/services/static-pages/static-pages.service';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.helpers';
import { SLICE_NAMESPACE } from '../constants';
import type { IStaticPagesState } from '../types';

export const fetchStaticPages = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/fetchStaticPages`,
  async ({ page = 1 }: { page?: number }, { getState }) => {
    const {
      staticPages: {
        filters: { title },
      },
    } = getState();

    return staticPagesService.getStaticPages({ title, page, itemsPerPage });
  },
);

export const fetchStaticPagesReducer: TSliceExtraReducer<IStaticPagesState> = builder =>
  builder
    .addCase(fetchStaticPages.pending, state => {
      state.isPagesLoading = true;
    })
    .addCase(fetchStaticPages.rejected, state => {
      state.isPagesLoading = false;
    })
    .addCase(fetchStaticPages.fulfilled, (state, action) => {
      state.staticPages = action.payload.results;
      state.totalPages = action.payload.pagination.totalPages;
      state.currentPage = action.payload.pagination.currentPage;
      state.isPagesLoading = false;
    });
