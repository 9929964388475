import { itemsPerPage } from 'core/constants/pagination.constants';
import { INavigationState } from './types';

export const SLICE_NAMESPACE = 'navigation';

export const initialState: INavigationState = {
  navigationTrees: [],
  isLoading: false,
  pagination: {
    totalPages: 0,
    currentPage: 1,
    itemsPerPage,
    totalItems: 0,
  },
  query: '',
};
