import { ReactNode } from 'react';

import './customer-form-switch-container.scss';

interface ICustomerFormSwitchContainerProps {
  children: ReactNode;
}

export const CustomerFormSwitchContainer = ({ children }: ICustomerFormSwitchContainerProps) => (
  <div className="customer-form-switch-container">{children}</div>
);
