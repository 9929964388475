import './order-detail-info.scss';

interface IOrderDetailInfo {
  title: string;
  content: string;
  icon?: JSX.Element;
  variant?: 'column' | 'column-icon';
  contentClassName?: string;
}

export const OrderDetailInfo = ({ title, content, icon, variant, contentClassName }: IOrderDetailInfo) => (
  <div className={`order-detail__info ${variant ? `order-detail__info--${variant}` : ''}`}>
    <div className="order-detail__info-container">
      <span className="order-detail__info-title">
        {icon} <span>{title}</span>
      </span>
      <span className={`order-detail__info-content ${contentClassName}`}>{content}</span>
    </div>
  </div>
);
