import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import type {
  IDuplicateNavigationTreeResponse,
  IGetNavigationTreeListResponse,
  IGetNavigationTreeParams,
  IGetNavigationTreeResponse,
  IPostNavigationTreeBody,
  IPutNavigationTreeBody,
} from './navigation.dto';

const treesPath = '/gtm-classification-management/v1/trees';

export const navigationData = {
  getNavigationTrees: async (params: IGetNavigationTreeParams): Promise<IGetNavigationTreeListResponse> => {
    const {
      data: { data },
    } = await https.get<IServiceSuccess<IGetNavigationTreeListResponse>>(treesPath, {
      params,
    });

    return data;
  },

  getNavigationTreeDetailById: async (id: number): Promise<IGetNavigationTreeResponse> => {
    const {
      data: { data },
    } = await https.get<IServiceSuccess<IGetNavigationTreeResponse>>(`${treesPath}/${id}`);

    return data;
  },

  createNavigationTree: async (body: IPostNavigationTreeBody) => {
    await https.post(treesPath, body);
  },

  editNavigationTree: async (id: number, body: IPutNavigationTreeBody): Promise<void> => {
    await https.put<void>(`${treesPath}/${id}`, body);
  },

  deleteNavigationTree: async (id: number): Promise<void> => {
    await https.delete<void>(`${treesPath}/${id}`);
  },

  duplicateNavigationTree: async (id: number): Promise<IDuplicateNavigationTreeResponse> => {
    const {
      data: { data },
    } = await https.post<IServiceSuccess<IDuplicateNavigationTreeResponse>>(`${treesPath}/${id}/duplicate`);

    return data;
  },
};
