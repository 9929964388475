import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { IStaticPage } from 'core/model/interfaces/static-pages';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useStaticPage = (id: number) => {
  const [staticPage, setStaticPage] = useState<IStaticPage | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchStaticPageDetail = async () => {
      try {
        setLoading(true);
        const remoteStaticPage = await apiService.staticPages.getStaticPageById(id);
        if (remoteStaticPage) {
          setStaticPage(remoteStaticPage);
        } else {
          notification.error('Error obteniendo los detalles de la página estática');
        }
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo los detalles de la página estática'));
      } finally {
        setLoading(false);
      }
    };

    fetchStaticPageDetail();
  }, [id]);

  return {
    staticPage,
    loading,
    error,
  };
};
