import { Button } from '@alicorpdigital/dali-components-react';
import { navigationTreeRoutePath, productsRoutePath } from 'core/constants/routes.constants';
import { ETreeStateType } from 'core/model/enums/products.enum';
import type { IHierarchyTree } from 'core/model/interfaces/navigation/hierarchy.interface';
import { Link } from 'shared/components/link';

import './hierarchy-tree-header.scss';

interface IHierarchyTreeHeaderProps {
  hierarchyTree: IHierarchyTree;
  onPublish: () => Promise<void>;
  hadleOpenCreateCategoryModal: () => void;
}
export const HierarchyTreeHeader = ({
  onPublish,
  hierarchyTree,
  hadleOpenCreateCategoryModal,
}: IHierarchyTreeHeaderProps) => (
  <div className="hierarchy-tree-header">
    <div className="hierarchy-tree-header__back">
      <Link to={`${productsRoutePath}${navigationTreeRoutePath}`} icon="caret-left">
        Atrás
      </Link>
    </div>
    <h2 className="hierarchy-tree-header__title">{hierarchyTree.name}</h2>
    <div className="hierarchy-tree-header__actions">
      <p className="hierarchy-tree-header__state">
        Estado: <span className="hierarchy-tree-header__state--accent">{hierarchyTree.state}</span>
      </p>
      <div className="hierarchy-tree-header__actions__buttons">
        <Button
          onClick={onPublish}
          variant="secondary"
          disabled={
            hierarchyTree.state === ETreeStateType.PUBLISHED || hierarchyTree.state === ETreeStateType.PROCESSING
          }
        >
          Publicar Árbol
        </Button>
        <Button onClick={hadleOpenCreateCategoryModal}>Nueva categoría</Button>
      </div>
    </div>
  </div>
);
