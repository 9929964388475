import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@alicorpdigital/dali-components-react';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { OrderDetail as OrderDetailMPP } from '@insuma/mpp-ui/components/order-detail';
import { ordersRoutePath } from 'core/constants/routes.constants';
import { Link } from 'shared/components/link';
import { Loading } from 'shared/components/loading';
import { useOrderDetail } from '../hooks/use-order-detail.hooks';
import { getStatus } from '../orders.utils';
import { OrderDetailInfo } from './order-detail-info';

import './order-detail.scss';

export const OrderDetail = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const orderId = id ? Number(id) : undefined;

  const { orderDetail, loading, error } = useOrderDetail(orderId);

  useEffect(() => {
    if (error !== null) navigate(`${ordersRoutePath}/`);
  }, [error, navigate]);

  if (loading || !orderDetail) return <Loading />;

  return (
    <div className="order-detail">
      <div className="order-detail__link-wrapper">
        <Link to={ordersRoutePath}>
          <Icon size="sm" name="caret-left" />
          Atrás
        </Link>
      </div>
      <h2 className="order-detail__title-leading">Información de pedido</h2>

      <div className="order-detail__information row-container">
        <h4 className="order-detail__subtitle">Pedido Nº {orderDetail.orderCard.id}</h4>
        <div className="order-detail__grid">
          <OrderDetailInfo
            title="Contenido"
            content={`${orderDetail.orderCard.totalCountProducts}   ${
              orderDetail.orderCard.totalCountProducts !== 1 ? 'productos' : 'producto'
            }`}
            variant="column"
          />

          <OrderDetailInfo
            title="Fecha de compra"
            content={orderDetail.orderInfo.purchaseDate}
            icon={<Icon name="calendar" />}
            variant="column-icon"
          />

          <OrderDetailInfo
            title="Fecha de entrega"
            content={orderDetail.orderInfo.deliveryDate}
            icon={<Icon name="truck" />}
            variant="column-icon"
          />
        </div>
      </div>

      <div className="order-detail__basic-info row-container">
        <h4 className="order-detail__subtitle">Información básica</h4>
        <div className="order-detail__grid">
          <div
            className={`order-detail__status order-detail__status--${getStatus(orderDetail.orderStatusDetails.status)}`}
          >
            <OrderDetailInfo
              title="Estado del pedido"
              content={orderDetail.orderStatusDetails.status}
              variant="column"
              contentClassName={`order-detail__status order-detail__status--${getStatus(
                orderDetail.orderStatusDetails.status,
              )}`}
            />
          </div>

          <OrderDetailInfo
            title="Método de pago"
            content={orderDetail.orderStatusDetails.paymentMethod}
            variant="column"
          />

          <OrderDetailInfo
            title="Direccion de entrega"
            content={orderDetail.orderStatusDetails.deliveryAddress}
            variant="column"
          />
        </div>
      </div>
      <div className="order-detail__client row-container">
        <h4 className="order-detail__subtitle">Información de cliente</h4>
        <div className="order-detail__form-grid">
          <OrderDetailInfo title="Razón social" content={orderDetail.customer.businessName} variant="column" />
          <div className="order-detail__client-button-container">
            <Button
              variant="secondary"
              onClick={() => navigate(`/clientes/detalle/${orderDetail.customer.userId}`)}
              type="button"
              className="order-detail__client-button"
            >
              <div className="order-detail__client-button-icon">
                <Icon name="user" />
              </div>
              Ver cliente
            </Button>
          </div>
        </div>
      </div>
      <div className="order-detail__detail">
        <OrderDetailMPP orderDetail={orderDetail.orderCard} platform="BO" />
      </div>
    </div>
  );
};
