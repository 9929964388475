import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Form } from '@alicorpdigital/dali-components-react';
import type { IBannerListForm } from 'core/model/interfaces/banners';
import { DatePickerForm, DragAndDropImageUploaderForm } from 'shared/components/forms';

import './banner-form.scss';

interface IBannerFormProps {
  index: number;
  onSaveClick(index: number): void;
  onCancelClick(index: number): void;
}

export const BannerForm = ({ index, onCancelClick, onSaveClick }: IBannerFormProps) => {
  const {
    control,
    register,
    formState: { errors },
    trigger,
  } = useFormContext<IBannerListForm>();

  const banner = useWatch<IBannerListForm, 'banners'>({
    control,
    name: 'banners',
  })[index];

  const onSaveChanges = async () => {
    const hasentErrors = await trigger(`banners.${index}`);
    if (hasentErrors) onSaveClick(index);
  };

  const errorName = errors.banners?.[index]?.name;
  const errorDesktopImage = errors.banners?.[index]?.desktopImage?.url;
  const errorMobileImage = errors.banners?.[index]?.mobileImage?.url;
  const errorDateRange = errors.banners?.[index]?.dateRange;
  const errorViewOrder = errors.banners?.[index]?.viewOrder;
  const errorLink = errors.banners?.[index]?.link;

  return (
    <div className="banner-form">
      <Form.Group>
        <Form.Label>Nombre del banner</Form.Label>
        <div className="banner-form__banner-name-container">
          <Form.Input {...register(`banners.${index}.name`)} placeholder="Nombre de banner" />
        </div>
        {errorName && <Form.HelperText isInvalid={!!errorName}>{errorName.message}</Form.HelperText>}
      </Form.Group>

      <div className="banner-form__images-section">
        <Form.Group>
          <h3 className="banner-form__subtitle">Banner desktop</h3>
          <DragAndDropImageUploaderForm name={`banners.${index}.desktopImage`} />
          {errorDesktopImage && <Form.HelperText isInvalid>{errorDesktopImage.message}</Form.HelperText>}
          {!banner.desktopImage?.url && (
            <p className="banner-form__helper">*Las medidas máximas recomendadas son de 1980 x 532 px</p>
          )}
        </Form.Group>

        <Form.Group>
          <h3 className="banner-form__subtitle">Banner mobile</h3>
          <DragAndDropImageUploaderForm name={`banners.${index}.mobileImage`} />
          {errorMobileImage && <Form.HelperText isInvalid>{errorMobileImage.message}</Form.HelperText>}
          {!banner.mobileImage?.url && (
            <p className="banner-form__helper">*Las medidas máximas recomendadas son de 1980 x 532 px</p>
          )}
        </Form.Group>
      </div>

      <div className="banner-form__information">
        <h3 className="banner-form__subtitle">Información de banner</h3>
        <div className="banner-form__dateRange-container">
          <Form.Group>
            <Form.Label>Fecha de inicio - fecha final</Form.Label>
            <DatePickerForm
              name={`banners.${index}.dateRange`}
              minDate={new Date()}
              selectRange
              placeholder="dd/mm/aaaa - dd/mm/aaaa"
              hasIcon
              onBlurProp={() => trigger(`banners.${index}.dateRange`)}
              size="lg"
            />
            {errorDateRange && <Form.HelperText isInvalid={!!errorDateRange}>{errorDateRange.message}</Form.HelperText>}
          </Form.Group>
        </div>

        <div className="banner-form__view-order-container">
          <Form.Group>
            <Form.Label>Orden de vista</Form.Label>
            <Form.Input {...register(`banners.${index}.viewOrder`)} placeholder="Ejemplo: 1" type="number" />
            {errorViewOrder && <Form.HelperText isInvalid={!!errorViewOrder}>{errorViewOrder.message}</Form.HelperText>}
          </Form.Group>
        </div>
      </div>

      <div className="banner-form__link-container">
        <h3 className="banner-form__subtitle">Enlace</h3>
        <Form.Group>
          <Form.Label>Enlace al que dirige</Form.Label>
          <Form.Input {...register(`banners.${index}.link`)} placeholder="Ejemplo: www.insuma.com.pe" />
          {errorLink && <Form.HelperText isInvalid={!!errorLink}>{errorLink.message}</Form.HelperText>}
        </Form.Group>
      </div>

      <div className="banner-form__actions">
        <Button type="button" variant="secondary" onClick={onSaveChanges}>
          Aplicar
        </Button>

        <Button type="button" variant="tertiary" onClick={() => onCancelClick(index)}>
          Cancelar
        </Button>
      </div>
    </div>
  );
};
