export const decodeJWTToken = (token: string) => {
  const tokenParts = token.split('.');

  if (tokenParts.length !== 3) throw new Error('invalid token');
  try {
    const payload = JSON.parse(atob(tokenParts[1]));
    return payload;
  } catch (e) {
    return null;
  }
};
