import * as yup from 'yup';
import {
  numberFieldMaxError,
  numberFieldMinError,
  TEXT_FIELD_REQUIRED_ERROR,
  TEXT_NUMBER_INT,
  TEXT_NUMBER_TYPO_ERROR,
} from 'core/constants/error-messages.constants';

export const suggestedProductFormSchema = yup.object().shape({
  title: yup.string().trim().required(TEXT_FIELD_REQUIRED_ERROR),
  maxSuggestions: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .typeError(TEXT_NUMBER_TYPO_ERROR)
    .required(TEXT_FIELD_REQUIRED_ERROR)
    .integer(TEXT_NUMBER_INT)
    .min(1, numberFieldMinError(1))
    .max(50, numberFieldMaxError(50)),
  type: yup.string().trim().required(TEXT_FIELD_REQUIRED_ERROR),
});
