import type { IExportsFilters } from 'core/model/interfaces/exports';
import type { TSliceReducer } from 'core/store';
import type { IExportsState } from './types';

const updateExportsFilters: TSliceReducer<IExportsState, Partial<IExportsFilters>> = (state, action) => {
  state.currentPage = 1;
  state.filters = {
    ...state.filters,
    ...action.payload,
  };
};
export const reducers = { updateExportsFilters };
