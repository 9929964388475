import type { ICustomerPaymentTypes } from 'core/model/interfaces/customers';
import { CustomerFormLayout } from 'screens/customers/customer-form/components';
import { CustomerPayment } from './customer-payment-methods';

interface ICustomerDualPaymentProps {
  apudexPayment: ICustomerPaymentTypes;
  dexPayment: ICustomerPaymentTypes;
}
export const CustomerDualPayment = ({ apudexPayment, dexPayment }: ICustomerDualPaymentProps) => (
  <CustomerFormLayout columns="two">
    <CustomerPayment paymentTypes={apudexPayment} title="Apudex" />
    <CustomerPayment paymentTypes={dexPayment} title="Dex" />
  </CustomerFormLayout>
);
