export enum EStaticPageStateType {
  ENABLED = 'Activo',
  DISABLED = 'Inactivo',
}

export enum EModuleType {
  BANNER = 'Banner',
  SHORTCUT = 'Shortcut',
  SUGGESTED_PRODUCT = 'SuggestedProduct',
}
