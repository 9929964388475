import { Button, Heading, Text } from '@alicorpdigital/dali-components-react';
import { Modal } from 'shared/components/modal';

import './confirmation-modal.scss';

export interface IConfirmationModalProps {
  head: string;
  message: string | JSX.Element;
  onConfirm?(): void;
  onCancel(): void;
}

export const ConfirmationModal = ({ head, message, onConfirm, onCancel }: IConfirmationModalProps) => (
  <Modal>
    <div className="confirmation-modal">
      <div className="confirmation-modal__content">
        <div className="confirmation-modal__title">
          <Heading size="xs">{head}</Heading>
        </div>
        <Text>{message}</Text>
      </div>
      <div className="confirmation-modal__actions">
        {onConfirm && (
          <Button variant="primary" onClick={onConfirm}>
            Confirmar
          </Button>
        )}
        <Button variant="tertiary" onClick={onCancel}>
          Volver
        </Button>
      </div>
    </div>
  </Modal>
);
