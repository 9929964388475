import { useEffect, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

import './checkbox-selector.scss';

interface ICheckboxSelectorProps<S extends FieldValues> {
  optionsList: Record<string, string>;
  control: Control<S>;
  name: Path<S>;
  customOnChange?(values: Array<string>): void;
}

export const CheckboxSelector = <S extends object>({
  optionsList,
  control,
  name,
  customOnChange,
}: ICheckboxSelectorProps<S>) => {
  const { field } = useController({
    control,
    name,
  });
  const [values, setValues] = useState<Array<string>>(field.value || []);

  useEffect(() => {
    setValues(field.value);
  }, [field.value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let valueTemp = [...values];
    valueTemp = e.target.checked ? [...valueTemp, e.target.value] : valueTemp.filter(value => value !== e.target.value);
    field.onChange(valueTemp);
    if (customOnChange) customOnChange(valueTemp);
  };
  return (
    <div className="checkbox-selector">
      {Object.keys(optionsList).map(item => (
        <label key={item} htmlFor={`${name}-${item}`} className="checkbox-selector__label">
          <input
            type="checkbox"
            className="checkbox-selector__input"
            onChange={onChange}
            checked={values?.includes(item)}
            value={item}
            id={`${name}-${item}`}
            name={name}
          />
          <span className="checkbox-selector__text">{optionsList[item]}</span>
        </label>
      ))}
    </div>
  );
};
