import { Form } from '@alicorpdigital/dali-components-react';
import type { ICustomerDeliveryAddress } from 'core/model/interfaces/customers';
import { CustomerFormLayout } from 'screens/customers/customer-form/components';

interface IAddressItemProps {
  address: ICustomerDeliveryAddress;
}

const DeliveryAddressItem = ({
  address: { address, department, district, province, ubigeoCode },
}: IAddressItemProps) => (
  <CustomerFormLayout>
    <Form.Group>
      <Form.Label>Dirección Principal</Form.Label>
      <Form.Input value={address} disabled />
    </Form.Group>
    <CustomerFormLayout columns="two">
      <Form.Group>
        <Form.Label>Distrito</Form.Label>
        <Form.Input value={district} disabled />
      </Form.Group>
      <Form.Group>
        <Form.Label>Provincia</Form.Label>
        <Form.Input value={province} disabled />
      </Form.Group>
      <Form.Group>
        <Form.Label>Departamento</Form.Label>
        <Form.Input value={department} disabled />
      </Form.Group>
      <Form.Group>
        <Form.Label>Ubigeo</Form.Label>
        <Form.Input value={ubigeoCode} disabled />
      </Form.Group>
    </CustomerFormLayout>
  </CustomerFormLayout>
);

export default DeliveryAddressItem;
