import type { TSliceReducer } from 'core/store';
import type { IProductsState } from './types';

const setFiltersApplied: TSliceReducer<IProductsState, boolean> = (state, action) => {
  state.areFiltersApplied = action.payload;
};

const updateProductFilter: TSliceReducer<IProductsState, string> = (state, action) => {
  state.filter = action.payload;
};

export const reducers = {
  updateProductFilter,
  setFiltersApplied,
};
