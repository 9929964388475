import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import {
  ICreateStaticPageBody,
  IGetStaticPagesParams,
  IGetStaticPagesResponse,
  TEditStaticPageBody,
  TGetStaticPageByIdResponse,
} from './static-pages.dto';

const staticPagesBaseURL = '/gtm-static-management/v1/pages';

export const staticPagesData = {
  getStaticPages: async (options: IGetStaticPagesParams): Promise<IGetStaticPagesResponse> => {
    const {
      data: { data },
    } = await https.get<IServiceSuccess<IGetStaticPagesResponse>>(staticPagesBaseURL, {
      params: options,
    });

    return data;
  },
  getStaticPageById: async (id: number) => {
    const {
      data: { data },
    } = await https.get<IServiceSuccess<TGetStaticPageByIdResponse>>(`${staticPagesBaseURL}/${id}`);

    return data;
  },
  createStaticPage: async (body: ICreateStaticPageBody): Promise<void> => {
    await https.post(staticPagesBaseURL, body);
  },
  removeStaticPages: async (id: number): Promise<void> => {
    await https.delete<void>(`${staticPagesBaseURL}/${id}`);
  },
  editStaticPage: async (id: number, body: TEditStaticPageBody): Promise<void> => {
    await https.put(`${staticPagesBaseURL}/${id}`, body);
  },
};
