import { useEffect, useState } from 'react';
import { Button, Form, SearchBox } from '@alicorpdigital/dali-components-react';
import { Table } from '@insuma/mpp-ui/components/table';
import { itemsPerPage } from 'core/constants/pagination.constants';
import { apiService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'core/store';
import { fetchExports, updateExportsFilters } from 'core/store/exports';
import { downloadFileFromUrl } from 'shared/utils/download-file.utils';
import { exportsColumns } from '../masive-exports.constants';

import './masive-exports.scss';

export const MasiveExports = () => {
  const dispatch = useAppDispatch();
  const { exports, isExportsLoading, filters, currentPage, totalPages } = useAppSelector(state => state.exports);
  const [query, setQuery] = useState<string>(filters.query);

  const onDownloadClick = async (fileUrl: string) => {
    const urls = await apiService.g2mFunctions.getDownloadUrls([fileUrl]);
    if (urls) downloadFileFromUrl(urls[0]);
  };

  const paginationModel = {
    totalPages,
    currentPage: currentPage - 1,
    onChangePage: (page: number) => dispatch(fetchExports({ page })),
    pageSize: itemsPerPage,
  };

  const columns = exportsColumns(onDownloadClick);

  useEffect(() => {
    dispatch(fetchExports({}));
  }, [dispatch]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleQueryClear = () => {
    setQuery('');
    dispatch(updateExportsFilters({ query: '' }));
    dispatch(fetchExports({ page: 1 }));
  };

  const handleSearchEnterEvent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateExportsFilters({ query }));
    dispatch(fetchExports({ page: 1 }));
  };

  return (
    <div className="masive-exports">
      <h2 className="masive-exports__title">Registro de Exportación</h2>
      <div className="masive-exports__actions">
        <div>
          <Form onSubmit={handleSearchEnterEvent} className="masive-exports__search">
            <SearchBox
              className="masive-exports__searchbox"
              size="sm"
              placeholder="Busca por nombre de archivo"
              value={query}
              onChange={handleQueryChange}
              onDeleteContent={handleQueryClear}
            />
            <Button
              size="sm"
              isIconOnly
              variant="primary"
              iconWeight="bold"
              iconName="magnifying-glass"
              type="submit"
            />
          </Form>
        </div>
      </div>

      <Table rows={exports} columns={columns} paginationModel={paginationModel} isLoading={isExportsLoading} />
    </div>
  );
};
