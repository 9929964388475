import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import { ORDER_NOT_FOUND } from 'core/constants/orders.constants';
import type { IOrderDetailDisplay } from 'core/model/interfaces/orders';
import { apiService } from 'core/services';
import { handleErrorMessage } from 'shared/utils/errors.utils';

export const useOrderDetail = (orderId?: number) => {
  const [orderDetail, setOrderDetail] = useState<IOrderDetailDisplay | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  useEffect(() => {
    const fetchOrderDetail = async () => {
      if (!orderId) throw new Error('Id should not be undefined or null');
      try {
        setLoading(true);

        const orderDetailRemote = await apiService.orders.getOderDetail({ orderId });
        setOrderDetail(orderDetailRemote);
      } catch (error) {
        setError(ORDER_NOT_FOUND);
        notification.error(handleErrorMessage(error, ORDER_NOT_FOUND));
      } finally {
        setLoading(false);
      }
    };

    if (orderId) {
      fetchOrderDetail();
    }
  }, [orderId]);

  return {
    orderDetail,
    loading,
    error,
  };
};
