import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { customizationRoutePath } from 'core/constants/routes.constants';
import type { ICustomizationSuggestedProductFormData } from 'core/model/interfaces/customization';
import { apiService } from 'core/services';
import { Loading } from 'shared/components/loading';
import { getErrorFromService } from 'shared/utils/https';
import { useCustomizationSuggestedProduct } from '../../hooks/use-customization-suggested-product.hooks';
import { SuggestedProductForm } from './components/suggested-product-form';

export const CustomizationSuggestedProduct = () => {
  const { customizationId, moduleId } = useParams();
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);

  const { isLoading, formData } = useCustomizationSuggestedProduct(Number(customizationId), Number(moduleId));

  const onSubmit = async (data: ICustomizationSuggestedProductFormData) => {
    try {
      setIsSaving(true);

      await apiService.customization.updateCustomizationSuggestedProduct(
        Number(customizationId),
        Number(moduleId),
        data,
      );

      notification.success('Producto sugerido actualizado exitosamente');
      setIsSaving(false);
      navigate(`${customizationRoutePath}/${customizationId}/modulos`);
    } catch (error) {
      setIsSaving(false);
      notification.error(getErrorFromService(error));
    }
  };

  if (isLoading) return <Loading />;

  return <SuggestedProductForm onSubmit={onSubmit} isSaving={isSaving} formData={formData} />;
};
