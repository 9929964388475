import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { ICategory } from 'core/model/interfaces/master-entities';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useCategories = () => {
  const [categoriesList, setCategoriesList] = useState<Array<ICategory>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);

      try {
        const categories = await apiService.masterEntities.getCategories();
        setCategoriesList(categories);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo las opciones de categorias'));
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();

    return () => {
      setCategoriesList([]);
    };
  }, []);

  return { categoriesList, loading, error };
};
