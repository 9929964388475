import { useFormContext } from 'react-hook-form';
import { Button, Form } from '@alicorpdigital/dali-components-react';
import type { IShortcutFormData } from 'core/model/interfaces/customization';
import { DragAndDropImageUploaderForm } from 'shared/components/forms';

import './shortcut-item-form.scss';

interface IShortcutItemFormProps {
  index: number;
  handleApplyClick: () => void;
  handleCancelClick: () => void;
}

export const ShortcutItemForm = ({ index, handleApplyClick, handleCancelClick }: IShortcutItemFormProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IShortcutFormData>();

  const errorTitle = errors?.shortcuts?.[index]?.title;
  const errorLink = errors?.shortcuts?.[index]?.link;
  const errorButtonName = errors?.shortcuts?.[index]?.buttonName;
  const errorImage = errors?.shortcuts?.[index]?.image;

  return (
    <div>
      <Form.Group>
        <Form.Label>Nombre del shortcut (Máximo 20 caracteres)</Form.Label>
        <Form.Input {...register(`shortcuts.${index}.title` as const)} />
        {errorTitle && <Form.HelperText isInvalid>{errorTitle.message}</Form.HelperText>}
      </Form.Group>
      <Form.Group>
        <Form.Label>Enlace al que dirige</Form.Label>
        <Form.Input {...register(`shortcuts.${index}.link` as const)} />
        {errorLink && <Form.HelperText isInvalid>{errorLink.message}</Form.HelperText>}
      </Form.Group>
      <Form.Group>
        <Form.Label>Nombre del Botón (Máximo 20 caracteres)</Form.Label>
        <Form.Input {...register(`shortcuts.${index}.buttonName` as const)} />
        {errorButtonName && <Form.HelperText isInvalid>{errorButtonName.message}</Form.HelperText>}
      </Form.Group>
      <Form.Group className="shortcut-item-form__image-container">
        <Form.Label>Imagen principal</Form.Label>
        <DragAndDropImageUploaderForm
          name={`shortcuts.${index}.image`}
          className="shortcut-item-form__image-uploader"
        />
        {errorImage && <Form.HelperText isInvalid>{errorImage.message}</Form.HelperText>}
      </Form.Group>
      <p>*Las medidas máximas recomendadas son de 400 x 300 px</p>
      <div className="shortcut-item-form__actions">
        <Button variant="secondary" onClick={() => handleApplyClick()}>
          Aplicar
        </Button>
        <Button variant="tertiary" onClick={() => handleCancelClick()}>
          Cancelar
        </Button>
      </div>
    </div>
  );
};
