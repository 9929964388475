import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import {
  ICreateCustomizationDTO,
  ICustomizationDetailDTO,
  IGetCustomizationListParams,
  IGetCustomizationListResponse,
  IGetCustomizationModuleDetailDTO,
  IGetResponseModuleListDTO,
  IModuleDTO,
  IUpdateCustomizationDTO,
  IUpdateCustomizationModuleDetailDTO,
} from './customization.dto';

const customizationBaseURL = '/gtm-static-management/v1/themes';

export const customizationData = {
  getCustomizationList: async (params: IGetCustomizationListParams): Promise<IGetCustomizationListResponse> => {
    const { data } = await https.get<IServiceSuccess<IGetCustomizationListResponse>>(customizationBaseURL, {
      params,
    });
    return data.data;
  },

  deleteCustomization: async (id: number): Promise<void> => {
    await https.delete(`${customizationBaseURL}/${id}`);
  },

  getModules: async (customizationId: number): Promise<Array<IModuleDTO>> => {
    const {
      data: {
        data: { modules },
      },
    } = await https.get<IServiceSuccess<IGetResponseModuleListDTO>>(
      `${customizationBaseURL}/${customizationId}/modules`,
    );

    return modules;
  },

  changeModuleStatus: async (customizationId: number, moduleId: number, newStatus: boolean): Promise<void> => {
    await https.patch<void>(`${customizationBaseURL}/${customizationId}/modules/${moduleId}`, { active: newStatus });
  },

  createCustomization: async (body: ICreateCustomizationDTO): Promise<number> => {
    const { data } = await https.post<IServiceSuccess<{ themeId: number }>>(`${customizationBaseURL}`, body);
    return data.data.themeId;
  },

  getCustomizationById: async (id: string): Promise<ICustomizationDetailDTO> => {
    const { data } = await https.get<IServiceSuccess<ICustomizationDetailDTO>>(`${customizationBaseURL}/${id}`);
    return data.data;
  },

  updateCustomization: async ({ id, ...body }: IUpdateCustomizationDTO): Promise<number> => {
    const { data } = await https.put<IServiceSuccess<{ themeId: number }>>(`${customizationBaseURL}/${id}`, body);
    return data.data.themeId;
  },

  getModuleDetail: async (customizationId: number, moduleId: number): Promise<IGetCustomizationModuleDetailDTO> => {
    const {
      data: { data },
    } = await https.get<IServiceSuccess<IGetCustomizationModuleDetailDTO>>(
      `${customizationBaseURL}/${customizationId}/modules/${moduleId}`,
    );

    return data;
  },

  updateModuleDetail: async ({
    customizationId,
    moduleId,
    banners,
    suggestedProductConfiguration,
  }: IUpdateCustomizationModuleDetailDTO) => {
    const { data } = await https.put<IServiceSuccess<void>>(
      `${customizationBaseURL}/${customizationId}/modules/${moduleId}`,
      {
        banners,
        suggestedProductConfiguration,
      },
    );

    return data;
  },
};
