import type { INavItem } from './components/side-nav-list-item';
import { SideNavHeader, SideNavList } from './components';

import './side-nav.scss';

interface ISideNavProps {
  userFullName: string;
  items: Array<INavItem>;
  currentPath: string;
}

export const SideNav = ({ userFullName, items, currentPath }: ISideNavProps) => (
  <div className="sidenav">
    <SideNavHeader userFullName={userFullName} />
    <SideNavList items={items} currentPath={currentPath} />
  </div>
);
