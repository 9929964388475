import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { INavigationTreeDetail } from 'core/model/interfaces/navigation';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useNavigationTreeDetail = (id: number) => {
  const [navigationTree, setNavigationTree] = useState<INavigationTreeDetail | null>();
  const [isLoading, setLoading] = useState<boolean>();
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchNavigationTreeDetail = async () => {
      try {
        setLoading(true);
        const remoteNavigationTree = await apiService.navigation.getNavigationTreeById(id);
        setNavigationTree(remoteNavigationTree);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo el detalle del árbol de navegación.'));
      } finally {
        setLoading(false);
      }
    };

    fetchNavigationTreeDetail();

    return () => {
      setNavigationTree(null);
    };
  }, [id]);

  return { navigationTree, isLoading, error };
};
