import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import * as yup from 'yup';
import {
  numberFieldMaxError,
  TEXT_FIELD_REQUIRED_ERROR,
  TEXT_NUMBER_INT,
  TEXT_NUMBER_MIN_1_ERROR,
  TEXT_NUMBER_TYPO_ERROR,
  textFieldMaxError,
} from 'core/constants/error-messages.constants';

const daySchema = yup.object().shape({
  cutoffTime: yup.string().required(),
  additionalDaysAfterCutoffTime: yup.number().required(),
  additionalDaysBeforeCutoffTime: yup.number().required(),
});

export const schema = yup.object().shape({
  deliveryConfigurationName: yup.string().trim().required(TEXT_FIELD_REQUIRED_ERROR).max(100, textFieldMaxError(100)),
  description: yup
    .string()
    .trim()
    .required(TEXT_FIELD_REQUIRED_ERROR)
    .test('description', 'min', function minCharacteres(value) {
      const editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(value).contentBlocks),
      );
      const { blocks } = convertToRaw(editorState.getCurrentContent());
      const valueWithoutHtml = blocks
        .map(block => (!block.text.trim() && '\n') || block.text)
        .join('\n')
        .trim();

      if (valueWithoutHtml.length < 1)
        return this.createError({
          path: `${this.path}`,
          message: TEXT_FIELD_REQUIRED_ERROR,
        });
      return true;
    })
    .test('description', 'max', function maxCharacteres(value) {
      const editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(value).contentBlocks),
      );
      const { blocks } = convertToRaw(editorState.getCurrentContent());
      const valueWithoutHtml = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

      if (valueWithoutHtml.length > 2000)
        return this.createError({
          path: `${this.path}`,
          message: textFieldMaxError(2000),
        });
      return true;
    }),
  availableDeliveryDays: yup.array().required(TEXT_FIELD_REQUIRED_ERROR).min(1, TEXT_FIELD_REQUIRED_ERROR),
  purchaseDayDeliveryConfigurations: yup.object().shape({
    MONDAY: daySchema,
    TUESDAY: daySchema,
    WEDNESDAY: daySchema,
    THURSDAY: daySchema,
    FRIDAY: daySchema,
    SATURDAY: daySchema,
    SUNDAY: daySchema,
  }),
  dateOptionsCount: yup
    .number()
    .integer(TEXT_NUMBER_INT)
    .required(TEXT_FIELD_REQUIRED_ERROR)
    .typeError(TEXT_NUMBER_TYPO_ERROR)
    .min(1, TEXT_NUMBER_MIN_1_ERROR)
    .max(99, numberFieldMaxError(99)),
  additionalDaysForRestrictedDates: yup.number().required(TEXT_FIELD_REQUIRED_ERROR),
  restrictedDeliveryDates: yup.array().min(1, TEXT_FIELD_REQUIRED_ERROR),
});
