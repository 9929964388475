import { Form } from '@alicorpdigital/dali-components-react';
import type { ICustomerVisitData } from 'core/model/interfaces/customers';
import { CustomerFormDivider, CustomerFormLayout } from 'screens/customers/customer-form/components';

interface ICustomerVisitDataProps {
  visitData: ICustomerVisitData;
}

export const CustomerVisitData = ({ visitData }: ICustomerVisitDataProps) => (
  <section>
    <CustomerFormLayout columns="two">
      <Form.Group>
        <Form.Label>Vendedor</Form.Label>
        <Form.Input value={visitData.sellerName} disabled />
      </Form.Group>
      <Form.Group>
        <Form.Label>Correo del vendedor</Form.Label>
        <Form.Input value={visitData.sellerEmail} disabled />
      </Form.Group>
      <Form.Group>
        <Form.Label>Sede</Form.Label>
        <Form.Input value={visitData.campus} disabled />
      </Form.Group>
      <Form.Group>
        <Form.Label>Código de zona de ventas</Form.Label>
        <Form.Input value={visitData.saleZoneCode} disabled />
      </Form.Group>
    </CustomerFormLayout>
    <CustomerFormDivider />
    <CustomerFormLayout columns="two">
      <Form.Group>
        <Form.Label>Día de visita</Form.Label>
        <Form.Input value={visitData.dayOfVisit} disabled />
      </Form.Group>
      <Form.Group>
        <Form.Label>Ventana horaria</Form.Label>
        <Form.Input value={visitData.leadDeliveryTime} disabled />
      </Form.Group>
    </CustomerFormLayout>
  </section>
);
