import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store';
import { fetchOrders, updateOrdersWithFilter } from 'core/store/orders';

export const useOrdersQuery = () => {
  const dispatch = useAppDispatch();
  const { query: filter } = useAppSelector(state => state.orders);
  const [query, setQuery] = useState<string>(filter);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleQueryClear = () => {
    setQuery('');
    dispatch(updateOrdersWithFilter(''));
    dispatch(fetchOrders({ page: 1 }));
  };

  const handleSearchEnterEvent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateOrdersWithFilter(query));
    dispatch(fetchOrders({ page: 1 }));
  };

  return {
    handleSearchChange,
    handleQueryClear,
    handleSearchEnterEvent,
    query,
  };
};
