import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtmlPuri from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './custom-editor.scss';

interface ICustomEditorProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export const CustomEditor = ({ value, onChange, disabled = false }: ICustomEditorProps) => {
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value).contentBlocks)),
  );

  const handleOnEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const htmlPuri = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()));
    onChange(htmlPuri);
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={handleOnEditorStateChange}
      toolbarClassName={`custom-editor__toolbar ${disabled ? 'custom-editor__toolbar--disabled' : ''}`}
      editorClassName="custom-editor__editor"
      wrapperClassName={`custom-editor__wrapper ${disabled ? 'custom-editor__wrapper--disabled' : ''}`}
      toolbar={{
        options: ['inline', 'link', 'textAlign', 'list'],
        inline: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ['bold', 'italic', 'underline'],
        },
        textAlign: {
          inDropdown: false,
          options: ['justify'],
        },
        link: {
          inDropdown: false,
          options: ['link'],
        },
        list: {
          inDropdown: false,
          options: ['unordered', 'ordered'],
        },
      }}
      readOnly={disabled}
    />
  );
};
