import type {
  ICustomizationFormData,
  ICustomizationSuggestedProductFormData,
  IOptionsSaveBannersForm,
  IShortcutFormData,
} from 'core/model/interfaces/customization';
import { customizationData } from './customization.data';
import type { IGetCustomizationListParams } from './customization.dto';
import {
  bannerDTOToShortcutFormData,
  bannerListDtoToBannerListForm,
  bannerListFormToBannerListRequest,
  configSuggestedProductDTOToSuggestedProductFormData,
  customizationDTOToDisplayCustomization,
  customizationDTOToFormData,
  customizationFormDataToCreateCustomization,
  moduleDTOToModuleDisplay,
  shortcutFormDataToUpdateModuleDetailDTO,
  suggestedProductFormDataToSuggestedProductConfigurationDTO,
} from './customization.mappers';

export const customizationService = {
  getCustomizationList: async (params: IGetCustomizationListParams) => {
    const { results, pagination } = await customizationData.getCustomizationList(params);
    return {
      themes: results.map(customizationDTOToDisplayCustomization),
      pagination,
    };
  },
  deleteCustomization: async (id: number) => customizationData.deleteCustomization(id),

  getModules: async (customizationId: number) =>
    moduleDTOToModuleDisplay(await customizationData.getModules(customizationId)),

  changeModuleStatus: (customizationId: number, moduleId: number, newStatus: boolean) =>
    customizationData.changeModuleStatus(customizationId, moduleId, newStatus),

  createCustomization: async (body: ICustomizationFormData) => {
    const createCustomization = customizationFormDataToCreateCustomization(body);
    const customizationId = await customizationData.createCustomization(createCustomization);
    return customizationId;
  },

  getCustomizationById: async (id: string) => {
    const customizationDTO = await customizationData.getCustomizationById(id);
    const customizationFormData = customizationDTOToFormData(customizationDTO);
    return customizationFormData;
  },

  updateCustomization: async (id: string, body: ICustomizationFormData) => {
    const createCustomization = customizationFormDataToCreateCustomization(body);
    const customizationId = await customizationData.updateCustomization({ id, ...createCustomization });
    return customizationId;
  },

  updateCustomizationShortcuts: async (
    customizationId: number,
    moduleId: number,
    shortcutFormData: IShortcutFormData,
  ) => {
    const banners = shortcutFormData.shortcuts.map(shortcutFormDataToUpdateModuleDetailDTO);
    await customizationData.updateModuleDetail({
      customizationId,
      moduleId,
      banners,
    });
  },

  getCustomizationShortcuts: async (customizationId: number, moduleId: number) => {
    const moduleDetail = await customizationData.getModuleDetail(customizationId, moduleId);
    return moduleDetail.banners.map(bannerDTOToShortcutFormData);
  },
  getBanners: async (customizationId: number, moduleId: number) =>
    bannerListDtoToBannerListForm(await customizationData.getModuleDetail(customizationId, moduleId)),

  saveBanners: (options: IOptionsSaveBannersForm) =>
    customizationData.updateModuleDetail({
      customizationId: options.customizationId,
      moduleId: options.moduleId,
      banners: bannerListFormToBannerListRequest(options.bannerListForm, options.uploadedImages),
    }),

  getCustomizationSuggestedProduct: async (customizationId: number, moduleId: number) => {
    const moduleDetail = await customizationData.getModuleDetail(customizationId, moduleId);
    if (moduleDetail.suggestedProductConfiguration.length > 0) {
      const formData = moduleDetail.suggestedProductConfiguration[0].config.map(
        configSuggestedProductDTOToSuggestedProductFormData,
      )[0];
      return formData;
    }
    return null;
  },

  updateCustomizationSuggestedProduct: async (
    customizationId: number,
    moduleId: number,
    suggestedProductFormData: ICustomizationSuggestedProductFormData,
  ) => {
    const suggestedProductConfiguration =
      suggestedProductFormDataToSuggestedProductConfigurationDTO(suggestedProductFormData);
    await customizationData.updateModuleDetail({
      customizationId,
      moduleId,
      suggestedProductConfiguration: [suggestedProductConfiguration],
    });
  },
};
