import { Form } from '@alicorpdigital/dali-components-react';

interface ISuggestionErrorParams {
  errors: any;
  name: string;
  sublevelName?: string;
  field: string;
}

export const SuggestionError = ({ errors, name, sublevelName, field }: ISuggestionErrorParams) => (
  <>
    {sublevelName && errors?.[name]?.[sublevelName]?.[field] && (
      <Form.HelperText isInvalid>{errors[name][sublevelName][field].message}</Form.HelperText>
    )}
    {!sublevelName && errors?.[name]?.[field] && (
      <Form.HelperText isInvalid>{errors[name][field].message}</Form.HelperText>
    )}
  </>
);
