import type { TSliceReducer } from 'core/store';
import type { IOrdersState } from './types';

const updateOrdersWithFilter: TSliceReducer<IOrdersState, string> = (state, action) => {
  state.query = action.payload;
};

const setFiltersApplied: TSliceReducer<IOrdersState, boolean> = (state, action) => {
  state.areFiltersApplied = action.payload;
};

export const reducers = { updateOrdersWithFilter, setFiltersApplied };
