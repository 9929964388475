import { Controller, useFormContext } from 'react-hook-form';
import { ISingleImageUploaderDdProps, SingleImageUploaderDd } from 'shared/components/image-uploader-dd';

interface IDragAndDropImageUploaderFormProps extends Omit<ISingleImageUploaderDdProps, 'setImageFile' | 'imageFile'> {
  name: string;
}

export const DragAndDropImageUploaderForm = ({ name, ...rest }: IDragAndDropImageUploaderFormProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <SingleImageUploaderDd imageFile={value} setImageFile={onChange} {...rest} />
      )}
    />
  );
};
