import { ReactNode, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactTextTransition from 'react-text-transition';
import { Button, Form, Heading, Text } from '@alicorpdigital/dali-components-react';
import { DlColorBrandPrimaryMedium } from '@alicorpdigital/dali-design-tokens/build/web/theme-insuma/tokens';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { diadiaTheme } from 'core/constants/themes.constants';
import { Loading } from 'shared/components/loading';
import { Modal } from 'shared/components/modal';
import { useLoadingPhrases } from '../../hooks/use-loading-phrases.hooks';
import { FileInputArea } from '../file-input-area';
import { getImportModalFormSchema } from './import-modal.schema';

import './import-modal.scss';

interface IImportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAgree: (file: File | null) => Promise<void>;
  onDownloadTemplate: () => void;
  isLoading?: boolean;
  title: string;
  maxFileSizeMB: number;
  fileFormat: string;
  filePickerHelperText?: string | ReactNode;
}

export const ImportModal = ({
  isOpen,
  onClose,
  isLoading = false,
  onAgree,
  onDownloadTemplate,
  title,
  maxFileSizeMB,
  fileFormat,
  filePickerHelperText,
}: IImportModalProps) => {
  const maxFileSize = maxFileSizeMB * 1024 * 1024;
  const fileExtension = fileFormat.split('.').pop() || '';
  const formMethods = useForm<{ file: File | null }>({
    mode: 'onChange',
    resolver: yupResolver(getImportModalFormSchema(maxFileSize, fileExtension)),
  });
  const { currentPhrase, startLoadingPhrases, endLoadingPhrases } = useLoadingPhrases();
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = formMethods;

  const file = watch('file');

  useEffect(() => {
    setValue('file', null);
    clearErrors();
  }, [isOpen, setValue, clearErrors]);

  const onRemove = () => {
    setValue('file', null);
    clearErrors();
  };

  const onSubmit = async () => {
    if (onAgree) {
      const intervalId = startLoadingPhrases();
      await onAgree(file);
      endLoadingPhrases(intervalId);
    }
  };

  if (!isOpen) return null;
  return (
    <Modal>
      <div className="import-modal">
        <button type="button" className="import-modal__close-button" onClick={onClose}>
          <Icon name="x" weight="bold" size="md" />
        </button>
        <Heading size="sm"> {title} </Heading>
        {isLoading ? (
          <div className="import-modal__loading-container">
            <Loading color={DlColorBrandPrimaryMedium} height={50} width={50} />
            <div className="import-modal__loading-text-container">
              <ReactTextTransition className="import-modal__loading-text" text={currentPhrase} />
            </div>
          </div>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)} className="import-modal__form">
            <div className="import-modal__file-example">
              <Text color={diadiaTheme.colorNeutralDark}>
                Solo se permite archivos con el siguiente formato <b>{fileFormat}</b>
              </Text>
              <Button variant="secondary" size="sm" hasIcon="left" iconName="download" onClick={onDownloadTemplate}>
                Descargar plantilla
              </Button>
            </div>
            <Form.Group>
              {!file ? (
                <Controller
                  name="file"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FileInputArea
                      accept={fileFormat}
                      setFile={onChange}
                      file={value}
                      filePickerHelperText={filePickerHelperText}
                    />
                  )}
                />
              ) : (
                <div className="import-modal__file-container">
                  <div className="import-modal__file-icon-container">
                    <Icon className="import-modal__file-icon" name="file" size="lg" />
                    <span className="import-modal__file-label">{file?.name}</span>
                  </div>

                  <Button onClick={onRemove} variant="tertiary" hasIcon="left" iconName="trash" size="sm">
                    Eliminar
                  </Button>
                </div>
              )}

              {errors.file && <Form.HelperText isInvalid>{errors.file.message}</Form.HelperText>}
            </Form.Group>

            <div className="import-modal__action-buttons">
              {!file && (
                <Button onClick={onClose} variant="tertiary">
                  Anular
                </Button>
              )}
              <Button type="submit">Confirmar</Button>
            </div>
          </Form>
        )}
      </div>
    </Modal>
  );
};
