import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import type { TGroupingRuleItemListDisplay } from 'core/model/interfaces/grouping-rules';
import { EmptyList } from '../empty-list/empty-list';
import { SortableItem } from './sortable-item/sortable-item';

import './sortable-list.scss';

interface ISortableListProps {
  items: Array<TGroupingRuleItemListDisplay>;
  onChange(value: Array<TGroupingRuleItemListDisplay>): void;
}

export const SortableList = ({ items, onChange }: ISortableListProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (e: any) => {
    const { active, over } = e;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over.id);

      const newItems = arrayMove(items, oldIndex, newIndex);
      if (onChange) onChange(newItems);
    }
  };

  if (items.length === 0) return <EmptyList />;
  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <div className="sortable-list">
          {items.map(item => (
            <SortableItem key={item.id} item={item} />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};
