import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { deliveryConditionRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import type { TDeliveryConditionFormData } from 'core/model/interfaces/delivery-condition';
import { deliveryConditionService } from 'core/services/delivery-condition/delivery-condition.service';
import { getErrorFromService } from 'shared/utils/https';
import { DeliveryConditionForm } from '../components/delivery-condition-form/delivery-condition-form';

export const CreateDeliveryCondition = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async (data: TDeliveryConditionFormData) => {
    try {
      setIsSaving(true);
      await deliveryConditionService.createDeliveryCondition(data);

      notification.success('Nueva condición creada exitosamente');
      setIsSaving(false);
      navigate(`${logisticsRoutePath}${deliveryConditionRoutePath}`);
    } catch (error) {
      setIsSaving(false);
      notification.error(getErrorFromService(error));
    }
  };

  return <DeliveryConditionForm onSubmit={onSubmit} isSaving={isSaving} mode="create" />;
};
