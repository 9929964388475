import { useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import axios from 'axios';
import type { ISuggestion } from 'core/model/interfaces/suggested-product';
import { apiService } from 'core/services';
import { Loading } from 'shared/components/loading';
import { getErrorFromService } from 'shared/utils/https';
import { useSuggestedProductConfiguration } from './hooks/use-suggested-product-configuration.hooks';
import { SuggestedProductForm } from './suggested-product-form';

export const SuggestedProductPage = () => {
  const [isSaving, setIsSaving] = useState(false);
  const { loadingData, suggestedProductDetails: remoteData, setIsReload } = useSuggestedProductConfiguration();

  const onSubmit = async (values: ISuggestion) => {
    try {
      setIsSaving(true);
      await apiService.suggestedProducts.saveSuggestedProductDetails(values);
      setIsSaving(false);
      notification.success('Productos sugeridos guardados exitosamente');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        notification.error(getErrorFromService(error, 'Error guardando los detalles de producto sugerido'));
      }
      setIsSaving(false);
    }
  };

  if (loadingData) return <Loading type="spin" />;

  return (
    <SuggestedProductForm onSubmit={onSubmit} remoteData={remoteData} isSaving={isSaving} setIsReload={setIsReload} />
  );
};
