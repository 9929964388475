import { Action, combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import deliveryConditionsReducer, {
  IDeliveryConditionState,
  initialState as deliveryConditionsInitialState,
} from './logistics/delivery-condition';
import distributorsReducer, {
  IDistributorsState,
  initialState as distributorsInitialState,
} from './logistics/distributors';
import customersReducer, { ICustomersState, initialState as customersInitialState } from './customers';
import customizationReducer, { ICustomizationState, initialState as customizationInitialState } from './customization';
import exportsReducer, { IExportsState, initialState as exportsInitialState } from './exports';
import navigationReducer, { INavigationState, initialState as navigationState } from './navigation';
import ordersReducer, { initialState as ordersInitialState, IOrdersState } from './orders';
import productsReducer, { initialState as productInitialState, IProductsState } from './products';
import staticPagesReducer, { initialState as staticPagesInitialState, IStaticPagesState } from './static-pages';

export interface IState {
  customers: ICustomersState;
  customization: ICustomizationState;
  products: IProductsState;
  orders: IOrdersState;
  exports: IExportsState;
  deliveryConditions: IDeliveryConditionState;
  staticPages: IStaticPagesState;
  distributors: IDistributorsState;
  navigation: INavigationState;
}

export const initialState: IState = {
  customers: customersInitialState,
  customization: customizationInitialState,
  products: productInitialState,
  orders: ordersInitialState,
  exports: exportsInitialState,
  staticPages: staticPagesInitialState,
  deliveryConditions: deliveryConditionsInitialState,
  distributors: distributorsInitialState,
  navigation: navigationState,
};

export const appReducer = combineReducers({
  customers: customersReducer,
  customization: customizationReducer,
  products: productsReducer,
  orders: ordersReducer,
  exports: exportsReducer,
  staticPages: staticPagesReducer,
  deliveryConditions: deliveryConditionsReducer,
  distributors: distributorsReducer,
  navigation: navigationReducer,
});

export const rootReducer = (state: IState, action: Action) => {
  if (action.type === 'user/logOut/fulfilled') {
    storage.removeItem('persist:root');
    return appReducer(initialState, action);
  }
  return appReducer(state, action);
};

export type TRootState = ReturnType<typeof rootReducer>;
