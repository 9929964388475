import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import { defaultCustomizationShortcut } from 'core/constants/customization.constants';
import type { IShortcutFormData } from 'core/model/interfaces/customization';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useCustomizationShortcuts = (customizationId: number, moduleId: number) => {
  const [formData, setFormData] = useState<IShortcutFormData>({ shortcuts: [defaultCustomizationShortcut] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    apiService.customization
      .getCustomizationShortcuts(customizationId, moduleId)
      .then(remoteCustomization => {
        setFormData({
          shortcuts: remoteCustomization.length === 0 ? [defaultCustomizationShortcut] : remoteCustomization,
        });
      })
      .catch(error => {
        setError(error);
        notification.error(getErrorFromService(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [customizationId, moduleId]);

  return {
    formData,
    isLoading,
    error,
  };
};
