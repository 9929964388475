import dayjs from 'dayjs';
import {
  ICustomerForm,
  ICustomersListFilters,
  IDisplayCustomers,
  IERPInfo,
  TCustomersFilters,
} from 'core/model/interfaces/customers';
import {
  ICreateCustomerExportRequest,
  IERPInfoDTO,
  IGetCustomerResponseDTO,
  IGetCustomersParams,
  IGetCustomersResponseDTO,
} from './customers.dto';
import { getAmount, getCustomerGroup } from './customers.utils';

export const toParamsServiceInExport = (
  filters: TCustomersFilters,
  fileName: string,
): ICreateCustomerExportRequest => ({
  fileName,
  query: filters.query,
});

const toERPInfo = (erpInfo?: IERPInfoDTO): IERPInfo => ({
  basicInfo: {
    b2bSegment: erpInfo?.externalSegment?.business.description ?? '',
    companyCode: erpInfo?.externalDistributor.id ?? '',
    creationDate: erpInfo?.createdAt ? dayjs(erpInfo?.createdAt).format('D/M/YYYY') : '',
    customerCode: erpInfo?.externalCustomerId ?? '',
    operatorName: erpInfo?.externalDistributor?.name ?? '',
    potencialSegment: erpInfo?.externalSegment?.potential.description ?? '',
    pricesGroup: erpInfo?.externalPricingPriceGroup?.description ?? '',
    promotionalPricesGroup: erpInfo?.externalPromotionalPriceGroup?.description ?? '',
    status: erpInfo?.isActive ?? false,
    subsegment: erpInfo?.externalSegment?.corporate.description ?? '',
    branchCode: erpInfo?.externalDistributor?.externalBranchId ?? '',
    updateDate: erpInfo?.updatedAt ? dayjs(erpInfo?.updatedAt).format('D/M/YYYY') : '',
  },
  payment: {
    cash: {
      paymentMethods: erpInfo?.paymentMethod?.cash.methods?.map(method => method) ?? [],
      status: erpInfo?.paymentMethod?.cash.status ?? false,
    },
    credit: {
      availableCreditLineAmount: getAmount(erpInfo?.paymentMethod?.credit.amountAvailable),
      busyAvailableCreditLineAmount: getAmount(erpInfo?.paymentMethod?.credit.amountCompromised),
      creditLineAmount: getAmount(erpInfo?.paymentMethod?.credit.amount),
      debtReason: erpInfo?.paymentMethod?.credit.debtReason ?? '',
      paymentTerm: erpInfo?.paymentMethod?.credit.description ?? '',
      status: erpInfo?.paymentMethod?.credit.status ?? false,
    },
  },
});

export const toICustomerForm = (responseData: IGetCustomerResponseDTO): ICustomerForm => ({
  apudex: toERPInfo(responseData.alliedInfo),
  dex: toERPInfo(responseData.alicorpInfo),
  commercialData: {
    address: responseData.externalAddresses?.filter(address => address?.isMain)[0]?.fullAddress,
    businessName: responseData.businessName,
    dexCustomerGroup: getCustomerGroup(
      responseData.externalCustomerGroup.id,
      responseData.externalCustomerGroup.description,
    ),
    dexPricesGroup: responseData.externalPriceGroup.description ?? '',
    dexPricesList: responseData.externalPriceList.description ?? '',
    digitalLeadFlag: responseData.isLeadDigital ? 'Sí' : 'No',
    documentNumber: responseData.document.number,
    documentType: responseData.document.type,
    email: responseData.email,
    firstLastName: responseData.lastname ?? '',
    name: responseData.name,
    primaryPhone: responseData.phones.find(phone => phone.isMain)?.number ?? '',
    secondaryPhone: responseData.phones.find(phone => !phone.isMain)?.number ?? '',
    secondLastName: responseData.motherLastName ?? '',
  },
  deliveryAddresses: (responseData.externalAddresses || []).map(deliveryAddress => ({
    address: deliveryAddress.fullAddress,
    department: deliveryAddress.department,
    district: deliveryAddress.district,
    apudexId: deliveryAddress.ids.allied,
    dexId: deliveryAddress.ids.alicorp,
    province: deliveryAddress.province,
    ubigeoCode: deliveryAddress.localCode,
  })),
  deliveryData: {
    deliveryDate1: responseData.deliveryDate?.deadline1,
    deliveryDate2: responseData.deliveryDate?.deadline2,
    deliveryDate3: responseData.deliveryDate?.deadline3,
  },
  taxData: {
    affectedByIGV: responseData.isTaxAffected ? 'Sí' : 'No',
    perceptionAgent: responseData.isPerceptionAgent ? 'Sí' : 'No',
  },
  visitData: {
    campus: responseData.externalZone?.description,
    dayOfVisit: responseData.visitDay.toString(),
    leadDeliveryTime: responseData.serviceTime,
    saleZoneCode: responseData.externalZone?.id,
    sellerEmail: responseData.externalSeller?.email,
    sellerName: responseData.externalSeller?.name,
  },
});

export const toIDisplayCustomers = (response: IGetCustomersResponseDTO): IDisplayCustomers => ({
  pagination: response.pagination,
  customers: response.users.map(user => ({
    businessName: user.businessName,
    priceList: user.externalPriceListName,
    id: user.userId,
    documentNumber: user.document.number,
  })),
});

export const toGetCustomersParams = (params: ICustomersListFilters): IGetCustomersParams => ({
  page: params.page,
  limit: params.records,
  filter: params.query,
});
