import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '@insuma/mpp-ui/components/table';
import { notification } from '@insuma/mpp-ui/notifications';
import { itemsPerPage } from 'core/constants/pagination.constants';
import { staticPagesRoutePath } from 'core/constants/routes.constants';
import type { IDisplayStaticPage } from 'core/model/interfaces/static-pages';
import { staticPagesService } from 'core/services/static-pages/static-pages.service';
import { useAppDispatch, useAppSelector } from 'core/store';
import { fetchStaticPages } from 'core/store/static-pages/async-thunks/fetchStaticPages';
import { ConfirmationModal } from 'shared/components/confirmation-modal';
import { getErrorFromService } from 'shared/utils/https';
import { getColumns } from '../static-pages.constants';
import { HeaderActions } from './components';

import './static-pages-list.scss';

export const StaticPagesList = () => {
  const route = `${staticPagesRoutePath}/`;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { staticPages, isPagesLoading, totalPages, currentPage, filters } = useAppSelector(state => state.staticPages);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState<IDisplayStaticPage | null>(null);
  const onEditStaticPageClick = (row: IDisplayStaticPage) => navigate(`${route}editar/${row.pageId}`);

  const onDeleteClick = (row: IDisplayStaticPage) => {
    setSelectedRowToDelete(row);
  };

  const onDeleteConfirmation = async () => {
    if (!selectedRowToDelete) return;

    const id = selectedRowToDelete.pageId;
    try {
      await staticPagesService.removeStaticPage(id);
      dispatch(fetchStaticPages({}));
      notification.success('Página estática eliminada exitosamente');
    } catch (error) {
      notification.error(getErrorFromService(error, 'Error eliminando página estática'));
    } finally {
      setSelectedRowToDelete(null);
    }
  };

  const columns = getColumns({
    onEdit: onEditStaticPageClick,
    onDelete: onDeleteClick,
  });

  useEffect(() => {
    dispatch(fetchStaticPages({}));
  }, [filters, dispatch]);

  return (
    <>
      {selectedRowToDelete && (
        <ConfirmationModal
          head="Eliminar página estática"
          message={
            <span>
              ¿Estás seguro de eliminar la página estática <b>{selectedRowToDelete?.title}</b>?
            </span>
          }
          onCancel={() => setSelectedRowToDelete(null)}
          onConfirm={onDeleteConfirmation}
        />
      )}

      <div className="static-pages-list">
        <h2 className="static-pages-list__title">Páginas estáticas</h2>
        <HeaderActions filters={filters} createRoute={`${route}crear`} />
        <div>
          <Table
            isLoading={isPagesLoading}
            rows={staticPages}
            columns={columns}
            noDataMessage="No se encontraron resultados"
            paginationModel={{
              currentPage: currentPage - 1,
              totalPages,
              onChangePage: (page: number) => dispatch(fetchStaticPages({ page })),
              pageSize: itemsPerPage,
            }}
          />
        </div>
      </div>
    </>
  );
};
