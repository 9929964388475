import type { IDistributorParams } from 'core/model/interfaces/grouping-rules';

export const parseDistributorId = (distributorCode: string, branchCode: string) => `${distributorCode}&&${branchCode}`;

export const unParseDistributorId = (id: string): IDistributorParams => {
  const distributorCode = id.split('&&')[0];
  const branchCode = id.split('&&')[1];

  return {
    id: distributorCode,
    externalBranchId: branchCode,
  };
};
