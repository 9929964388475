import { staticPagesData } from './static-pages.data';
import type { IGetStaticPagesParams, TCreateStaticPageDTO, TEditStaticPageDTO } from './static-pages.dto';
import { fromFormDataToServiceBody } from './static-pages.mapper';

export const staticPagesService = {
  getStaticPages: (options: IGetStaticPagesParams) => staticPagesData.getStaticPages(options),
  getStaticPageById: async (id: number) => {
    const staticPage = await staticPagesData.getStaticPageById(id);
    return staticPage;
  },
  createStaticPage: (options: TCreateStaticPageDTO) => {
    const body = fromFormDataToServiceBody(options);
    return staticPagesData.createStaticPage(body);
  },
  removeStaticPage: (id: number) => staticPagesData.removeStaticPages(id),
  editStaticPage: (id: number, options: TEditStaticPageDTO) => {
    const body = fromFormDataToServiceBody(options);
    return staticPagesData.editStaticPage(id, body);
  },
};
