import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, IAutocompleteProps } from '@insuma/mpp-ui/components/form';

interface IAutocompleteFormProps<T, IsMulti extends boolean> extends IAutocompleteProps<T, IsMulti> {
  source: Array<T>;
  onChangeProp?: (option: T | ReadonlyArray<T> | null) => void;
  isObjectValue?: boolean;
  getOptionValue(option: T): string;
  name: string;
}

export const AutocompleteForm = <T, IsMulti extends boolean = false>({
  source = [],
  onChangeProp,
  getOptionValue,
  isMulti,
  getOptionLabel,
  name,
  isObjectValue = false,
  ...rest
}: IAutocompleteFormProps<T, IsMulti>) => {
  const { control } = useFormContext();

  const getOnChangeFn = (onChange: (...event: Array<any>) => void, optionsSelected: T | ReadonlyArray<T> | null) => {
    if (isObjectValue) {
      onChange(optionsSelected);
    } else if (Array.isArray(optionsSelected)) {
      if (isMulti) onChange(optionsSelected.map(getOptionValue));
    } else {
      const value = optionsSelected ? getOptionValue(optionsSelected as T) : optionsSelected;
      onChange(value);
    }

    if (onChangeProp) onChangeProp(optionsSelected);
  };

  const getValueFn = (value: string | Array<string> | T | ReadonlyArray<T>) => {
    if (!value) return null;
    if (isObjectValue) {
      return value as T | ReadonlyArray<T>;
    }
    if (isMulti && Array.isArray(value)) {
      return source.filter(option => value?.includes(getOptionValue(option)));
    }
    return source.find(option => value === getOptionValue(option));
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, onChange, value, ...restOfFields } }) => (
        <Autocomplete
          {...rest}
          {...restOfFields}
          inputRef={ref}
          isMulti={isMulti}
          source={source}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          onChange={optionsSelected => getOnChangeFn(onChange, optionsSelected)}
          value={getValueFn(value)}
        />
      )}
    />
  );
};
