import type { ICustomFile } from 'core/model/interfaces/custom-file';

export const extractImageUrl = (file: ICustomFile): string => {
  if (file.blob) {
    return file.url;
  }
  try {
    return new URL(file.url).pathname;
  } catch (error) {
    return file.url;
  }
};
