export const compose = <R>(...fns: Array<(a: R) => R>) =>
  fns.reduceRight(
    (f, g) =>
      (...args) =>
        g(f(...args)),
  );

export const composePromises =
  <R>(...fns: Array<(a: R) => Promise<R>>) =>
  (input: R) =>
    fns.reduceRight((f, g) => f.then(g), Promise.resolve(input) as Promise<R>);

export const pipe = <R>(...fns: Array<(a: R) => R>) =>
  fns.reduce(
    (f, g) =>
      (...args) =>
        g(f(...args)),
  );

export const pipePromises =
  <R>(...fns: Array<(a: R) => Promise<R>>) =>
  (input: R) =>
    fns.reduce((f, g) => f.then(g), Promise.resolve(input) as Promise<R>);

export const debounce = <T extends (...args: Array<any>) => void>(func: T, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: Parameters<T>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
