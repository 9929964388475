import { Navigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRoutePath } from 'core/constants/routes.constants';
import { useAuth } from 'core/hooks/auth/use-auth.hooks';
import { SuspenseLoading } from '../suspense-loading';

interface IRequireAuthProps {
  children: JSX.Element;
}

export const RequireAuth = ({ children }: IRequireAuthProps) => {
  const { isAuth } = useAuth();
  const { inProgress } = useMsal();

  if (inProgress !== 'none') {
    return <SuspenseLoading />;
  }

  if (!isAuth) {
    return <Navigate to={loginRoutePath} />;
  }

  return children;
};
