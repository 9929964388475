import { Route, Routes } from 'react-router-dom';
import { CreateNavigationTree } from './navigation-tree/create-navigation-tree';
import { EditNavigationTree } from './navigation-tree/edit-navigation-tree';
import { HierarchyTree } from './navigation-tree/hierarchy-tree';
import { NavigationTreeList } from './navigation-tree/navigation-tree-list';
import { ViewProduct } from './product-management/product-view/view-product';
import { ProductsList } from './product-management/products-list';

const Products = () => (
  <Routes>
    <Route path="/" element={<ProductsList />} />
    <Route path="/arbol-de-navegacion" element={<NavigationTreeList />} />
    <Route path="/arbol-de-navegacion/:id" element={<HierarchyTree />} />
    <Route path="/arbol-de-navegacion/crear" element={<CreateNavigationTree />} />
    <Route path="/arbol-de-navegacion/editar/:id" element={<EditNavigationTree />} />
    <Route path="/ver/:id" element={<ViewProduct />} />
  </Routes>
);

export default Products;
