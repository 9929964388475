import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { navigationTreeRoutePath, productsRoutePath } from 'core/constants/routes.constants';
import type { IHierarchyTree } from 'core/model/interfaces/navigation/hierarchy.interface';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

interface IUseFetchHierarchyTreeProps {
  treeId: string;
}
export const useFetchHierarchyTree = ({ treeId }: IUseFetchHierarchyTreeProps) => {
  const [hierarchyTree, setHierarchyTree] = useState<IHierarchyTree | null>(null);
  const [isHierarchyTreeLoading, setHierarchyTreeLoading] = useState<boolean>();
  const navigate = useNavigate();

  const fetchHierarchyTree = useCallback(() => {
    setHierarchyTreeLoading(true);
    apiService.hierarchy
      .getHierarchyTree(treeId)
      .then(response => {
        setHierarchyTree(response);
      })
      .catch(error => {
        const errorMessage = getErrorFromService(error, 'Ha ocurrido un error al cargar el árbol de navegación');
        notification.error(errorMessage);
        setHierarchyTree(null);
        navigate(`${productsRoutePath}${navigationTreeRoutePath}`, { replace: true });
      })
      .finally(() => setHierarchyTreeLoading(false));
  }, [treeId, navigate]);

  useEffect(() => {
    fetchHierarchyTree();
    return () => setHierarchyTree(null);
  }, [fetchHierarchyTree, treeId]);

  return {
    hierarchyTree,
    isHierarchyTreeLoading,
    fetchHierarchyTree,
  };
};
