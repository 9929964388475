import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import type { IGetSuggestedProductConfigDTO, IUpdateSuggestedProductConfigDTO } from './suggested-product.dto';

const suggestedProductsBaseURL = '/gtm-product-management/v1/suggested-products/config';

export const suggestedProductsData = {
  getSuggestedProductDetails: async (): Promise<IGetSuggestedProductConfigDTO> => {
    const { data } = await https.get<IServiceSuccess<IGetSuggestedProductConfigDTO>>(suggestedProductsBaseURL);
    return data.data;
  },

  saveSuggestedProductDetails: async (body: IUpdateSuggestedProductConfigDTO): Promise<void> => {
    const { data } = await https.post(suggestedProductsBaseURL, body);
    return data;
  },
};
