import type { IDistributorFilters } from 'core/model/interfaces/distributors';
import type { TSliceReducer } from 'core/store';
import type { IDistributorsState } from './types';

const updateFilters: TSliceReducer<IDistributorsState, Partial<IDistributorFilters>> = (state, action) => {
  state.currentPage = 1;
  state.filters = {
    ...state.filters,
    ...action.payload,
  };
};

export const reducers = { updateFilters };
