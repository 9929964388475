import { useFormContext } from 'react-hook-form';
import { useCSS } from '@insuma/mpp-ui/hooks';
import type { IDistributorForm } from 'core/model/interfaces/distributors';
import { DistributorSection } from 'screens/logistics/distributors/components/distributor-section';
import { InputValueForm } from 'shared/components/forms';

import './distributor-data.scss';

export const DistributorData = () => {
  const css = useCSS('distributor-data');
  const { watch } = useFormContext<IDistributorForm>();

  const distributorData = watch('distributorData');
  const externalZoneInfo = `${distributorData.zoneCode} - ${distributorData.zoneName}`;

  return (
    <DistributorSection subtitle="1. Datos de operador">
      <div className={css()}>
        <InputValueForm
          name="distributorData.distributorName"
          label="Nombre del operador"
          value={distributorData.distributorName}
          disabled
        />
        <InputValueForm
          name="distributorData.externalZoneInfo"
          label="Oficina / Zona geográfica"
          value={externalZoneInfo}
          disabled
        />
        <InputValueForm
          name="distributorData.distributorCode"
          label="Compañía"
          value={distributorData.distributorCode}
          disabled
        />
        <InputValueForm
          name="distributorData.branchCode"
          label="Sucursal"
          value={distributorData.branchCode}
          disabled
        />
      </div>
    </DistributorSection>
  );
};
