import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { defaultCustomizationShortcut } from 'core/constants/customization.constants';
import type { IShortcutFormData, IShortcutItemFormData } from 'core/model/interfaces/customization';
import { ShortcutItemForm, ShortcutItemView } from './components';

interface IShortcutsItemProps {
  item: IShortcutItemFormData;
  index: number;
  onDelete: () => void;
  onCancel: (value: IShortcutItemFormData) => void;
  handleEditing: (value: boolean) => void;
  formDisabled?: boolean;
}

export const ShortcutsItem = ({
  formDisabled,
  index,
  item,
  handleEditing,
  onDelete,
  onCancel,
}: IShortcutsItemProps) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<IShortcutFormData>();

  const [isFormOpen, setIsFormOpen] = useState(!item.id);
  const [isNewItem, setIsNewItem] = useState(!item.id);
  const [prevItem, setPrevItem] = useState<IShortcutItemFormData>(defaultCustomizationShortcut);

  const handleApplyClick = async () => {
    const isValid = await trigger(`shortcuts.${index}`);
    if (isValid) {
      setIsFormOpen(false);
      setIsNewItem(false);
      handleEditing(false);
    }
  };

  const handleEditClick = (value: IShortcutItemFormData) => {
    setPrevItem(value);

    setIsFormOpen(true);
    handleEditing(true);
  };

  const handleCancelClick = () => {
    setIsFormOpen(false);
    handleEditing(false);
    if (isNewItem) {
      onDelete();
    } else {
      onCancel(prevItem);
    }
  };

  return (
    <div>
      {!isFormOpen ? (
        <ShortcutItemView
          control={control}
          hasErrors={!!errors?.shortcuts?.[index]}
          handleEditClick={handleEditClick}
          handleDeleteClick={onDelete}
          index={index}
          formDisabled={formDisabled}
        />
      ) : (
        <ShortcutItemForm index={index} handleApplyClick={handleApplyClick} handleCancelClick={handleCancelClick} />
      )}
    </div>
  );
};
