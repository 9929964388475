import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, FormGroup } from '@alicorpdigital/dali-components-react';
import { ImageRoutes } from 'core/constants/image-routes.constants';
import { EProductType } from 'core/model/enums/products.enum';
import type { IProductDetail } from 'core/model/interfaces/products';
import { getPropertiesFromUnitMeasure } from 'screens/products/product-management/products-utils';
import { Image } from 'shared/components/image';
import { SimpleInputValue } from 'shared/components/simple-input-value';
import { IProductFormData } from '../../product-form.schema';

import './product-basic-information.scss';

interface IBasicInformationProps {
  remoteData: IProductDetail;
}

export const ProductBasicInformation = ({ remoteData }: IBasicInformationProps) => {
  const {
    sku,
    name,
    dexInfo,
    apudexInfo,
    commercialName,
    externalProvider,
    image,
    description,
    unitMeasure,
    sourceId,
  } = remoteData;
  const { status: statusDex } = dexInfo;
  const { status: statusApudex, stock: stockApudex } = apudexInfo;
  const { name: externalProviderName } = externalProvider;
  const defaultUM = getPropertiesFromUnitMeasure(unitMeasure, true);
  const alternativeUM = getPropertiesFromUnitMeasure(unitMeasure, false);
  const saleUnit = alternativeUM.description || defaultUM.description;

  const [imageFile, setImageFile] = useState<string | null>(image);

  const {
    register,
    formState: { errors },
    trigger,
  } = useFormContext<IProductFormData>();

  useEffect(() => {
    setImageFile(image);
  }, [image]);

  const renderStateInfo = (label: string) => (
    <div className="product-basic-information__state-info">
      <FormGroup>
        <Form.Label>SKU del producto {label}</Form.Label>
        <Form.Input disabled value={sku} />
      </FormGroup>
    </div>
  );

  const renderTooglesSaleOnMarketplace = () => {
    if (sourceId !== EProductType.APUDEX) return null;
    return (
      <>
        <FormGroup>
          <div className="product-basic-information__show-check">
            <Form.Check id="showSaleUnit" data-testid="showSaleUnit" type="switch" {...register(`showSaleUnit`)}>
              Mostrar en el marketplace
            </Form.Check>
            {errors.showSaleUnit && <Form.HelperText isInvalid>{errors.showSaleUnit.message}</Form.HelperText>}
          </div>
        </FormGroup>
        <FormGroup>
          <div className="product-basic-information__show-check">
            <Form.Check
              id="showBaseUnit"
              data-testid="showBaseUnit"
              type="switch"
              {...register(`showBaseUnit`, { onChange: () => trigger('showSaleUnit') })}
            >
              Mostrar en el marketplace
            </Form.Check>
          </div>
        </FormGroup>
      </>
    );
  };

  const renderContentBasedOnSource = () => {
    if (sourceId === EProductType.DEX) {
      return renderStateInfo('DEX');
    }
    if (sourceId === EProductType.APUDEX) {
      return renderStateInfo('APUDEX');
    }
    if (sourceId === EProductType.HYBRID) {
      return (
        <>
          {renderStateInfo('DEX')}
          {renderStateInfo('APUDEX')}
        </>
      );
    }
    if (statusDex) {
      return renderStateInfo('DEX');
    }
    if (statusApudex) {
      return renderStateInfo('APUDEX');
    }

    return null;
  };

  return (
    <div className="product-basic-information">
      <div className="product-basic-information__card">
        <h4 className="product-basic-information__subtitle">Información básica</h4>
        <div className="product-basic-information__section product-basic-information__section--state">
          {renderContentBasedOnSource()}
        </div>

        <div className="product-basic-information__product-names-conatiner">
          <SimpleInputValue label="Nombre del producto" value={name} disabled />
          <SimpleInputValue label="Nombre del producto (secundario)" value={commercialName} disabled />
        </div>

        <hr />

        <div className="product-basic-information__section">
          <SimpleInputValue
            label="Proveedor"
            value={externalProviderName}
            className="product-basic-information__full-row"
            disabled
          />
          <SimpleInputValue label="Unidad de venta" value={saleUnit} disabled />
          <SimpleInputValue label="Unidad base" value={defaultUM?.description} disabled />
          {renderTooglesSaleOnMarketplace()}
          <SimpleInputValue label="Conversión unidad de venta" value={defaultUM?.conversionFactor} disabled />
          <SimpleInputValue label="Presentación del producto" value={defaultUM?.presentation} disabled />
          <SimpleInputValue
            label="Stock APUDEX"
            value={stockApudex?.toString()}
            className="product-basic-information__full-row"
            disabled
          />
        </div>
      </div>
      <div className="product-basic-information__card">
        <h4 className="product-basic-information__subtitle">Imagen</h4>
        <div className="product-basic-information__separator" />
        <div className="product-basic-information__section">
          <div className="product-basic-information__image-container">
            <Image
              className="product-basic-information__image"
              image={imageFile}
              name={name}
              defaultImgSrc={ImageRoutes.DEFAULT_PRODUCT}
            />
          </div>

          <FormGroup>
            <Form.Label>Descripción</Form.Label>
            <Form.Textarea disabled value={description} />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};
