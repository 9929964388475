import { Route, Routes } from 'react-router-dom';
import { CustomerDetail } from './customer-detail';
import { CustomersList } from './customers-list';

const CustomersRoutes = () => (
  <Routes>
    <Route path="/" element={<CustomersList />} />
    <Route path="detalle/:id" element={<CustomerDetail />} />
  </Routes>
);

export default CustomersRoutes;
