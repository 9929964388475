import { Route, Routes } from 'react-router-dom';
import {
  deliveryConditionRoutePath,
  distributorsRoutePath,
  groupingRulesRoutePath,
} from 'core/constants/routes.constants';
import { CreateDeliveryCondition, DeliveryConditionsList, EditDeliveryCondition } from './delivery-condition';
import { DistributorsList, EditDistributor } from './distributors';
import { CreateGroupingRules, EditGroupingRules, GroupingRulesList } from './grouping-rules';

const DeliveryConditionRoutes = () => (
  <Routes>
    <Route path={`${distributorsRoutePath}/`} element={<DistributorsList />} />
    <Route path={`${distributorsRoutePath}/editar/:branchCode/:distributorCode`} element={<EditDistributor />} />
    <Route path={`${deliveryConditionRoutePath}/crear`} element={<CreateDeliveryCondition />} />
    <Route path={`${deliveryConditionRoutePath}/editar/:id`} element={<EditDeliveryCondition />} />
    <Route path={`${deliveryConditionRoutePath}/`} element={<DeliveryConditionsList />} />
    <Route path={`${groupingRulesRoutePath}/`} element={<GroupingRulesList />} />
    <Route path={`${groupingRulesRoutePath}/crear`} element={<CreateGroupingRules />} />
    <Route path={`${groupingRulesRoutePath}/editar/:id`} element={<EditGroupingRules />} />
  </Routes>
);

export default DeliveryConditionRoutes;
