import type { ICustomerPaymentTypes, ICustomerTaxData } from 'core/model/interfaces/customers';
import { CustomerFormContainer, CustomerFormLayout, CustomerFormSubtitle } from '../../components';
import { CustomerDualPayment, CustomerTaxesData } from './sections';

import './customer-taxes-and-credits.scss';

interface ICustomerPaymentAndTaxesProps {
  apudexPayment: ICustomerPaymentTypes;
  dexPayment: ICustomerPaymentTypes;
  taxes: ICustomerTaxData;
}

export const CustomerPaymentAndTaxes = ({ apudexPayment, dexPayment, taxes }: ICustomerPaymentAndTaxesProps) => (
  <div className="customer-taxes-and-credits">
    <CustomerFormContainer>
      <CustomerFormLayout>
        <CustomerFormSubtitle>1. Métodos de pago</CustomerFormSubtitle>
        <CustomerDualPayment apudexPayment={apudexPayment} dexPayment={dexPayment} />
      </CustomerFormLayout>
    </CustomerFormContainer>
    <CustomerFormContainer>
      <CustomerFormLayout>
        <CustomerFormSubtitle>2. Datos de impuestos</CustomerFormSubtitle>
        <CustomerTaxesData taxData={taxes} />
      </CustomerFormLayout>
    </CustomerFormContainer>
  </div>
);
