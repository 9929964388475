import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, FormGroup } from '@alicorpdigital/dali-components-react';
import { EDeliveryTimeOption } from 'core/model/enums/grouping-rules';

import './grouping-rules-delivery-time.scss';

const GroupingRulesDeliveryTime = () => {
  const {
    formState: { errors },
    register,
    watch,
    clearErrors,
  } = useFormContext();

  const useCustomerDeliveryDates = watch('useCustomerDeliveryDates');

  useEffect(() => {
    if (useCustomerDeliveryDates === EDeliveryTimeOption.ASSOCIATED_DATES) clearErrors('deliveryDate');
  }, [clearErrors, useCustomerDeliveryDates]);

  return (
    <div className="row-container">
      <h4 className="row-container__subtitle">3. Tiempo de entrega</h4>
      <div className="delivery-time">
        <Form.Group>
          <Form.Check
            id="delivery-time_useCustomerDeliveryDates"
            type="radio"
            value={EDeliveryTimeOption.SPECIFIC_DAYS}
            {...register('useCustomerDeliveryDates')}
          >
            Priorizar días específicos de entrega del cliente
          </Form.Check>

          <Form.Check
            id="delivery-time_not-useCustomerDeliveryDates"
            type="radio"
            value={EDeliveryTimeOption.ASSOCIATED_DATES}
            {...register('useCustomerDeliveryDates')}
          >
            Priorizar fechas asociadas a esta agrupación
          </Form.Check>
        </Form.Group>
        <FormGroup>
          {errors.deliveryTime && <Form.HelperText isInvalid>{errors.deliveryTime.message}</Form.HelperText>}
        </FormGroup>
      </div>
    </div>
  );
};

export default GroupingRulesDeliveryTime;
