import { IProductFormData } from 'screens/products/product-management/product-form/product-form.schema';
import { IProductDetail } from '../../model/interfaces/products/products';
import { IProductDetailDTO, IUpdateProduct } from './products.dto';

export const productDetailDTOToProductDetailForm = (productDetail: IProductDetailDTO): IProductDetail => {
  const baseUM = productDetail.unitMeasure.find(uM => uM.default === true);
  const SaleUM = productDetail.unitMeasure.find(uM => uM.default === false);

  if (!baseUM || !SaleUM) throw new Error('Base unit measure, sale unit measure should not be undefined or null');
  return { ...productDetail, isActiveBaseUnitMeasure: baseUM?.isActive, isActiveSaleUnitMeasure: SaleUM?.isActive };
};

export const productDetailFormToProductDetailUpdateDTO = (
  sku: string,
  productForm: IProductFormData,
): IUpdateProduct => ({
  minimumSaleQuantity: productForm.minimumSaleQuantity,
  maximumSaleQuantity: productForm.maximumSaleQuantity,
  isActiveBaseUnitMeasure: productForm.showBaseUnit,
  isActiveSaleUnitMeasure: productForm.showSaleUnit,
  id: sku,
});
