import { EHierarchyAssociation } from 'core/model/enums/hierarchy.enum';
import type { INavigationTreeDetailCreateForm } from 'core/model/interfaces/navigation';

export const defaultValues: INavigationTreeDetailCreateForm = {
  name: '',
  categoryName: '',
  subcategoryName: '',
  associationType: EHierarchyAssociation.BY_FAMILIES,
  skus: '',
  families: [],
  externalPriceListIds: [],
  externalDistributors: [],
  default: false,
  categoryIcon: { url: '' },
};
