import { ReactNode } from 'react';

import './navigation-tree-form-section.scss';

interface INavigationTreeFormSectionProps {
  children: ReactNode;
}

export const NavigationTreeFormSection = ({ children }: INavigationTreeFormSectionProps) => (
  <div className="navigation-tree-form-section"> {children}</div>
);
