import { itemsPerPage } from 'core/constants/pagination.constants';
import { apiService } from 'core/services';
import type { IGetProductsDTO, IProductsOptions } from 'core/services/products/products.dto';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.helpers';
import { SLICE_NAMESPACE } from '../constants';
import type { IProductsState } from '../types';

export const fetchAllProducts = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/fetchAllProducts`,
  async ({ page }: { page?: number }, { getState }): Promise<IGetProductsDTO> => {
    const { products } = getState();
    const { filter, currentPage } = products;

    const params: IProductsOptions = {
      itemsPerPage,
      page: page ?? currentPage,
      filter,
    };

    return apiService.products.getProducts(params);
  },
);

export const fetchAllProductsReducer: TSliceExtraReducer<IProductsState> = builder =>
  builder
    .addCase(fetchAllProducts.pending, state => {
      state.isLoading = true;
    })
    .addCase(fetchAllProducts.fulfilled, (state, action) => {
      state.products = action.payload.products;
      state.totalPages = action.payload.pagination.totalPages;
      state.currentPage = action.payload.pagination.currentPage;
      state.isLoading = false;
    })
    .addCase(fetchAllProducts.rejected, state => {
      state.isLoading = false;
      state.products = [];
    });
