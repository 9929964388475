import type { IStaticPagesFilters } from 'core/model/interfaces/static-pages';
import type { TSliceReducer } from 'core/store';
import type { IStaticPagesState } from './types';

const updateFilters: TSliceReducer<IStaticPagesState, Partial<IStaticPagesFilters>> = (state, action) => {
  state.currentPage = 0;
  state.filters = {
    ...state.filters,
    ...action.payload,
  };
};

export const reducers = { updateFilters };
