import { notification } from '@insuma/mpp-ui/notifications';
import type {
  IHierarchyTreeCategory,
  IHierarchyTreeSubcategory,
} from 'core/model/interfaces/navigation/hierarchy.interface';
import { apiService } from 'core/services';
import { ConfirmationModal } from 'shared/components/confirmation-modal';
import { getErrorFromService } from 'shared/utils/https';

interface IHierarchyDeleteModalProps {
  selectedCategory: IHierarchyTreeCategory | null;
  selectedSubcategory: IHierarchyTreeSubcategory | null;
  closeDeleteItemModal: () => void;
  fetchHierarchyTree: () => void;
  treeId: string;
}
export const HierarchyDeleteModal = ({
  selectedCategory,
  selectedSubcategory,
  closeDeleteItemModal,
  fetchHierarchyTree,
  treeId,
}: IHierarchyDeleteModalProps) => {
  const handleDeleteHierarchySubcategory = async () => {
    if (selectedSubcategory) {
      try {
        await apiService.hierarchy.deleteHierarchyItem(selectedSubcategory.id, treeId);
        notification('Subcategoría eliminada exitosamente');
        fetchHierarchyTree();
      } catch (error) {
        notification.error(getErrorFromService(error));
      }
      closeDeleteItemModal();
    }
  };

  const handleDeleteHierarchyCategory = async () => {
    if (selectedCategory) {
      try {
        await apiService.hierarchy.deleteHierarchyItem(selectedCategory.id, treeId);
        notification('Categoría eliminada exitosamente');
        fetchHierarchyTree();
      } catch (error) {
        notification.error(getErrorFromService(error));
      }
      closeDeleteItemModal();
    }
  };

  return (
    <ConfirmationModal
      onConfirm={selectedSubcategory ? handleDeleteHierarchySubcategory : handleDeleteHierarchyCategory}
      onCancel={closeDeleteItemModal}
      head={selectedSubcategory ? 'Eliminar Subcategoría' : 'Eliminar Categoría'}
      message={
        selectedSubcategory
          ? `¿Estás seguro de eliminar la subcategoría ${selectedSubcategory.name}?`
          : `¿Estás seguro de eliminar la categoría ${selectedCategory?.name}?`
      }
    />
  );
};
