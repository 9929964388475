import { useState } from 'react';
import type { IHierarchyTreeCategory } from 'core/model/interfaces/navigation/hierarchy.interface';

interface IUseHierarchyCategoryModalsProps {
  setSelectedCategory: React.Dispatch<React.SetStateAction<IHierarchyTreeCategory | null>>;
  selectedCategory: IHierarchyTreeCategory | null;
}
export const useHierarchyCategoryModal = ({
  setSelectedCategory,
  selectedCategory,
}: IUseHierarchyCategoryModalsProps) => {
  const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);

  const openCreateCategoryModal = () => {
    setCategoryModalOpen(true);
  };

  const openEditCategoryModal = (category: IHierarchyTreeCategory) => {
    setSelectedCategory(category);
    setCategoryModalOpen(true);
  };

  const closeCategoryModal = () => {
    setSelectedCategory(null);
    setCategoryModalOpen(false);
  };

  return {
    selectedCategory,
    isCategoryModalOpen,
    closeCategoryModal,
    openCreateCategoryModal,
    openEditCategoryModal,
  };
};
