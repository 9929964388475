import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Button, Form } from '@alicorpdigital/dali-components-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultCustomizationShortcut } from 'core/constants/customization.constants';
import { customizationRoutePath } from 'core/constants/routes.constants';
import type { IShortcutFormData } from 'core/model/interfaces/customization';
import { Link } from 'shared/components/link';
import { ShortcutsItem } from './shortcut-item/shortcut-item';
import { schema } from './shortcut-form.schema';

import './shortcut-form.scss';

interface IShortcutsFormProps {
  isSaving?: boolean;
  onSubmit: (data: IShortcutFormData) => void;
  shortcut: IShortcutFormData;
  formDisabled?: boolean;
}

export const ShortcutForm = ({ isSaving, onSubmit, shortcut, formDisabled }: IShortcutsFormProps) => {
  const { customizationId } = useParams();
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: shortcut,
  });

  const { handleSubmit, control, trigger, watch } = formMethods;
  const { append, remove, update } = useFieldArray({
    control,
    name: 'shortcuts',
  });

  const shortcuts = watch('shortcuts');

  const [isEditing, setIsEditing] = useState(!shortcut.shortcuts[0]?.id);
  const isFormDisabled = formDisabled || isEditing;

  return (
    <div className="shortcut-form">
      <div className="shortcut-form__back">
        <Link icon="caret-left" to={`${customizationRoutePath}/${customizationId}/modulos`}>
          Atrás
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="shortcut-form__title">Shortcut</h2>
        <br />

        <FormProvider {...formMethods}>
          <div>
            <h4 className="shortcut-form__subtitle">Elección de Shortcuts</h4>
            <section className="shortcut-form__items row-container">
              {shortcuts.length > 0 && (
                <Form.Group className="shortcut-form__form-group">
                  {shortcuts.map((item, index) => (
                    <ShortcutsItem
                      key={index}
                      index={index}
                      item={item}
                      formDisabled={isFormDisabled}
                      onDelete={() => remove(index)}
                      onCancel={value => {
                        update(index, value);
                        trigger(`shortcuts.${index}`);
                      }}
                      handleEditing={value => setIsEditing(value)}
                    />
                  ))}
                </Form.Group>
              )}
              {shortcuts.length < 4 && (
                <div className="shortcut-form__add-shortcut">
                  <Button
                    variant="tertiary"
                    iconName="plus"
                    hasIcon="left"
                    type="button"
                    disabled={isFormDisabled}
                    onClick={() => {
                      setIsEditing(true);
                      append(defaultCustomizationShortcut);
                    }}
                  >
                    Añadir shortcut
                  </Button>
                </div>
              )}
              <b>Mínimo 03 Shortcut para poder completar</b>
            </section>
          </div>
        </FormProvider>

        <div className="shortcut-form__actions">
          <Button type="submit" disabled={formDisabled} isLoading={isSaving}>
            Guardar
          </Button>
        </div>
      </form>
    </div>
  );
};
