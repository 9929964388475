import { NavigateFunction } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';

export const exportNotification = (navigate: NavigateFunction) =>
  notification('Tu archivo está siendo exportado. Puedes ver el estado en la sección Exportaciones masivas', {
    action: {
      text: 'Visualizar',
      cb: () => {
        navigate('/cargas-masivas/masive-exportations');
        notification.dismiss();
      },
    },
  });
