import { useState } from 'react';
import { useParams } from 'react-router-dom';
import type {
  IHierarchyTreeCategory,
  IHierarchyTreeSubcategory,
} from 'core/model/interfaces/navigation/hierarchy.interface';
import { Loading } from 'shared/components/loading';
import { DndSortable } from 'shared/dnd-sortable';
import {
  CategoryListItem,
  CreateHierarchyCategoryModal,
  CreateHierarchySubcategoryModal,
  EditHierarchyCategoryForm,
  EditHierarchySubcategoryModal,
  HierarchyDeleteModal,
  HierarchyTreeHeader,
} from './components';
import {
  useFetchHierarchyTree,
  useHierarchyCategoryModal,
  useHierarchyDeleteModal,
  useHierarchySubcategoryModal,
  useHierarchyTreeActions,
} from './hooks';

import './hierarchy-tree.scss';

export const HierarchyTree = () => {
  const { id } = useParams();
  const treeId = String(id);
  const [selectedCategory, setSelectedCategory] = useState<IHierarchyTreeCategory | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<IHierarchyTreeSubcategory | null>(null);

  const { fetchHierarchyTree, isHierarchyTreeLoading, hierarchyTree } = useFetchHierarchyTree({
    treeId,
  });

  const { closeCategoryModal, openCreateCategoryModal, openEditCategoryModal, isCategoryModalOpen } =
    useHierarchyCategoryModal({ selectedCategory, setSelectedCategory });

  const { closeSubcategoryModal, openCreateSubcategoryModal, openEditSubcategoryModal, isSubcategoryModalOpen } =
    useHierarchySubcategoryModal({ setSelectedCategory, setSelectedSubcategory });

  const { closeDeleteItemModal, openDeleteCategoryModal, openDeleteSubcategoryModal, isDeleteModalOpen } =
    useHierarchyDeleteModal({
      setSelectedSubcategory,
      setSelectedCategory,
    });

  const {
    sortActions: { handleSortCategories, handleSortSubcategories },
    sharedActions: { handlePublish, handleCollapsedToogle },
    sortedCategories,
  } = useHierarchyTreeActions({
    treeId,
    fetchHierarchyTree,
    hierarchyTree,
  });

  if (isHierarchyTreeLoading || !hierarchyTree) return <Loading />;

  return (
    <div className="hierarchy-tree">
      <HierarchyTreeHeader
        onPublish={handlePublish}
        hadleOpenCreateCategoryModal={openCreateCategoryModal}
        hierarchyTree={hierarchyTree}
      />

      {sortedCategories.length === 0 ? (
        <p>No existen categorías</p>
      ) : (
        <div className="hierarchy-tree__table">
          <DndSortable
            items={sortedCategories}
            renderItem={(item, index) => (
              <CategoryListItem
                isCollapsed={item.collapsed}
                category={item}
                isDeleteDisabled={sortedCategories.length === 1}
                onChangeCollapsed={collapsed => handleCollapsedToogle(item.id, collapsed)}
                onCreateSubcategory={() => openCreateSubcategoryModal(item)}
                onEdit={() => openEditCategoryModal(item)}
                onDelete={() => openDeleteCategoryModal(item)}
                onDeleteSubcategory={subcategory => openDeleteSubcategoryModal(subcategory)}
                onEditSubcategory={subcategory => openEditSubcategoryModal(subcategory, item)}
                onChangeSubcategories={subcategories => handleSortSubcategories(index, subcategories)}
              />
            )}
            onChange={handleSortCategories}
          />
        </div>
      )}

      {isCategoryModalOpen &&
        (selectedCategory ? (
          <EditHierarchyCategoryForm
            selectedCategory={selectedCategory}
            onClose={closeCategoryModal}
            fetchTreeHierarchy={fetchHierarchyTree}
            treeId={treeId}
          />
        ) : (
          <CreateHierarchyCategoryModal
            onClose={closeCategoryModal}
            fetchTreeHierarchy={fetchHierarchyTree}
            treeId={treeId}
          />
        ))}

      {isSubcategoryModalOpen &&
        selectedCategory &&
        (selectedSubcategory ? (
          <EditHierarchySubcategoryModal
            selectedCategory={selectedCategory}
            onClose={closeSubcategoryModal}
            fetchTreeHierarchy={fetchHierarchyTree}
            treeId={treeId}
            selectedSubcategory={selectedSubcategory}
          />
        ) : (
          <CreateHierarchySubcategoryModal
            selectedCategory={selectedCategory}
            onClose={closeSubcategoryModal}
            fetchTreeHierarchy={fetchHierarchyTree}
            treeId={treeId}
          />
        ))}

      {isDeleteModalOpen && (
        <HierarchyDeleteModal
          closeDeleteItemModal={closeDeleteItemModal}
          fetchHierarchyTree={fetchHierarchyTree}
          selectedCategory={selectedCategory}
          selectedSubcategory={selectedSubcategory}
          treeId={treeId}
        />
      )}
    </div>
  );
};
