import { useCallback, useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import { defaultSuggestedProductConfiguration } from 'core/constants/suggested-product.constants';
import type { ISuggestion } from 'core/model/interfaces/suggested-product';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useSuggestedProductConfiguration = () => {
  const [suggestedProductDetails, setSuggestedProductDetails] = useState<ISuggestion>(
    defaultSuggestedProductConfiguration,
  );
  const [loadingData, setLoadingData] = useState(true);
  const [isReload, setIsReload] = useState(false);

  const onGetSuggestedProductDetails = useCallback(async () => {
    try {
      setLoadingData(true);
      const remoteData = await apiService.suggestedProducts.getSuggestedProductDetails();
      if (remoteData) {
        setSuggestedProductDetails(remoteData);
      }
    } catch (error) {
      notification.error(getErrorFromService(error, 'Error obteniendo los detalles de producto sugerido.'));
    } finally {
      setLoadingData(false);
      setIsReload(false);
    }
  }, []);

  useEffect(() => {
    onGetSuggestedProductDetails();
  }, [onGetSuggestedProductDetails]);

  useEffect(() => {
    if (isReload) onGetSuggestedProductDetails();
  }, [isReload, onGetSuggestedProductDetails]);

  return {
    suggestedProductDetails,
    loadingData,
    setIsReload,
  };
};
