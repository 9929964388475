import * as yup from 'yup';
import {
  TEXT_ANY_FIELD_REQUIRED,
  TEXT_FIELD_REQUIRED_ERROR,
  TEXT_INVALID_DOCUMENTS,
  TEXT_NUMBER_GT_0,
  TEXT_NUMBER_TYPO_ERROR,
} from 'core/constants/error-messages.constants';
import { VALID_CLIENT_ID_REGEX } from 'core/constants/regex.constant';
import { EApplyToOption } from 'core/model/enums/distributor.enum';

const minimumPurchaseSchema = {
  applyTo: yup.string().required(),
  amount: yup
    .number()
    .nullable()
    .emptyStringToNull()
    .when(['applyTo'], {
      is: EApplyToOption.CART,
      then: schema =>
        schema.typeError(TEXT_NUMBER_TYPO_ERROR).required(TEXT_FIELD_REQUIRED_ERROR).min(1, TEXT_NUMBER_GT_0),
    }),
  amountAlicorp: yup
    .number()
    .nullable()
    .emptyStringToNull()
    .when(['applyTo'], {
      is: EApplyToOption.BRIEFCASE,
      then: schema => schema.required(TEXT_FIELD_REQUIRED_ERROR).min(1, TEXT_NUMBER_GT_0),
    }),

  amountAllied: yup
    .number()
    .nullable()
    .emptyStringToNull()
    .when(['applyTo'], {
      is: EApplyToOption.BRIEFCASE,
      then: schema => schema.required(TEXT_FIELD_REQUIRED_ERROR).min(1, TEXT_NUMBER_GT_0),
    }),
  priceGroup: yup.array().of(yup.object()),
  customerPriceList: yup.array().of(yup.object()),
  customers: yup
    .string()
    .notRequired()
    .trim()
    .test('validClients', TEXT_INVALID_DOCUMENTS, text => {
      if (!text) return true;
      const lines = text.split('\n');
      const isValidLines = lines.every(line => VALID_CLIENT_ID_REGEX.test(line));
      const hasInvalidTrailingLine = lines[lines.length - 1] === '';
      return isValidLines && !hasInvalidTrailingLine;
    }),
  zones: yup.array().of(yup.object()),
  externalSalesForce: yup.string().nullable(),
};

export const schema = yup.object().shape({
  visitDelivery: yup.number().required(TEXT_FIELD_REQUIRED_ERROR),
  minimumPurchases: yup
    .array()
    .of(
      yup
        .object()
        .shape(minimumPurchaseSchema)
        .atLeastOneOf(['priceGroup', 'zones', 'externalSalesForce', 'customers'], TEXT_ANY_FIELD_REQUIRED),
    ),
  isWhatsappButtonEnabled: yup.boolean().required(TEXT_FIELD_REQUIRED_ERROR),
});
