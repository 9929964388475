import type { IBannerForm, IBannerListForm } from 'core/model/interfaces/banners';

export const defaultNewBanner: IBannerForm = {
  name: '',
  desktopImage: {
    url: '',
  },
  mobileImage: {
    url: '',
  },
  link: '',
  isEditing: true,
  isNew: true,
  dateRange: null,
  viewOrder: null,
};

export const defaultBannerList: IBannerListForm = {
  banners: [defaultNewBanner],
};
