import type {
  ICustomerDeliveryAddress,
  ICustomerDeliveryData,
  ICustomerVisitData,
} from 'core/model/interfaces/customers';
import { CustomerFormContainer, CustomerFormLayout, CustomerFormSubtitle } from '../../components';
import { CustomerDeliveryAddresses, CustomerDeliveryData, CustomerVisitData } from './sections';

import './customer-delivery-and-visits.scss';

interface ICustomerDeliveryAndVisitsProps {
  deliveryAddresses: Array<ICustomerDeliveryAddress>;
  deliveryData: ICustomerDeliveryData;
  visitData: ICustomerVisitData;
}

export const CustomerDeliveryAndVisits = ({
  deliveryAddresses,
  deliveryData,
  visitData,
}: ICustomerDeliveryAndVisitsProps) => (
  <div className="customer-delivery-and-visits">
    <CustomerFormContainer>
      <CustomerFormLayout>
        <CustomerFormSubtitle>1. Direcciones de entrega</CustomerFormSubtitle>
        <CustomerDeliveryAddresses deliveryAddresses={deliveryAddresses} />
      </CustomerFormLayout>
    </CustomerFormContainer>
    <CustomerFormContainer>
      <CustomerFormLayout>
        <CustomerFormSubtitle>2. Datos de entrega</CustomerFormSubtitle>
        <CustomerDeliveryData deliveryData={deliveryData} />
      </CustomerFormLayout>
    </CustomerFormContainer>
    <CustomerFormContainer>
      <CustomerFormLayout>
        <CustomerFormSubtitle>3. Datos de visita</CustomerFormSubtitle>
        <CustomerVisitData visitData={visitData} />
      </CustomerFormLayout>
    </CustomerFormContainer>
  </div>
);
