import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { navigationTreeRoutePath, productsRoutePath } from 'core/constants/routes.constants';
import type { INavigationTreeDetail } from 'core/model/interfaces/navigation/navigation.interface';
import { apiService } from 'core/services';
import { Loading } from 'shared/components/loading';
import { getErrorFromService } from 'shared/utils/https';
import { useNavigationTreeDetail } from '../hooks/use-navigation-tree-detail.hooks';
import { NavigationTreeForm } from '../navigation-tree-form/navigation-tree-form';
import { defaultValues } from './edit-navigation-tree.constants';
import { schema } from './edit-navigation-tree.schema';

export const EditNavigationTree = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const { navigationTree, isLoading } = useNavigationTreeDetail(Number(id));

  const onEdit = async (data: INavigationTreeDetail) => {
    try {
      setIsSaving(true);
      await apiService.navigation.editNavigationTree(Number(id), data);
      notification.success('Árbol editado exitosamente');
      setIsSaving(false);
      navigate(`${productsRoutePath}${navigationTreeRoutePath}`);
    } catch (error) {
      setIsSaving(false);
      notification.error(getErrorFromService(error, 'Error inesperado mientras se editaba el árbol'));
    }
  };

  if (isLoading || !navigationTree) return <Loading height={50} width={50} />;

  return (
    <NavigationTreeForm navigationTree={navigationTree} defaultValues={defaultValues} onSubmit={onEdit} schema={schema}>
      <NavigationTreeForm.Title title="Editar Árbol" />
      <NavigationTreeForm.MainFields blockFields={navigationTree.default} />
      <NavigationTreeForm.SubmitButton label="Guardar" disabled={isSaving || navigationTree.default} />
    </NavigationTreeForm>
  );
};
