import type { ICustomerBasicInfo } from 'core/model/interfaces/customers';
import { CustomerFormLayout } from 'screens/customers/customer-form/components';
import { CustomerBasicData } from './customer-basic-data';

interface ICustomerDualBasicDataProps {
  apudexBasicInfo: ICustomerBasicInfo;
  dexBasicInfo: ICustomerBasicInfo;
}
export const CustomerDualBasicData = ({ apudexBasicInfo, dexBasicInfo }: ICustomerDualBasicDataProps) => (
  <CustomerFormLayout columns="two">
    <CustomerBasicData title="Apudex" basicInfo={apudexBasicInfo} />
    <CustomerBasicData title="Dex" basicInfo={dexBasicInfo} />
  </CustomerFormLayout>
);
