import { EDeliveryTimeOption } from 'core/model/enums/grouping-rules';
import type { TDeliveryConditionFormData } from 'core/model/interfaces/delivery-condition';
import type { IDistributorForm } from 'core/model/interfaces/distributors';
import type { IGroupingRulesFormData } from 'core/model/interfaces/grouping-rules';

export const SPANISH_DAYS = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'] as const;
export const ENGLISH_DAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'] as const;
export type TSpanishDay = (typeof SPANISH_DAYS)[number];
export type TEnglishDay = (typeof ENGLISH_DAYS)[number];

export type TDayNames = {
  [key in TEnglishDay]: string;
};

export const DAY_NAMES: TDayNames = {
  MONDAY: 'Lun',
  TUESDAY: 'Mar',
  WEDNESDAY: 'Mié',
  THURSDAY: 'Jue',
  FRIDAY: 'Vie',
  SATURDAY: 'Sáb',
  SUNDAY: 'Dom',
};

export const DEFAULT_DELIVERY_CONDITION: TDeliveryConditionFormData = {
  deliveryConfigurationName: '',
  availableDeliveryDays: [],
  description: '',
  purchaseDayDeliveryConfigurations: {
    MONDAY: {
      cutoffTime: '',
      additionalDaysAfterCutoffTime: 2,
      additionalDaysBeforeCutoffTime: 1,
    },
    TUESDAY: {
      cutoffTime: '',
      additionalDaysAfterCutoffTime: 2,
      additionalDaysBeforeCutoffTime: 1,
    },
    WEDNESDAY: {
      cutoffTime: '',
      additionalDaysAfterCutoffTime: 2,
      additionalDaysBeforeCutoffTime: 1,
    },
    THURSDAY: {
      cutoffTime: '',
      additionalDaysAfterCutoffTime: 2,
      additionalDaysBeforeCutoffTime: 1,
    },
    FRIDAY: {
      cutoffTime: '',
      additionalDaysAfterCutoffTime: 2,
      additionalDaysBeforeCutoffTime: 1,
    },
    SATURDAY: {
      cutoffTime: '',
      additionalDaysAfterCutoffTime: 2,
      additionalDaysBeforeCutoffTime: 1,
    },
    SUNDAY: {
      cutoffTime: '',
      additionalDaysAfterCutoffTime: 2,
      additionalDaysBeforeCutoffTime: 1,
    },
  },
  additionalDaysForRestrictedDates: 1,
  dateOptionsCount: 1,
  restrictedDeliveryDates: [],
};

export const DEFAULT_GROUPING_RULES: IGroupingRulesFormData = {
  deliveryGroupingRuleName: '',
  conditions: {
    externalPriceGroupIds: [],
    externalPriceListIds: [],
    externalProductCategoryIds: [],
    externalDistributors: [],
  },
  useCustomerDeliveryDates: EDeliveryTimeOption.SPECIFIC_DAYS,
  deliveryConfiguration: {
    id: 1,
    deliveryConfigurationName: '',
  },
};

export const DEFAULT_DISTRIBUTOR_DETAIL: IDistributorForm = {
  distributorData: {
    distributorName: '',
    distributorCode: '',
    branchCode: '',
    zoneName: '',
    zoneCode: '',
  },
  visitDelivery: 1,
  minimumPurchases: [],
  taxes: {
    perception: false,
  },
  isWhatsappButtonEnabled: false,
};
