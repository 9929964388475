import type { IPriceGroup, ISalesForces } from 'core/model/interfaces/master-entities';

export const getPriceGroupList = (
  salesForceList: Array<ISalesForces>,
  pricesGroupListGeneral: Array<IPriceGroup>,
  selectedDexSalesForcesId?: string,
): Array<IPriceGroup> => {
  if (!selectedDexSalesForcesId) return pricesGroupListGeneral || [];

  const findSalesForces = salesForceList.find(salesForceItem => salesForceItem?.id === selectedDexSalesForcesId);
  if (findSalesForces) {
    return findSalesForces.externalPriceGroups;
  }

  return [];
};
