import { EHierarchyAssociation } from 'core/model/enums/hierarchy.enum';
import type { IHierarchyTreeSubcategory } from 'core/model/interfaces/navigation/hierarchy.interface';

export const defaultValues: IHierarchyTreeSubcategory = {
  id: '',
  name: '',
  families: [],
  associationType: EHierarchyAssociation.BY_FAMILIES,
  skus: '',
  treeId: '',
  categoryId: '',
};
