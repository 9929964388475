import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import {
  customersRoutePath,
  customizationRoutePath,
  loginRoutePath,
  logisticsRoutePath,
  masiveLoadsRoutePath,
  ordersRoutePath,
  productsRoutePath,
  staticPagesRoutePath,
  suggestedProductRoutePath,
} from 'core/constants/routes.constants';
import Customers from 'screens/customers';
import Login from 'screens/login';
import DeliveryCondition from 'screens/logistics';
import MasiveLoads from 'screens/massive-loads';
import Products from 'screens/products';
import { MainLayout } from 'shared/components/main-components/main-layout';
import { RequireAuth } from 'shared/components/require-auth';
import { SuggestedProductPage } from './suggested-product/suggested-product-form';
import CustomizationRoutes from './customization';
import Orders from './orders';
import StaticPages from './static-pages';

const AuthenticatedRouter = () => (
  <RequireAuth>
    <MainLayout maxWidthEnabled>
      <Outlet />
    </MainLayout>
  </RequireAuth>
);

const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path={`${loginRoutePath}`} element={<Login />} />
      <Route element={<AuthenticatedRouter />}>
        <Route path={`${customersRoutePath}/*`} element={<Customers />} />
        <Route path={`${customizationRoutePath}/*`} element={<CustomizationRoutes />} />
        <Route path={`${ordersRoutePath}/*`} element={<Orders />} />
        <Route path={`${productsRoutePath}/*`} element={<Products />} />
        <Route path={`${logisticsRoutePath}/*`} element={<DeliveryCondition />} />
        <Route path={`${masiveLoadsRoutePath}/*`} element={<MasiveLoads />} />
        <Route path={`${staticPagesRoutePath}/*`} element={<StaticPages />} />
        <Route path={suggestedProductRoutePath} element={<SuggestedProductPage />} />
        <Route path="*" element={<Navigate to={customersRoutePath} />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
