import { Link } from 'react-router-dom';
import { Icon, type TIconName } from '@insuma/mpp-ui/components/icon';

import './side-nav-link.scss';

export type TSubNavLink = Omit<INavLink, 'links'>;
export interface INavLink {
  to?: string;
  action?: () => void;
  label: string;
  icon?: TIconName;
  isActive?: boolean;
  sublinks?: Array<TSubNavLink>;
}

interface ISideNavLinkProps {
  link: INavLink;
  isActive?: boolean;
}

export const SideNavLink = ({ link, isActive = false }: ISideNavLinkProps) => (
  <Link className={`sidenav-link ${isActive ? 'sidenav-link--active' : ''}`} to={link.to || ''}>
    <div className="sidenav-link__icon-wrapper">
      {link.icon && (
        <div className="sidenav-link__icon">
          <Icon name={link.icon} size="md" />
        </div>
      )}
    </div>
    <p className="sidenav-link__text">{link.label}</p>
  </Link>
);
