import { Link as RouterLink } from 'react-router-dom';
import { Icon, type TIconName } from '@insuma/mpp-ui/components/icon';

import './link.scss';

export interface ILinkProps {
  children: React.ReactNode;
  to: string;
  icon?: TIconName;
  inverse?: boolean;
  className?: string;
}

export const Link = ({ to, inverse, icon, className, children }: ILinkProps) => (
  <RouterLink to={to} className={`mpp-link ${inverse ? 'mpp-link--inverse' : ''} ${className || ''}`}>
    {icon && <Icon className="mpp-link__icon" name={icon} size="sm" />}
    {children}
  </RouterLink>
);
