import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@alicorpdigital/dali-components-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tabs } from '@insuma/mpp-ui/components/tabs';
import { productsRoutePath } from 'core/constants/routes.constants';
import type { IProductDetail } from 'core/model/interfaces/products';
import { Link } from 'shared/components/link';
import { ProductBasicInformation } from './components/product-basic-information/product-basic-information';
import { ProductSaleInformation } from './components/product-sale-information/product-sale-information';
import { IProductFormData, schema } from './product-form.schema';

import './product-form.scss';

interface IProductFormProps {
  remoteData: IProductDetail;
  onSubmit: (value: IProductFormData) => void;
  isSaving?: boolean;
}

export const ProductForm = ({ remoteData, onSubmit, isSaving }: IProductFormProps) => {
  const { name, maximumSaleQuantity, minimumSaleQuantity, isActiveBaseUnitMeasure, isActiveSaleUnitMeasure } =
    remoteData;

  const defaultValues: IProductFormData = {
    maximumSaleQuantity,
    minimumSaleQuantity,
    showSaleUnit: isActiveSaleUnitMeasure,
    showBaseUnit: isActiveBaseUnitMeasure,
  };

  const formMethods = useForm<IProductFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <div className="product-form">
        <Link icon="caret-left" to={productsRoutePath}>
          Atrás
        </Link>

        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="product-form__title">{name}</h2>

          <Tabs defaultTab={1}>
            <Tabs.List>
              <Tabs.Tab value={1} title="Informacion basica" />
              <Tabs.Tab value={2} title="Informacion de venta" />
            </Tabs.List>

            <Tabs.Panel value={1}>
              <ProductBasicInformation remoteData={remoteData} />
            </Tabs.Panel>

            <Tabs.Panel value={2}>
              <ProductSaleInformation remoteData={remoteData} />
            </Tabs.Panel>
          </Tabs>

          <Button isLoading={isSaving} type="submit" isDisabled={Object.keys(errors).length > 0}>
            Guardar
          </Button>
        </form>
      </div>
    </FormProvider>
  );
};
