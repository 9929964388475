import { useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type {
  IHierarchyTreeCategory,
  IHierarchyTreeSubcategory,
} from 'core/model/interfaces/navigation/hierarchy.interface';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';
import { HierarchySubcategoryModalForm } from './hierarchy-subcategory-modal-form';

interface IEditHierarchySubcategoryModalProps {
  onClose(): void;
  fetchTreeHierarchy: () => void;
  treeId: string;
  selectedCategory: IHierarchyTreeCategory;
  selectedSubcategory: IHierarchyTreeSubcategory;
}

export const EditHierarchySubcategoryModal = ({
  onClose,
  fetchTreeHierarchy,
  treeId,
  selectedCategory,
  selectedSubcategory,
}: IEditHierarchySubcategoryModalProps) => {
  const [isButtonLoading, setButtonLoading] = useState(false);

  const onEdit = async (data: IHierarchyTreeSubcategory) => {
    if (selectedCategory && selectedSubcategory) {
      try {
        setButtonLoading(true);
        await apiService.hierarchy.editHierachySubcategory({
          categoryId: selectedCategory.id,
          treeId,
          id: selectedSubcategory.id,
          name: data.name,
          associationType: data.associationType,
          skus: data.skus,
          families: data.families,
        });
        setButtonLoading(false);
        notification('Subcategoría editada exitosamente');
        onClose();
        fetchTreeHierarchy();
      } catch (error) {
        setButtonLoading(false);
        notification.error(getErrorFromService(error, 'Hubo un error editando la subcategoría'));
      }
    }
  };

  return (
    <HierarchySubcategoryModalForm
      onSubmit={onEdit}
      onClose={onClose}
      isButtonLoading={isButtonLoading}
      categoryName={selectedCategory.name}
      selectedSubcategory={selectedSubcategory}
      treeId={treeId}
    />
  );
};
