import type { INavigationTreeDetail, INavigationTreeDetailCreateForm } from 'core/model/interfaces/navigation';
import { parseDistributorId, unParseDistributorId } from 'shared/utils/distributors.utils';
import { getHierarchyAssociation } from './hierarchy.utils';
import type { IGetNavigationTreeResponse, IPostNavigationTreeBody } from './navigation.dto';

export const toINavigationTreeDetail = (response: IGetNavigationTreeResponse): INavigationTreeDetail => ({
  name: response.name,
  externalPriceListIds: response.externalPriceListIds,
  externalDistributors: response.externalDistributors.map(dis => parseDistributorId(dis.id, dis.externalBranchId)),
  default: response.default,
});

export const mapNavigationTreeToPostBody = (
  data: INavigationTreeDetailCreateForm,
  imageURL: string,
): IPostNavigationTreeBody => ({
  name: data.name,
  externalDistributors: data.externalDistributors.map(d => unParseDistributorId(d)),
  externalPriceListIds: data.externalPriceListIds,
  items: [
    {
      name: data.categoryName,
      image: imageURL,
      items: [
        {
          name: data.subcategoryName,
          ...getHierarchyAssociation(data.associationType, data.families, data.skus),
        },
      ],
    },
  ],
});

export const mapNavigationTreeToPutBody = (data: INavigationTreeDetail) => ({
  name: data.name,
  externalDistributors: data.externalDistributors?.map(d => unParseDistributorId(d)),
  externalPriceListIds: data.externalPriceListIds,
});
