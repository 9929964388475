import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { distributorsRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import type { IDistributorForm } from 'core/model/interfaces/distributors';
import { apiService } from 'core/services';
import { distributorFormToDistributorDto } from 'core/services/distributors/distributors.mappers';
import { Loading } from 'shared/components/loading';
import { getErrorFromService } from 'shared/utils/https';
import { DistributorsForm } from '../distributors-form';
import { useDistributorDetail } from '../hooks/use-distributor-detail.hooks';

export const EditDistributor = () => {
  const { branchCode, distributorCode } = useParams();

  const {
    distributorDetail,
    visitDeliveryList,
    loading: distributorLoading,
    error: distributorError,
  } = useDistributorDetail(branchCode, distributorCode);

  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const route = `${logisticsRoutePath}${distributorsRoutePath}/`;

  const onSubmit = (data: IDistributorForm) => {
    const saveDistributor = async () => {
      if (!branchCode || !distributorCode)
        throw new Error('branchCode and distributorCode should not be undefined or null');
      try {
        setIsSaving(true);
        await apiService.distributors.updateDistributor({
          branchCode,
          distributorCode,
          body: distributorFormToDistributorDto(data),
        });
        notification.success('Operador editado exitosamente');
        navigate(`${logisticsRoutePath}${distributorsRoutePath}`);
      } catch (error) {
        notification.error(getErrorFromService(error, 'Error guardando los datos del operador'));
      } finally {
        setIsSaving(false);
      }
    };

    saveDistributor();
  };

  useEffect(() => {
    if (distributorError) navigate(route);
  }, [distributorError, route, navigate]);

  if (distributorLoading || !distributorDetail) return <Loading />;
  return (
    <DistributorsForm
      distributor={distributorDetail}
      customVisitDelivery={visitDeliveryList}
      onSubmit={onSubmit}
      isSaving={isSaving}
    />
  );
};
