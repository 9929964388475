import { EHierarchyAssociation } from 'core/model/enums/hierarchy.enum';
import type { IHierarchyTreeCategoryCreateForm } from 'core/model/interfaces/navigation/hierarchy.interface';

export const defaultValues: IHierarchyTreeCategoryCreateForm = {
  name: '',
  iconUrl: '',
  collapsed: false,
  treeId: '',
  subcategoryName: '',
  associationType: EHierarchyAssociation.BY_FAMILIES,
  skus: '',
  families: [],
  image: { url: '' },
};
