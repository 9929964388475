import * as yup from 'yup';
import { TEXT_ANY_FIELD_REQUIRED, TEXT_FIELD_REQUIRED_ERROR } from 'core/constants/error-messages.constants';

export const schema = yup.object().shape({
  name: yup.string().trim().required(TEXT_FIELD_REQUIRED_ERROR),
  externalExcludedCustomers: yup.string(),
  conditions: yup
    .object()
    .shape({
      externalPriceGroups: yup.array(),
      externalPriceLists: yup.array(),
      externalDistributors: yup.array(),
      externalZones: yup.array(),
      externalCustomers: yup.string(),
    })
    .atLeastOneOf(
      ['externalPriceGroups', 'externalPriceLists', 'externalDistributors', 'externalZones', 'externalCustomers'],
      TEXT_ANY_FIELD_REQUIRED,
    ),
});
