import { notification } from '@insuma/mpp-ui/notifications';
import { itemsPerPage } from 'core/constants/pagination.constants';
import { apiService } from 'core/services';
import type { IGetDistributorsDataResponse } from 'core/services/distributors/distributors.dto';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.helpers';
import { handleErrorMessage } from 'shared/utils/errors.utils';
import { SLICE_NAMESPACE } from '../constants';
import type { IDistributorsState } from '../types';

export const fetchDistributors = createAppAsyncThunk<IGetDistributorsDataResponse, { page?: number }>(
  `${SLICE_NAMESPACE}/fetchDistributors`,
  async ({ page }: { page?: number }, { getState }) => {
    const {
      distributors: { filters, currentPage },
    } = getState();
    try {
      return await apiService.distributors.getDistributors({ ...filters, page: page ?? currentPage, itemsPerPage });
    } catch (error) {
      notification.error(handleErrorMessage(error));
      throw error;
    }
  },
);

export const fetchDistributorsReducer: TSliceExtraReducer<IDistributorsState> = builder =>
  builder
    .addCase(fetchDistributors.pending, state => {
      state.isLoading = true;
    })
    .addCase(fetchDistributors.rejected, state => {
      state.isLoading = false;
    })
    .addCase(fetchDistributors.fulfilled, (state, action) => {
      state.distributors = action.payload.distributors;
      state.totalPages = action.payload.pagination.totalPages;
      state.currentPage = action.payload.pagination.currentPage;
      state.isLoading = false;
    });
