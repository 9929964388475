import { Navigate, useParams } from 'react-router-dom';
import { productsRoutePath } from 'core/constants/routes.constants';
import { Loading } from 'shared/components/loading';
import { useProductView } from '../hooks/use-product-view.hooks';
import { ProductForm } from '../product-form/product-form';

export const ViewProduct = () => {
  const { id } = useParams();
  const route = `${productsRoutePath}/`;
  const { remoteData, loading, onSubmit, isSaving, error } = useProductView(id);

  if (loading) return <Loading />;
  if (error || !remoteData) return <Navigate to={route} />;

  return <ProductForm remoteData={remoteData} onSubmit={onSubmit} isSaving={isSaving} />;
};
