import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { IPriceList } from 'core/model/interfaces/master-entities';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const usePriceList = (selectedDexPrices?: Array<string>) => {
  const [priceList, setPriceList] = useState<Array<IPriceList>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchPriceList = async () => {
      setLoading(true);

      try {
        const selectedDexPricesIds = selectedDexPrices?.join();
        const priceList = await apiService.masterEntities.getPriceLists(selectedDexPricesIds);
        setPriceList(priceList);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo las opciones de lista de precios'));
      } finally {
        setLoading(false);
      }
    };

    fetchPriceList();

    return () => {
      setPriceList([]);
    };
  }, [selectedDexPrices]);

  return { priceList, loading, error };
};
