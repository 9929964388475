import { useCallback, useEffect } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { notification } from '@insuma/mpp-ui/notifications';

import './single-image-uploader-dd.scss';

export interface ISingleImageUploaderDdProps {
  maxSize?: number;
  disabled?: boolean;
  setImageFile: (imageFile: any) => void;
  imageFile?: any;
  className?: string;
}

export const SingleImageUploaderDd = ({
  maxSize = 1024,
  setImageFile,
  imageFile,
  disabled,
  className,
}: ISingleImageUploaderDdProps) => {
  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length === 1) {
        setImageFile({
          ...imageFile,
          url: URL.createObjectURL(acceptedFiles[0]),
          blob: acceptedFiles[0],
        });
      }
    },
    [setImageFile, imageFile],
  );

  const onDropRejected = useCallback((rejectedFiles: Array<FileRejection>) => {
    rejectedFiles.forEach(file => {
      let message = 'Error inesperado';
      const { errors, file: originalFile } = file;

      if (errors && errors.length > 0 && errors[0].code === 'file-too-large') {
        message = `El tamaño del archivo (${Math.round(originalFile.size / 1024)}Kb) sobrepasa el límite permitido`;
      }

      notification.error(message, { duration: 2000 });
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: 'image/*',
    maxSize: maxSize * 1024,
    onDrop,
    onDropRejected,
    disabled,
  });

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (imageFile && imageFile.blob) URL.revokeObjectURL(imageFile.blob);
    };
  }, [imageFile]);

  return (
    <div className={`single-image-uploader-dd ${!className ? '' : className}`} {...getRootProps()} role="button">
      <input {...getInputProps()} aria-label="Cargador de imagen" />
      <div className="single-image-uploader-dd__main-content">
        {imageFile?.url ? (
          <div className="single-image-uploader-dd__preview-image-container">
            <img className="single-image-uploader-dd__preview-image" src={imageFile.url} alt="" />
          </div>
        ) : (
          <div className="single-image-uploader-dd__content">
            <span className="single-image-uploader-dd__icon">
              <Icon name="image" size="lg" />
            </span>
            <span className="single-image-uploader-dd__text">Peso de imagen máximo {maxSize} kb</span>
            <span className="single-image-uploader-dd__emphasis">Arrastra o selecciona tu archivo</span>
          </div>
        )}
      </div>
    </div>
  );
};
