import { useState } from 'react';
import { Button, Form, SearchBox } from '@alicorpdigital/dali-components-react';
import { Table } from '@insuma/mpp-ui/components/table';
import { itemsPerPage } from 'core/constants/pagination.constants';
import { ExportModal } from 'shared/components/export-modal';
import { useProductList } from '../hooks/use-product-list.hooks';
import { getProductsColumns } from '../products-utils';

import './product-list.scss';

export const ProductsList = () => {
  const {
    products,
    handleSearchChange,
    handleQueryClear,
    handleSearchEnterEvent,
    handleChangePage,
    query,
    isLoading,
    totalPages,
    currentPage,
    handleViewClick,
    handleExportAgree,
  } = useProductList();

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const columns = getProductsColumns(handleViewClick);

  return (
    <>
      <ExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        onExport={handleExportAgree}
        hasDateRange={false}
      />

      <div>
        <h2 className="products-list__title">Gestión de Productos</h2>
        <div className="products-list__actions">
          <Form onSubmit={handleSearchEnterEvent} className="products-list__search-form">
            <SearchBox
              className="products-list__search-box"
              size="sm"
              placeholder="Buscar por sku o nombre"
              value={query}
              onChange={handleSearchChange}
              onDeleteContent={handleQueryClear}
            />
            <Button isIconOnly iconWeight="bold" iconName="magnifying-glass" size="sm" type="submit" />
          </Form>

          <Button
            size="sm"
            variant="tertiary"
            hasIcon="left"
            iconName="download"
            onClick={() => setIsExportModalOpen(true)}
          >
            Exportación masiva
          </Button>
        </div>

        <Table
          rows={products}
          isLoading={isLoading}
          columns={columns}
          noDataMessage="No se encontraron productos."
          paginationModel={{
            currentPage: currentPage - 1,
            onChangePage: handleChangePage,
            totalPages,
            pageSize: itemsPerPage,
          }}
        />
      </div>
    </>
  );
};
