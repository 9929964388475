import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, IDatePickerProps } from '@insuma/mpp-ui/components/form';

export const DatePickerForm = ({ name, onBlur, onClear, ...rest }: IDatePickerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onBlur: onBlurHookForm, ...restOfFields } }) => (
        <DatePicker
          {...rest}
          onBlur={() => {
            onBlurHookForm();
            if (onBlur) onBlur();
          }}
          onClear={() => {
            restOfFields.onChange(null);
            if (onClear) onClear();
          }}
          inputRef={ref}
          {...restOfFields}
        />
      )}
    />
  );
};
