export enum EProductStateType {
  ACTIVO = 'Activo',
  INACTIVO = 'Inactivo',
}
export enum EHierarchyType {
  PLATFORM = 'PLATFORM',
  CATEGORY = 'CATEGORY',
  FAMILY = 'FAMILY',
}

export enum ETreeStateType {
  TO_PUBLISH = 'Por publicar',
  PUBLISHED = 'Publicado',
  PROCESSING = 'En proceso',
  ERROR = 'Error',
}

export enum EPresentationType {
  UNIT = 'UND',
  PACKAGE = 'PQT',
}

export enum EProductType {
  DEX = 1,
  APUDEX = 2,
  HYBRID = 3,
}
