import { defaultSuggestedProductConfiguration } from 'core/constants/suggested-product.constants';
import type { ISuggestion } from 'core/model/interfaces/suggested-product';
import { suggestedProductsData } from './suggested-product.data';
import {
  suggestedProductConfigDTOToSuggestion,
  suggestionToUpdateSuggestedProductConfigDTO,
} from './suggested-product.mappers';

export const suggestedProductsService = {
  getSuggestedProductDetails: async (): Promise<ISuggestion> => {
    const config = await suggestedProductsData.getSuggestedProductDetails();
    if (config.results.length === 0) {
      return defaultSuggestedProductConfiguration;
    }
    const data = suggestedProductConfigDTOToSuggestion(config);
    return data;
  },

  saveSuggestedProductDetails: (params: ISuggestion) =>
    suggestedProductsData.saveSuggestedProductDetails(suggestionToUpdateSuggestedProductConfigDTO(params)),
};
