import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { IDisplayDeliveryCondition } from 'core/model/interfaces/delivery-condition';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useDeliveryConfigurations = () => {
  const [deliveryConfigurationList, setDeliveryConfigurationList] = useState<Array<IDisplayDeliveryCondition>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchDeliveryConfigurations = async () => {
      setLoading(true);

      try {
        const { deliveryConfigurations } = await apiService.deliveryCondition.getDeliveryConditions();
        setDeliveryConfigurationList(deliveryConfigurations);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo las fechas de entrega'));
      } finally {
        setLoading(false);
      }
    };

    fetchDeliveryConfigurations();

    return () => {
      setDeliveryConfigurationList([]);
    };
  }, []);

  return { deliveryConfigurationList, loading, error };
};
