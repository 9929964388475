import { EHierarchyAssociation } from 'core/model/enums/hierarchy.enum';

interface IHierarchyAssociation {
  id: EHierarchyAssociation;
  content: string;
}

export const HIERARCHY_ASSOCIATION_TYPES: Array<IHierarchyAssociation> = [
  { content: 'Por familias', id: EHierarchyAssociation.BY_FAMILIES },
  { content: 'Por SKU', id: EHierarchyAssociation.BY_SKUS },
];
