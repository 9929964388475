import { itemsPerPage } from 'core/constants/pagination.constants';
import type { IExportsFilters } from 'core/model/interfaces/exports';
import { exportsData } from './exports.data';
import type { ICreateExportRequest } from './exports.dto';
import { exportListDTOToExportListDisplay } from './exports.mappers';

export const exportService = {
  getExports: async (params: IExportsFilters, page: number) =>
    exportListDTOToExportListDisplay(
      await exportsData.getExports({
        q: params.query,
        itemsPerPage,
        page,
      }),
    ),

  createFileExport: (params: ICreateExportRequest) => exportsData.createFileExport(params),
};
