import { ENGLISH_DAYS, SPANISH_DAYS, TEnglishDay, TSpanishDay } from 'core/constants/logistics.constants';
import type { IDeliveryOptions } from 'core/model/interfaces/delivery-condition';

export const defaultOptions: Array<IDeliveryOptions> = [
  {
    day: 'Lunes',
    beforeOptions: [1],
    afterOptions: [2],
  },
  {
    day: 'Martes',
    beforeOptions: [1],
    afterOptions: [2],
  },
  {
    day: 'Miércoles',
    beforeOptions: [1],
    afterOptions: [2],
  },
  {
    day: 'Jueves',
    beforeOptions: [1],
    afterOptions: [2],
  },
  {
    day: 'Viernes',
    beforeOptions: [1],
    afterOptions: [2],
  },
  {
    day: 'Sábado',
    beforeOptions: [1],
    afterOptions: [2],
  },
  {
    day: 'Domingo',
    beforeOptions: [1],
    afterOptions: [2],
  },
];

function getNumberDaysBetween(shoppingDay: TSpanishDay, deliveryDay: TEnglishDay): number {
  const shoppingDayNumber = SPANISH_DAYS.findIndex(d => d === shoppingDay);
  const deliveryDayNumber = ENGLISH_DAYS.findIndex(item => item === deliveryDay);
  let difference = deliveryDayNumber - shoppingDayNumber;
  if (difference <= 0) {
    difference += 7;
  }
  return difference;
}

export function getDeliveryOptions(deliveryDays: Array<TEnglishDay>): Array<IDeliveryOptions> {
  if (deliveryDays.length === 0) {
    return [...defaultOptions];
  }

  return SPANISH_DAYS.map(day => {
    let beforeOptions: Array<number> = [];
    let afterOptions: Array<number> = [];

    deliveryDays.forEach(deliveryDay => {
      beforeOptions = [...beforeOptions, getNumberDaysBetween(day, deliveryDay)];
    });

    beforeOptions = beforeOptions.sort();
    afterOptions = beforeOptions.filter(item => item > 1);
    if (afterOptions.length === 0) {
      afterOptions = [8];
    }

    return {
      day,
      beforeOptions,
      afterOptions,
    };
  });
}
