import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { groupingRulesRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import type { IGroupingRulesFormData } from 'core/model/interfaces/grouping-rules';
import { groupingRulesService } from 'core/services/grouping-rules/grouping-rules.service';
import { Loading } from 'shared/components/loading';
import { getErrorFromService } from 'shared/utils/https';
import { GroupingRulesForm } from '../grouping-rules-form/grouping-rules-form';
import { useGroupingRulesDetail } from '../hooks/use-grouping-rules-detail.hooks';

export const EditGroupingRules = () => {
  const { id } = useParams();
  const route = `${logisticsRoutePath}${groupingRulesRoutePath}`;

  const navigate = useNavigate();

  const { groupingRulesDetail, loading, error } = useGroupingRulesDetail(id);
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async (data: IGroupingRulesFormData) => {
    if (!id) throw new Error('Id should not be undefined or null');
    try {
      setIsSaving(true);
      await groupingRulesService.updateGroupingRule(data, id);

      notification.success('Regla de agrupación editada exitosamente');
      setIsSaving(false);
      navigate(route);
    } catch (error) {
      setIsSaving(false);
      notification.error(getErrorFromService(error, 'Error guardando los detalles de la regla de agrupación'));
    }
  };

  if (loading) return <Loading />;
  if (error || !groupingRulesDetail) return <Navigate to={route} />;

  return <GroupingRulesForm groupingRules={groupingRulesDetail} onSubmit={onSubmit} isSaving={isSaving} />;
};
