import { Configuration, PublicClientApplication } from '@azure/msal-browser';

const msalConfiguration: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_MSAL_TENANT_ID}`,
    redirectUri: import.meta.env.VITE_MSAL_REDIRECT_URI,
    postLogoutRedirectUri: import.meta.env.VITE_MSAL_POSTLOGOUT_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    allowNativeBroker: false,
  },
};

const msalPublicClientApplication = new PublicClientApplication(msalConfiguration);

export const msalScopes = [import.meta.env.VITE_MSAL_API_SCOPE, 'openid', 'email', 'profile'];

export default msalPublicClientApplication;
