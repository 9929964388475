import { get, useFormContext } from 'react-hook-form';
import { Form } from '@alicorpdigital/dali-components-react';
import { DragAndDropImageUploaderForm, InputValueForm } from 'shared/components/forms';

import './category-form-main-fields.scss';

export const HierarchyCategoryMainFields = () => {
  const {
    trigger,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <InputValueForm label="Nombre de la categoría" name="name" onChange={() => trigger('name')} />
      <Form.Group className="hierarchy-category-form-main-fields__icon-section">
        <Form.Label>Icono</Form.Label>
        <DragAndDropImageUploaderForm name="image" maxSize={500} />
        {errors.image && <Form.HelperText isInvalid>{get(errors, 'image.blob.message')}</Form.HelperText>}
      </Form.Group>
    </>
  );
};
