import * as yup from 'yup';
import {
  NO_SPECIAL_CHARACTERS_ERROR,
  TEXT_FIELD_REQUIRED_ERROR,
  textFieldMaxError,
} from 'core/constants/error-messages.constants';
import { NO_SPECIAL_CHARACTER_REGEX } from 'core/constants/regex.constant';
import { EHierarchyAssociation } from 'core/model/enums/hierarchy.enum';

export const associationSubcategorySchema = {
  associationType: yup.string().required(TEXT_FIELD_REQUIRED_ERROR),
  families: yup.array().when(['associationType'], {
    is: (associationType: string) => associationType === EHierarchyAssociation.BY_FAMILIES,
    then: schema => schema.min(1, 'Debe seleccionar una familia').required(TEXT_FIELD_REQUIRED_ERROR),
  }),
  skus: yup.string().when(['associationType'], {
    is: (associationType: string) => associationType === EHierarchyAssociation.BY_SKUS,
    then: schema => schema.required('Debe escribir un SKU'),
  }),
};

export const schema = yup.object({
  name: yup
    .string()
    .trim()
    .required(TEXT_FIELD_REQUIRED_ERROR)
    .max(200, textFieldMaxError(200))
    .matches(NO_SPECIAL_CHARACTER_REGEX, NO_SPECIAL_CHARACTERS_ERROR),
  ...associationSubcategorySchema,
});
