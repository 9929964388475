import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { TGroupingRuleItemListDisplay } from 'core/model/interfaces/grouping-rules';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useGroupingRules = () => {
  const [groupingRuleList, setGroupingRuleList] = useState<Array<TGroupingRuleItemListDisplay>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchGroupingRules = async () => {
      setLoading(true);

      try {
        const groupingRuleList = await apiService.groupingRules.getGroupingRuleList();
        const orderedGroupingRuleList = groupingRuleList.sort((a, b) => (a.priority > b.priority ? 1 : -1));
        setGroupingRuleList(orderedGroupingRuleList);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo las reglas de agrupación'));
      } finally {
        setLoading(false);
      }
    };

    fetchGroupingRules();

    return () => {
      setGroupingRuleList([]);
    };
  }, []);

  return { groupingRuleList, setGroupingRuleList, loading, error };
};
