import * as yup from 'yup';
import {
  NO_SPECIAL_CHARACTERS_ERROR,
  textFieldMaxError,
  textFieldMinError,
  textFieldRequired,
} from 'core/constants/error-messages.constants';
import { VALID_SLUG } from 'core/constants/regex.constant';

export interface IStaticPageFormData {
  title: string;
  slug: string;
  isPublic: boolean;
  active: boolean;
  content: string;
  trees: Array<string>;
  inFooterEnabled: boolean;
  inSidebarEnabled: boolean;
  inNavigationTree: boolean;
  images: Array<IStaticPageImage>;
}

export interface IStaticPageImage {
  blob: File | null;
  url: string;
}

const nonEmptyString = (field: string) => yup.string().required(textFieldRequired(field));
export const schema = yup.object().shape({
  title: nonEmptyString('Nombre de la página').max(500, textFieldMaxError(500)),
  slug: yup
    .string()
    .matches(VALID_SLUG, NO_SPECIAL_CHARACTERS_ERROR)
    .max(500, `${textFieldMaxError(500, 'Enlace Permanente')}`)
    .min(1, textFieldMinError(1, 'Enlace Permanente')),
  isPublic: yup.boolean(),
  active: yup.boolean(),
  content: yup.string(),
  images: yup.array().of(yup.mixed()).max(20, 'Puede elegir hasta 20 imágenes').label('Imágenes'),
  inFooterEnabled: yup.boolean(),
  inSidebarEnabled: yup.boolean(),
  inNavigationTree: yup.boolean(),
  trees: yup
    .array()
    .of(yup.mixed())
    .when('inNavigationTree', ([inNavigationTree], schema) =>
      inNavigationTree ? schema.min(1, 'Debes elegir al menos un árbol de navegación') : schema,
    ),
});
