import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '@insuma/mpp-ui/components/table';
import { itemsPerPage } from 'core/constants/pagination.constants';
import { distributorsRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import { useAppDispatch, useAppSelector } from 'core/store';
import { fetchDistributors, updateFilters } from 'core/store/logistics/distributors';
import { distributorColumns } from 'screens/logistics/distributors/distributors.constants';
import { Search } from 'shared/components/search';

import './distributors-list.scss';

export const DistributorsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { distributors, isLoading, totalPages, currentPage, filters } = useAppSelector(state => state.distributors);
  const route = `${logisticsRoutePath}${distributorsRoutePath}`;
  const onEditDistributorClick = (branchCode: string, distributorCode: string) =>
    navigate(`${route}/editar/${branchCode}/${distributorCode}`);

  const [query, setQuery] = useState<string>(filters.distributorName);

  const paginationModel = {
    totalPages,
    currentPage: currentPage - 1,
    onChangePage: (page: number) => dispatch(fetchDistributors({ page })),
    pageSize: itemsPerPage,
  };

  const columns = distributorColumns(onEditDistributorClick);

  const handleSearchEnterEvent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateFilters({ distributorName: query }));
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };
  const handleQueryClear = () => {
    setQuery('');
    dispatch(updateFilters({ distributorName: '' }));
  };

  useEffect(() => {
    dispatch(fetchDistributors({}));
  }, [filters, dispatch]);

  return (
    <div className="distributors-list">
      <h2 className="distributors-list__title">Operadores</h2>
      <Search
        placeholder="Buscar por nombre"
        query={query}
        handleSearchChange={handleSearchChange}
        handleQueryClear={handleQueryClear}
        handleSearchEnterEvent={handleSearchEnterEvent}
      />
      <Table rows={distributors} columns={columns} paginationModel={paginationModel} isLoading={isLoading} />
    </div>
  );
};
