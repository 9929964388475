import * as yup from 'yup';
import {
  NO_SPECIAL_CHARACTERS_ERROR,
  TEXT_FIELD_REQUIRED_ERROR,
  textFieldMaxError,
} from 'core/constants/error-messages.constants';
import { NO_SPECIAL_CHARACTER_REGEX } from 'core/constants/regex.constant';
import { associationSubcategorySchema } from '../../subcategory-modal/hierarchy-subcategory-modal-form/hierarchy-subcategory-modal-form.schema';

export const schema = yup.object({
  name: yup
    .string()
    .trim()
    .required(TEXT_FIELD_REQUIRED_ERROR)
    .max(200, textFieldMaxError(200))
    .matches(NO_SPECIAL_CHARACTER_REGEX, NO_SPECIAL_CHARACTERS_ERROR),
  subcategoryName: yup
    .string()
    .trim()
    .required(TEXT_FIELD_REQUIRED_ERROR)
    .max(200, textFieldMaxError(200))
    .matches(NO_SPECIAL_CHARACTER_REGEX, NO_SPECIAL_CHARACTERS_ERROR),
  ...associationSubcategorySchema,
  image: yup.object().shape({
    blob: yup.mixed().required(TEXT_FIELD_REQUIRED_ERROR),
  }),
});
