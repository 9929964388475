import { ReactNode } from 'react';

import './customer-form-container.scss';

interface ICustomerFormContainerProps {
  children: ReactNode;
  type?: 'dark' | 'outlined';
}

export const CustomerFormContainer = ({ children, type }: ICustomerFormContainerProps) => (
  <div className={type ? `customer-form-container--${type}` : 'customer-form-container'}>{children}</div>
);
