import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { staticPagesRoutePath } from 'core/constants/routes.constants';
import { staticPagesService } from 'core/services/static-pages/static-pages.service';
import { Loading } from 'shared/components/loading';
import { getErrorFromService } from 'shared/utils/https';
import { useStaticPage } from '../hooks/use-static-page.hooks';
import type { IStaticPageFormData } from '../static-pages-form/schema';
import { StaticPageForm } from '../static-pages-form/static-pages-form';

export const EditStaticPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const staticPageId = Number(id);
  const { staticPage, error, loading } = useStaticPage(staticPageId);

  const onSubmit = ({ images, ...values }: IStaticPageFormData) => {
    const editStaticPage = async () => {
      try {
        await staticPagesService.editStaticPage(staticPageId, { ...values, imagesUrls: [] });
        notification.success('Página estática editada exitosamente');
        navigate(staticPagesRoutePath);
      } catch (error) {
        notification.error(getErrorFromService(error, 'Error editando página estática'));
      }
    };

    editStaticPage();
  };

  if (loading) return <Loading type="spin" />;
  if (error || !staticPage) return <Navigate to={staticPagesRoutePath} />;

  return <StaticPageForm mode="edit" onSubmit={onSubmit} remoteData={staticPage} />;
};
