export enum ESuggestedProductStateType {
  ENABLED = 'Activado',
  DISABLED = 'Inactivo',
}

export enum ESuggestion {
  UNFORGIVABLES = 'I',
  ENHANCERS = 'P',
  IDEAL_SELECTION = 'S',
}

export enum ESuggestionLabel {
  UNFORGIVABLES = 'Imperdonables',
  ENHANCERS = 'Potenciadores',
  IDEAL_SELECTION = 'Surtido Ideal',
}

export enum ESuggestedProductView {
  INTERNAL_PAGE = 1,
  SHOPPING_CART = 2,
  HOMEPAGE = 3,
  FIXED_BANNER = 4,
}

export enum ESuggestedProductViewName {
  INTERNAL_PAGE = 'Página interna',
  SHOPPING_CART = 'Carrito de compra',
  HOMEPAGE = 'Home',
  FIXED_BANNER = 'Banner',
}
