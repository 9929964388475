import { useState } from 'react';

export const useLoadingPhrases = () => {
  const loadingPhrases = ['Subiendo, por favor espera...', 'Cargando...', 'Ya casi', 'Un poco más', 'Falta poco'];
  const [phraseIndex, setPhraseIndex] = useState(0);
  const currentPhrase = loadingPhrases[phraseIndex];

  const startLoadingPhrases = () => {
    const intervalId = setInterval(() => {
      setPhraseIndex(pi => {
        if (pi + 1 === loadingPhrases.length) {
          return 0;
        }
        return pi + 1;
      });
    }, 5000);

    return intervalId;
  };

  const endLoadingPhrases = (intervalId: NodeJS.Timeout) => {
    setPhraseIndex(0);
    clearInterval(intervalId);
  };

  return { currentPhrase, startLoadingPhrases, endLoadingPhrases };
};
