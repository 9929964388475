import { useFormContext } from 'react-hook-form';
import { Check, FormGroup, HelperText } from '@alicorpdigital/dali-components-react';
import { useCSS } from '@insuma/mpp-ui/hooks';
import type { ICustomVisitDelivery } from 'core/model/interfaces/distributors';
import { DistributorSection } from 'screens/logistics/distributors/components/distributor-section';

import './distributor-mirror-days.scss';

interface IDistributorMirrorDaysProps {
  customVisitDeliveries: Array<ICustomVisitDelivery>;
}

export const DistributorMirrorDays = ({ customVisitDeliveries }: IDistributorMirrorDaysProps) => {
  const css = useCSS('distributor-mirror-days');
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <DistributorSection
      subtitle="2. Días espejo en fechas de entrega"
      description="Selecciona una opción de día espejo para una fecha de entrega."
    >
      <FormGroup>
        <div className={css()}>
          {customVisitDeliveries.map(visitDelivery => (
            <Check
              defaultChecked={visitDelivery.selected}
              key={visitDelivery.customerVisitDeliveryId}
              type="radio"
              label={visitDelivery.description}
              id={visitDelivery.customerVisitDeliveryId.toString()}
              value={visitDelivery.customerVisitDeliveryId.toString()}
              {...register('visitDelivery')}
            />
          ))}
          {errors.visitDelivery && <HelperText isInvalid>{errors.visitDelivery.message}</HelperText>}
        </div>
      </FormGroup>
    </DistributorSection>
  );
};
