import { Route, Routes } from 'react-router-dom';
import { MasiveExports } from './masive-exports/masive-exports-list';

const MasiveLoads = () => (
  <Routes>
    <Route path="masive-exportations" element={<MasiveExports />} />
  </Routes>
);

export default MasiveLoads;
