import type { IDeliveryConditionState } from './types';

export const SLICE_NAMESPACE = 'logistics/delivery-condition';

export const initialState: IDeliveryConditionState = {
  isLoading: false,
  deliveryConditions: [],
  currentPage: 1,
  totalPages: 0,
  filters: {
    q: '',
  },
};
