import { createSlice } from '@reduxjs/toolkit';
import { initialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

const customizationSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState,
  reducers,
});

export const { updateFilters } = customizationSlice.actions;

export default customizationSlice.reducer;
