import { Form } from '@alicorpdigital/dali-components-react';
import type { ICustomerCommercialData } from 'core/model/interfaces/customers';
import { CustomerFormDivider, CustomerFormLayout } from 'screens/customers/customer-form/components';

interface ICustomerCommercialDataProps {
  commercialData: ICustomerCommercialData;
}
export const CustomerCommercialData = ({ commercialData }: ICustomerCommercialDataProps) => {
  const name = commercialData.documentType !== 'RUC' ? commercialData.name : '';
  const lastNames =
    commercialData.documentType !== 'RUC' ? `${commercialData.firstLastName} ${commercialData.secondLastName}` : '';
  const businessName = commercialData.documentType === 'RUC' ? commercialData.businessName : '';

  return (
    <section>
      <CustomerFormLayout columns="three">
        <Form.Group>
          <Form.Label>Razón social</Form.Label>
          <Form.Input value={businessName} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Nombres</Form.Label>
          <Form.Input value={name} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Apellidos</Form.Label>
          <Form.Input value={lastNames} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Tipo de documento</Form.Label>
          <Form.Input value={commercialData.documentType} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Número de identificación</Form.Label>
          <Form.Input value={commercialData.documentNumber} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Número de celular</Form.Label>
          <Form.Input value={commercialData.primaryPhone} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Número de contacto 2</Form.Label>
          <Form.Input value={commercialData.secondaryPhone} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Correo Electrónico</Form.Label>
          <Form.Input value={commercialData.email} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Dirección fiscal / Dirección domicilio</Form.Label>
          <Form.Input value={commercialData.address} disabled />
        </Form.Group>
      </CustomerFormLayout>
      <CustomerFormDivider />
      <CustomerFormLayout columns="three">
        <Form.Group>
          <Form.Label>Grupo cliente Dex</Form.Label>
          <Form.Input value={commercialData.dexCustomerGroup} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Grupo de precios Dex</Form.Label>
          <Form.Input value={commercialData.dexPricesGroup} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Lista de precios Dex</Form.Label>
          <Form.Input value={commercialData.dexPricesList} disabled />
        </Form.Group>
      </CustomerFormLayout>
      <CustomerFormDivider />
      <CustomerFormLayout>
        <Form.Group>
          <Form.Label>Flag Lead Digital</Form.Label>
          <Form.Input value={commercialData.digitalLeadFlag} disabled />
        </Form.Group>
      </CustomerFormLayout>
    </section>
  );
};
