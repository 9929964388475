import { SubHeading, Text } from '@alicorpdigital/dali-components-react';

import './side-nav-header.scss';

interface ISideNavHeaderProps {
  userFullName: string;
}

export const SideNavHeader = ({ userFullName }: ISideNavHeaderProps) => (
  <div className="sidenav-header">
    <Text as="span">Bienvenido</Text>
    <SubHeading size="md">{userFullName}</SubHeading>
  </div>
);
