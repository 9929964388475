import type { ICustomersListFilters, IDisplayCustomers } from 'core/model/interfaces/customers';
import { apiService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.helpers';
import { SLICE_NAMESPACE } from '../constants';
import type { ICustomersState } from '../types';

export const fetchAllCustomers = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/fetchAllCustomers`,
  async ({ page }: { page?: number }, { getState }): Promise<IDisplayCustomers> => {
    const { customers } = getState();
    const { filters, currentPage } = customers;

    const params: ICustomersListFilters = {
      records: 10,
      page: page ?? currentPage,
      ...filters,
    };

    return apiService.customer.getCustomers(params);
  },
);

export const fetchAllCustomersReducer: TSliceExtraReducer<ICustomersState> = builder =>
  builder
    .addCase(fetchAllCustomers.pending, state => {
      state.isLoading = true;
    })
    .addCase(fetchAllCustomers.fulfilled, (state, action) => {
      state.customers = action.payload.customers;
      state.totalPages = action.payload.pagination.totalPages;
      state.currentPage = action.payload.pagination.currentPage;
      state.isLoading = false;
    })
    .addCase(fetchAllCustomers.rejected, state => {
      state.isLoading = false;
      state.customers = [];
    });
