import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { initialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

const distributorsSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState,
  reducers,
  extraReducers,
});

export const { updateFilters } = distributorsSlice.actions;

export default distributorsSlice.reducer;
