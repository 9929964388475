import { useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type {
  IHierarchyTreeCategory,
  IHierarchyTreeSubcategory,
} from 'core/model/interfaces/navigation/hierarchy.interface';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';
import { HierarchySubcategoryModalForm } from './hierarchy-subcategory-modal-form';

interface ICreateHierarchySubcategoryModalProps {
  onClose(): void;
  fetchTreeHierarchy: () => void;
  treeId: string;
  selectedCategory: IHierarchyTreeCategory;
}

export const CreateHierarchySubcategoryModal = ({
  onClose,
  fetchTreeHierarchy,
  treeId,
  selectedCategory,
}: ICreateHierarchySubcategoryModalProps) => {
  const [isButtonLoading, setButtonLoading] = useState(false);

  const onCreate = async (data: IHierarchyTreeSubcategory) => {
    if (selectedCategory) {
      try {
        setButtonLoading(true);
        await apiService.hierarchy.createHierachySubcategory({
          treeId,
          categoryId: selectedCategory.id,
          name: data.name,
          associationType: data.associationType,
          skus: data.skus,
          families: data.families,
        });
        setButtonLoading(false);
        notification('Subcategoría creada exitosamente');
        onClose();
        fetchTreeHierarchy();
      } catch (error) {
        setButtonLoading(false);
        notification.error(getErrorFromService(error, 'Hubo un error creando la subcategoría'));
      }
    }
  };

  return (
    <HierarchySubcategoryModalForm
      onSubmit={onCreate}
      onClose={onClose}
      isButtonLoading={isButtonLoading}
      categoryName={selectedCategory.name}
      treeId={treeId}
    />
  );
};
