import { ESuggestion, ESuggestionLabel } from 'core/model/enums/suggested-product.enum';
import type { ISuggestion } from 'core/model/interfaces/suggested-product';

const defaultSuggestion = (suggestionType: ESuggestion, suggestionTitle: ESuggestionLabel) => ({
  title: suggestionTitle,
  state: true,
  maxSuggestions: 12,
  type: suggestionType,
});

export const defaultSuggestedProductConfiguration: ISuggestion = {
  internalPage: {
    unforgivables: defaultSuggestion(ESuggestion.UNFORGIVABLES, ESuggestionLabel.UNFORGIVABLES),
    enhancers: defaultSuggestion(ESuggestion.ENHANCERS, ESuggestionLabel.ENHANCERS),
    idealSelection: defaultSuggestion(ESuggestion.IDEAL_SELECTION, ESuggestionLabel.IDEAL_SELECTION),
  },
  shoppingCart: { ...defaultSuggestion(ESuggestion.UNFORGIVABLES, ESuggestionLabel.UNFORGIVABLES) },
  homepage: { ...defaultSuggestion(ESuggestion.UNFORGIVABLES, ESuggestionLabel.UNFORGIVABLES) },
  fixedBanner: { title: '', state: false },
  internalPageId: null,
  shoppingCartId: null,
  homepageId: null,
  fixedBannerId: null,
};
