import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { customizationRoutePath } from 'core/constants/routes.constants';
import type { ICustomizationFormData } from 'core/model/interfaces/customization';
import { apiService } from 'core/services';
import { Loading } from 'shared/components/loading';
import { getErrorFromService } from 'shared/utils/https';
import { CustomizationForm } from '../components/customization-form';
import { useCustomizationDetail } from '../hooks/use-customization-detail.hooks';

export const EditCustomization = () => {
  const { id = '' } = useParams<string>();

  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const customizationListRoute = `${customizationRoutePath}/`;

  const { formData, isLoading, error } = useCustomizationDetail(id);

  const onSubmit = async (data: ICustomizationFormData) => {
    try {
      setIsSaving(true);
      await apiService.customization.updateCustomization(id, data);

      notification.success('Personalización editada exitosamente');
      setIsSaving(false);
      navigate(`${customizationRoutePath}`);
    } catch (error) {
      setIsSaving(false);
      notification.error(getErrorFromService(error));
    }
  };

  useEffect(() => {
    if (error) navigate(customizationListRoute);
  }, [error, customizationListRoute, navigate]);

  if (!id) return <Navigate to={customizationListRoute} />;
  if (isLoading) return <Loading />;
  return <CustomizationForm onSubmit={onSubmit} formData={formData} isSaving={isSaving} />;
};
