import { useFormContext } from 'react-hook-form';
import type { IProductDetail } from 'core/model/interfaces/products';
import { InputValueForm } from 'shared/components/forms';
import { SimpleInputValue } from 'shared/components/simple-input-value';
import type { IProductFormData } from '../../product-form.schema';

import './product-sale-information.scss';

interface ISaleInformationProps {
  remoteData: IProductDetail;
}

export const ProductSaleInformation = ({ remoteData }: ISaleInformationProps) => {
  const { trigger } = useFormContext<IProductFormData>();

  const {
    apudexInfo,
    externalPlatform,
    externalSubPlatform,
    externalCategory,
    externalFamily,
    externalVariety,
    business,
    subBusiness,
    externalBrand,
  } = remoteData;

  const { price: priceApudex, isDisabledSale, noStock, onSale, hasTaxes, hasPerception, externalTier } = apudexInfo;

  return (
    <div className="product-sale-information__card">
      <h4 className="product-sale-information__subtitle">Información de venta</h4>
      <div className="product-sale-information__section">
        <InputValueForm
          label="Mínimo de venta*"
          name="minimumSaleQuantity"
          onChange={() => trigger('maximumSaleQuantity')}
        />

        <InputValueForm
          label="Máximo de venta*"
          name="maximumSaleQuantity"
          onChange={() => trigger('minimumSaleQuantity')}
        />

        <SimpleInputValue
          label="Precio de venta APUDEX"
          value={priceApudex?.toString()}
          className="product-sale-information__full-row"
          disabled
        />
      </div>

      <hr />

      <div className="product-sale-information__section">
        <SimpleInputValue label="Puede ser vendido APUDEX" value={onSale ? 'Si' : 'No'} disabled />
        <SimpleInputValue label="Permitir agotamiento de stock APUDEX" value={noStock ? 'Si' : 'No'} disabled />
        <SimpleInputValue
          label="Inhabilitado para la venta APUDEX"
          value={isDisabledSale ? 'Si' : 'No'}
          className="product-sale-information__full-row"
          disabled
        />
      </div>

      <hr />

      <div className="product-sale-information__section">
        <SimpleInputValue label="Impuesto de cliente APUDEX" value={hasTaxes ? 'Si' : 'No'} disabled />
        <SimpleInputValue label="Percepciones APUDEX" value={hasPerception ? 'Si' : 'No'} disabled />
      </div>

      <hr />

      <div className="product-sale-information__section">
        <SimpleInputValue label="Plataforma" value={externalPlatform?.description} disabled />
        <SimpleInputValue label="Sub Plataforma" value={externalSubPlatform?.description} disabled />
        <SimpleInputValue label="Categoría" value={externalCategory?.description} disabled />
        <SimpleInputValue label="Familia" value={externalFamily?.description} disabled />
        <SimpleInputValue
          label="Variedad"
          value={externalVariety?.description}
          className="product-sale-information__full-row"
          disabled
        />
      </div>

      <hr />

      <div className="product-sale-information__section">
        <SimpleInputValue label="Negocio" value={business?.description} disabled />
        <SimpleInputValue label="Subnegocio" value={subBusiness?.description} disabled />
        <SimpleInputValue label="Marca" value={externalBrand?.description} disabled />
        <SimpleInputValue label="Tier" value={externalTier?.description} disabled />
      </div>
    </div>
  );
};
