import { FOOTER_TREE_ID, SIDEBAR_TREE_ID } from 'core/constants/static-pages.constants';
import { stringToBase64 } from 'shared/utils/encode.utils';
import type { ICreateStaticPageBody, TCreateStaticPageDTO } from './static-pages.dto';

export const fromFormDataToServiceBody = (data: TCreateStaticPageDTO) => {
  let treeIds: Array<number> = [];

  if (data.inSidebarEnabled) {
    treeIds.push(SIDEBAR_TREE_ID);
  }
  if (data.inFooterEnabled) {
    treeIds.push(FOOTER_TREE_ID);
  }
  if (data.inNavigationTree) {
    treeIds = [...treeIds, ...data.trees.map(t => parseInt(t, 10))];
  }

  const body: ICreateStaticPageBody = {
    title: data.title,
    slug: data.slug,
    content: stringToBase64(data.content),
    isPrivate: !data.isPublic,
    active: data.active,
    images: data.imagesUrls,
    treeIds: Array.from(new Set(treeIds).values()),
  };

  return body;
};
