import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type {
  IHierarchyTree,
  IHierarchyTreeCategory,
  IHierarchyTreeSubcategory,
} from 'core/model/interfaces/navigation/hierarchy.interface';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

interface IUseHierarchyTreeActionsProps {
  treeId: string;
  hierarchyTree: IHierarchyTree | null;
  fetchHierarchyTree: () => void;
}

export const useHierarchyTreeActions = ({
  treeId,
  hierarchyTree,
  fetchHierarchyTree,
}: IUseHierarchyTreeActionsProps) => {
  const [sortedCategories, setCategories] = useState<Array<IHierarchyTreeCategory>>([]);

  useEffect(() => {
    setCategories(hierarchyTree?.categories ?? []);
  }, [hierarchyTree?.categories]);

  const handleSortCategories = async (newCategories: Array<IHierarchyTreeCategory>) => {
    const currentCategories = sortedCategories;
    try {
      setCategories(
        newCategories.map((category, index) => ({
          ...category,
          priority: index + 1,
        })),
      );
      await apiService.hierarchy.updateHierarchyItemsWeight({
        items: newCategories.map((category, index) => ({
          itemId: Number(category.id),
          weight: index + 1,
        })),
        treeId,
      });
    } catch (error) {
      notification.error('Error actualizando prioridad de categorías');
      setCategories(currentCategories);
    }
  };

  const handleSortSubcategories = async (index: number, subcategories: Array<IHierarchyTreeSubcategory>) => {
    const currentCategories = [...sortedCategories];

    try {
      const newCategories = [...sortedCategories];
      const categoryFound = newCategories[index];
      categoryFound.subcategories = subcategories;
      setCategories(newCategories);

      await apiService.hierarchy.updateHierarchyItemsWeight({
        items: subcategories.map((subcategory, index) => ({
          itemId: Number(subcategory.id),
          weight: index + 1,
        })),
        treeId,
      });
    } catch (error) {
      notification.error(getErrorFromService(error, 'Error actualizando prioridad de subcategorias'));
      setCategories(currentCategories);
    }
  };

  const handlePublish = async () => {
    try {
      await apiService.hierarchy.publishHierachyTrees(treeId);
      notification('Árbol publicado exitosamente.');
      fetchHierarchyTree();
    } catch (error) {
      notification.error(getErrorFromService(error));
    }
  };

  const handleCollapsedToogle = (categoryId: string, collapsed: boolean) => {
    const categoryFoundIndex = sortedCategories.findIndex(category => category.id === categoryId);
    if (categoryFoundIndex !== -1) {
      const newCategories = [...sortedCategories];
      newCategories[categoryFoundIndex].collapsed = collapsed;
      setCategories(newCategories);
    }
  };

  return {
    sortActions: {
      handleSortCategories,
      handleSortSubcategories,
    },
    sharedActions: {
      handlePublish,
      handleCollapsedToogle,
    },
    sortedCategories,
  };
};
