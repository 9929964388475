import type { ICustomersState } from './types';

export const SLICE_NAMESPACE = 'customers';

export const initialState: ICustomersState = {
  areFiltersApplied: false,
  customers: [],
  filters: {
    query: '',
  },
  currentPage: 1,
  totalPages: 0,
  isLoading: false,
};
