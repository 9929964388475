import type { TGroupingRuleItemListDisplay } from 'core/model/interfaces/grouping-rules';
import type { IPutPriorityGroupingRuleParams } from 'core/services/grouping-rules/grouping-rules.dto';

export const getOrderedItemsToService = (
  orderedDisplayItems: Array<TGroupingRuleItemListDisplay>,
): Array<IPutPriorityGroupingRuleParams> =>
  orderedDisplayItems.map((item, index) => ({
    id: item.id,
    priority: index + 1,
  }));
