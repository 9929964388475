import { apiService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.helpers';
import { SLICE_NAMESPACE } from '../constants';
import { INavigationState } from '../types';

export const fetchNavigationTrees = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/fetchNavigationTrees`,
  ({ page, query }: { page: number; query: string }) => apiService.navigation.getNavigationTrees({ page, name: query }),
);

export const fetchNavigationTreesReducer: TSliceExtraReducer<INavigationState> = builder =>
  builder
    .addCase(fetchNavigationTrees.pending, state => {
      state.isLoading = true;
    })
    .addCase(fetchNavigationTrees.rejected, state => {
      state.isLoading = false;
      state.navigationTrees = [];
    })
    .addCase(fetchNavigationTrees.fulfilled, (state, action) => {
      state.isLoading = false;
      state.navigationTrees = action.payload.trees;
      state.pagination = action.payload.pagination;
    });
