import { Form, FormGroup, InputProps } from '@alicorpdigital/dali-components-react';

interface ISimpleInputValueProps extends InputProps {
  label: string;
  value: string;
  className?: string;
}

export const SimpleInputValue = ({ label, value, className, ...rest }: ISimpleInputValueProps) => (
  <FormGroup className={className}>
    <Form.Label>{label}</Form.Label>
    <Form.Input value={value} {...rest} />
  </FormGroup>
);
