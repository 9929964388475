import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { customersRoutePath } from 'core/constants/routes.constants';
import { Loading } from 'shared/components/loading';
import { CustomerForm } from '../customer-form';
import useCustomerDetail from '../hooks/use-customer-detail.hooks';

export const CustomerDetail = () => {
  const { customer, loading, error } = useCustomerDetail();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) navigate(customersRoutePath);
  }, [error, navigate]);

  if (loading) return <Loading type="spin" />;
  if (!customer) return <span>No existen datos del cliente</span>;

  return <CustomerForm customer={customer} />;
};
