import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Text } from '@alicorpdigital/dali-components-react';
import { EApplyToOption } from 'core/model/enums/distributor.enum';
import type { IDistributorForm } from 'core/model/interfaces/distributors';
import { convertGroup } from 'screens/logistics/distributors/distributor.utils';

import './minimum-purchase-item.scss';

interface IPurchaseItemProps {
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  index: number;
}

export const MinimumPurchaseItem = ({ index, onEdit, onDelete }: IPurchaseItemProps) => {
  const { control } = useFormContext<IDistributorForm>();

  const fieldPurchaseItem = useWatch<IDistributorForm, 'minimumPurchases'>({
    control,
    name: 'minimumPurchases',
  })[index];
  return (
    <div className="minimum-purcharse-item" key={index}>
      <div className="minimum-purcharse-item--header">
        <Button variant="tertiary" type="button" hasIcon="left" iconName="pencil" onClick={() => onEdit(index)}>
          Editar
        </Button>
        <Button variant="tertiary" type="button" hasIcon="left" iconName="trash" onClick={() => onDelete(index)}>
          Eliminar
        </Button>
      </div>
      <div className="minimum-purcharse-item--content">
        {fieldPurchaseItem?.externalSalesForce && (
          <div>
            <Text>
              <strong>Fuerza de venta:</strong>
            </Text>
            <Text>{fieldPurchaseItem.externalSalesForce}</Text>
          </div>
        )}

        {fieldPurchaseItem?.priceGroup.length > 0 && (
          <div>
            <Text>
              <strong>Grupo de precios:</strong>
            </Text>
            <Text>
              {convertGroup(fieldPurchaseItem.priceGroup.map(priceGroupItem => priceGroupItem.priceGroupDescription))}
            </Text>
          </div>
        )}

        {fieldPurchaseItem?.customerPriceList.length > 0 && (
          <div>
            <Text>
              <strong>Lista de precios:</strong>
            </Text>
            <Text>
              {convertGroup(
                fieldPurchaseItem.customerPriceList.map(
                  customerPriceListItem => customerPriceListItem.priceListDescription,
                ),
              )}
            </Text>
          </div>
        )}

        {fieldPurchaseItem?.zones.length > 0 && (
          <div>
            <Text>
              <strong>Por oficina:</strong>
            </Text>
            <Text>{convertGroup(fieldPurchaseItem.zones.map(zonesItem => zonesItem.description))}</Text>
          </div>
        )}

        {fieldPurchaseItem?.customers && (
          <div>
            <Text>
              <strong>Por cliente:</strong>
            </Text>
            <Text>{fieldPurchaseItem.customers}</Text>
          </div>
        )}

        {fieldPurchaseItem.applyTo === EApplyToOption.CART ? (
          <div>
            <Text>
              <strong>Todo el carrito:</strong>
            </Text>
            <Text>S/ {fieldPurchaseItem.amount}</Text>
          </div>
        ) : (
          <>
            <div>
              <Text>
                <strong>Portafolio Alicorp:</strong>
              </Text>
              <Text>S/ {fieldPurchaseItem.amountAlicorp}</Text>
            </div>
            <div>
              <Text>
                <strong>Portafolio Aliados:</strong>
              </Text>
              <Text>S/ {fieldPurchaseItem.amountAllied}</Text>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
