import { notification } from '@insuma/mpp-ui/notifications';
import type { IExportsListDisplay } from 'core/model/interfaces/exports';
import { apiService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.helpers';
import { handleErrorMessage } from 'shared/utils/errors.utils';
import { SLICE_NAMESPACE } from '../constants';
import type { IExportsState } from '../types';

export const fetchExports = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/fetchExports`,
  async ({ page }: { page?: number }, { getState }): Promise<IExportsListDisplay> => {
    const { exports } = getState();
    const { filters, currentPage } = exports;

    try {
      return await apiService.exports.getExports(filters, page ?? currentPage);
    } catch (error) {
      notification.error(handleErrorMessage(error));
      throw error;
    }
  },
);

export const fetchExportsReducer: TSliceExtraReducer<IExportsState> = builder =>
  builder
    .addCase(fetchExports.pending, state => {
      state.isExportsLoading = true;
    })
    .addCase(fetchExports.fulfilled, (state, action) => {
      state.isExportsLoading = false;
      state.exports = action.payload.exportList;
      state.totalPages = action.payload.pagination.totalPages;
      state.currentPage = action.payload.pagination.currentPage;
    })
    .addCase(fetchExports.rejected, state => {
      state.isExportsLoading = false;
      state.exports = [];
    });
