import type { ICustomFile } from 'core/model/interfaces/custom-file';
import type { IShortcutFormData, IShortcutItemFormData } from 'core/model/interfaces/customization';

export const getShortcutFormDataWithImages = (
  shortcuts: Array<IShortcutItemFormData>,
  images: Array<ICustomFile>,
): IShortcutFormData => ({
  shortcuts: shortcuts.map((shortcut, index) => ({
    ...shortcut,
    image: {
      url: images[index].url,
      blob: images[index].blob,
    },
  })),
});
