import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import type { ICreateExportRequest, IGetExportsParams, IGetExportsResponseDTO } from './exports.dto';

const exportListBaseURL = '/gtm-static-management/v1/export-list';
export const exportFileBaseURL = '/gtm-static-management/v1/files/download';

export const exportsData = {
  getExports: async (params: IGetExportsParams): Promise<IGetExportsResponseDTO> => {
    const { data } = await https.get<IServiceSuccess<IGetExportsResponseDTO>>(exportListBaseURL, { params });
    return data.data;
  },

  createFileExport: async (params: ICreateExportRequest): Promise<void> => {
    await https.post(exportFileBaseURL, params);
  },
};
