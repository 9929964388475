/* eslint-disable no-useless-escape */
export const LATITUDE_REGEX = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,20})?))$/;
export const LONGITUDE_REGEX =
  /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,20})?))$/;
export const NUMBER_REGEX = /^[0-9]*$/;
export const NUMBER_CORRELATIVE_REGEX = /^(0|[0-9][0-9]*)$/;
export const NUMBER_MAXTWO_DECIMALS_REGEX = /^\d+(\.\d{1,2})?$/;
export const VALID_DNIS_REGEX = /^[a-zA-Z0-9]{7,12}$/;
export const FLOAT_MAX_TWO_DECIMALS_REGEX = /^\d+(\.\d{1,2})?$/;
export const FLOAT_REGEX = /^\d+(\.\d{1,})?$/;
export const INT_REGEX = /^[0-9]*$/;
export const VALID_CLIENT_ID_REGEX = /^[a-zA-Z0-9]{1,12}$/;
export const VALID_SKU_REGEX = /^[0-9]{14}$/;
export const VALID_REF_INTERNA_REGEX = /^[a-zA-Z0-9]*$/;
export const VALID_NAME_PROMO_REGEX = /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ\-.*’':%+/_ ]*$/;
export const VALID_DESCRIPTION_PROMO_REGEX = /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ\-.*’':%+/_ ]*$/;
export const FILE_NAME_REGX = /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ\.\@\#\!\$\;\&\%\[\]\(\)\{\+\}\- ]+$/;
export const CODE_NAME_REGX = /^[A-Z0-9]+$/;
export const FIRST_CHARACTER_NO_BLANK_NO_NUMBER = /^[A-Za-z]/;
export const REMOVE_SPACES_REGEX = /\s{2}/g;
export const CURRENCY_REGEX = /^\d+(\.\d{2})?$/;
export const EMAIL_REGREX = /[a-zA-Z0-9-]{1,}@([a-zA-Z.])?[a-zA-Z]{1,}\.[a-zA-Z]{1,4}/gi;
export const ALPHANUMERIC_REGX = /^[A-Za-z0-9ÁÉÍÓÚáéíóúÑñ'’ ]*$/g;
export const ALPHANUMERIC_CHARACTER_SINGS_REGX = /^[A-Za-zÁÉÍÓÚáéíóúñÑ0-9\-_\._\*_\’_\'_\{_\%_\+_\}_\ _]*$/g;
export const ALPHANUMERIC_CHARACTER_REGX = /^[A-Za-z0-9\-_\._\*_\{_\}_\ _\u00C0-\u017F]*$/g;
export const ONLY_LETTERS_REGEX = /^[A-Za-zÁÉÍÓÚáéíóúÑñ'’ ]*$/g;
export const NO_SPECIAL_CHARACTER_REGEX = /^[A-Za-z0-9ÁÉÍÓÚáéíóúÑñ'’ ]*$/g;
export const VALID_NAME_SELLER_REGEX = /^[A-Za-zÁÉÍÓÚáéíóúñÑ\' ]*$/;
export const VALID_LASTNAME_SELLER_REGEX = /^[A-Za-zÁÉÍÓÚáéíóúñÑ\' ]*$/;
export const VALID_FFVV_SELLER_REGEX = /^[A-Za-z0-9\ ]*$/;
export const VALID_RULENAME_REGEX = /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ\' ]*$/;
export const VALID_SLUG = /^[A-Za-z0-9\-\ ]*$/g;
