import type { IDistributorsState } from './types';

export const SLICE_NAMESPACE = 'logistics/distributors';

export const initialState: IDistributorsState = {
  isLoading: false,
  distributors: [],
  currentPage: 1,
  totalPages: 0,
  filters: {
    distributorName: '',
    dateUpdate: '1',
  },
};
