export const TEXT_FIELD_REQUIRED_ERROR = 'Este campo es requerido';
export const textFieldRequired = (field?: string) => `El campo ${field || ''} es requerido`;
export const TEXT_NUMBER_TYPO_ERROR = 'El campo debe ser un número';
export const TEXT_NUMBER_GT_0 = 'El número debe ser mayor que 0';
export const TEXT_NUMBER_MIN_1_ERROR = 'El número debe ser mayor o igual que 1';
export const TEXT_NUMBER_INT = 'El número debe ser entero';
export const TEXT_NUMBER_MAX_2_DECIMALS = 'El número debe tener máximo 2 decimales';
export const TEXT_SKU_CHARACTERS_NUMBER_ERROR = 'El sku debe ser válido';
export const TEXT_NAME_CHARACTERS_ERROR = 'El nombre debe ser número y/o letras y caracteres';
export const TEXT_DISPLAY_NAME_CHARACTERS_ERROR = 'El nombre comercial debe ser número y/o letras y caracteres';
export const TEXT_DISPLAY_SUBTITLE_CHARACTERS_ERROR = 'La presentación deber ser número y/o letras y caracteres';
export const EMAIL_FIELD_ERROR = 'El email es inválido';
export const TEXT_ALPHANUMERIC_ERROR = 'El campo solo acepta caracteres alfanuméricos';
export const textFieldMaxError = (max: number, field?: string) =>
  `El campo ${field || ''} debe tener como máximo ${max} caracteres`;
export const textFieldMinError = (max: number, field?: string) =>
  `El campo ${field || ''} debe tener como mínimo ${max} caracter${max !== 1 ? 'es' : ''}`;
export const numberFieldMaxError = (max: number) => `El número debe ser menor o igual que ${max}`;
export const numberFieldMinError = (min: number) => `El número debe ser mayor o igual que ${min}`;
export const numberFieldMaxDecimalsError = (max: number) => `El número debe tener máximo ${max} decimales`;
export const dateFieldMinError = (compare: string) => `La fecha debe ser mayor o igual que ${compare}`;
export const NO_SPECIAL_CHARACTERS_ERROR = 'El campo no debe tener caracteres especiales';
export const TEXT_GROUPINGNAME_ERROR = 'El nombre debe ser válido';
export const NO_EMPTY_PRODUCTS = 'Debes seleccionar por lo menos 1 producto';
export const TEXT_ANY_FIELD_REQUIRED = 'Es requerido alguno de los campos';
export const TEXT_INVALID_DOCUMENTS = 'Los documentos deben ser válidos';
export const AT_LEAST_ONE_EDITING_BANNER = 'Todos los banner no han sido guardados';
export const MAX_SALE_LESS_THAN_MIN_SALE = 'El mínimo de venta debe ser menor al máximo de venta.';
export const TEXT_NUMBER_TYPE_ERROR = 'Debe tener solo números';
export const PRODUCT_AT_LEAST_ONE_TYPE_SELECTED =
  'Al menos una de las opciones debe estar habilitada o la página interna se mostrará vacía.';
export const CHECK_SHOW_IN_MARKETPLACE_ERROR = '* Debe mostrar al menos una unidad (Venta o Base) en el Marketplace.';
