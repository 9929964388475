import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { exportNotification } from 'core/constants/exports.constants';
import { EExportListType } from 'core/model/enums/exports.enum';
import { apiService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'core/store';
import { fetchAllProducts, updateProductFilter } from 'core/store/products';

export const useProductList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { products = [], isLoading, totalPages, currentPage, filter } = useAppSelector(state => state.products);
  const [query, setQuery] = useState<string>(filter);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleQueryClear = () => {
    setQuery('');
    dispatch(updateProductFilter(''));
    dispatch(fetchAllProducts({ page: 1 }));
  };

  const handleSearchEnterEvent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateProductFilter(query));
    dispatch(fetchAllProducts({ page: 1 }));
  };

  const handleChangePage = (page: number) => {
    dispatch(fetchAllProducts({ page }));
  };

  const handleViewClick = (sku: string) => {
    navigate(`/productos/ver/${sku}`);
  };

  const handleExportAgree = async (fileName: string) => {
    try {
      await apiService.exports.createFileExport({
        fileName,
        exportListTypeId: EExportListType.PRODUCT,
      });
      exportNotification(navigate);
    } catch (error) {
      notification.error('Tu archivo no se pudo exportar. Por favor intentalo nuevamente');
    }
  };

  useEffect(() => {
    dispatch(fetchAllProducts({}));
  }, [dispatch]);

  return {
    products,
    handleSearchChange,
    handleQueryClear,
    handleSearchEnterEvent,
    handleChangePage,
    query,
    isLoading,
    totalPages,
    currentPage,
    handleViewClick,
    handleExportAgree,
  };
};
