import { ordersData } from './orders.data';
import type { IGetOrderRequest, IGetOrdersRequest } from './orders.dto';
import { toDisplayOrderDetail, toDisplayOrdersList } from './orders.mappers';

export const ordersService = {
  getOrders: async (filters: IGetOrdersRequest) => toDisplayOrdersList(await ordersData.getOrders(filters)),
  getOderDetail: async (orderId: IGetOrderRequest) => toDisplayOrderDetail(await ordersData.getOderDetail(orderId)),
};

export default ordersService;
