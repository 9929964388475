import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { initialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

const productsSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState,
  reducers,
  extraReducers,
});

export const { setFiltersApplied, updateProductFilter } = productsSlice.actions;

export default productsSlice.reducer;
