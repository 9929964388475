import type { IUploadFilesResponse } from './g2m-functions.dto';

export const mergeFilesWithUrls = (files: Array<File>, urls: Array<string>) =>
  files.map((file, index) => ({ file, url: urls[index] }));

export const mapProcessedFiles = (
  processedFiles: Array<
    PromiseSettledResult<{
      url: string;
      file: File;
    }>
  >,
) =>
  processedFiles.reduce<IUploadFilesResponse>(
    (acc, proccessedFile) => {
      if (proccessedFile.status === 'fulfilled') {
        acc.successfulFiles.push(proccessedFile.value);
      } else if (proccessedFile.status === 'rejected') {
        acc.rejectedFiles.push(proccessedFile.reason);
      }
      return acc;
    },
    { successfulFiles: [], rejectedFiles: [] },
  );
