import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { ICustomizationModule } from 'core/model/interfaces/customization';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useModules = (customizationId: number) => {
  const [moduleList, setModuleList] = useState<Array<ICustomizationModule>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchModules = async () => {
      setLoading(true);

      try {
        const moduleList = await apiService.customization.getModules(customizationId);
        setModuleList(moduleList);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo los módulos'));
      } finally {
        setLoading(false);
      }
    };

    fetchModules();

    return () => {
      setModuleList([]);
    };
  }, [customizationId]);

  return { moduleList, loading, error };
};
