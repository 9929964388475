import { EDeliveryTimeOption } from 'core/model/enums/grouping-rules';
import type { IGroupingRulesFormData } from 'core/model/interfaces/grouping-rules';
import { parseDistributorId, unParseDistributorId } from 'shared/utils/distributors.utils';
import type { IGroupingRuleParams, TGroupingRuleDetailDTO } from './grouping-rules.dto';

export const toFormGroupingRule = (response: TGroupingRuleDetailDTO): IGroupingRulesFormData => {
  const deliveryTimeOption = response.useCustomerDeliveryDates
    ? EDeliveryTimeOption.SPECIFIC_DAYS
    : EDeliveryTimeOption.ASSOCIATED_DATES;

  const externalDistributors = response.conditions.externalDistributors.map(dis =>
    parseDistributorId(dis.id, dis.externalBranchId),
  );

  const groupingRuleFormData: IGroupingRulesFormData = {
    deliveryGroupingRuleName: response.deliveryGroupingRuleName,
    conditions: { ...response.conditions, externalDistributors },
    useCustomerDeliveryDates: deliveryTimeOption,
    deliveryConfiguration: response.deliveryConfiguration || null,
  };

  return groupingRuleFormData;
};

export const toParamsService = (formData: IGroupingRulesFormData): IGroupingRuleParams => {
  const externalDistributors = formData.conditions.externalDistributors.map(unParseDistributorId);

  const requestData: IGroupingRuleParams = {
    deliveryGroupingRuleName: formData.deliveryGroupingRuleName,
    conditions: {
      externalPriceGroupIds: formData.conditions.externalPriceGroupIds,
      externalPriceListIds: formData.conditions.externalPriceListIds,
      externalProductCategoryIds: formData.conditions.externalProductCategoryIds,
      externalDistributors,
    },
    useCustomerDeliveryDates: formData.useCustomerDeliveryDates === EDeliveryTimeOption.SPECIFIC_DAYS,
    deliveryConfigurationId: formData.deliveryConfiguration ? formData.deliveryConfiguration.id : null,
  };

  return requestData;
};
