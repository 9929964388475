import type { Action, AnyAction, PreloadedState, Reducer, StoreEnhancerStoreCreator } from 'redux';
import { IMonitoringProvider } from 'core/model/interfaces/monitoring.interface';

export class NewRelicProvider implements IMonitoringProvider {
  private isNewrelicEnabled = false;

  constructor() {
    this.isNewrelicEnabled = this.checkIsMonitoringEnabled();
  }

  setApplicationVersion(appVersion: string | null): void {
    if (this.isNewrelicEnabled) {
      window.newrelic.setApplicationVersion(appVersion);
    }
  }

  setUserId(userId: string | null): void {
    if (this.isNewrelicEnabled) {
      window.newrelic.setUserId(userId);
    }
  }

  checkIsMonitoringEnabled(): boolean {
    this.isNewrelicEnabled = typeof window.newrelic !== 'undefined';
    return this.isNewrelicEnabled;
  }

  createReduxEnhancer() {
    return (next: StoreEnhancerStoreCreator): StoreEnhancerStoreCreator =>
      <S = any, A extends Action = AnyAction>(reducer: Reducer<S, A>, initialState?: PreloadedState<S>) => {
        const newrelicReducer: Reducer<S, A> = (state: S | undefined, action: A): S => {
          const newState = reducer(state, action);
          if (this.isNewrelicEnabled) {
            window.newrelic.addPageAction(action.type, action);
          }
          return newState;
        };

        return next(newrelicReducer, initialState);
      };
  }
}
