import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'core/store';
import { fetchAllCustomers, updateCustomerFilter } from 'core/store/customers';

export const useCustomersList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { customers, isLoading, totalPages, currentPage, filters } = useAppSelector(state => state.customers);
  const [query, setQuery] = useState<string>(filters.query ?? '');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleQueryClear = () => {
    setQuery('');
    dispatch(updateCustomerFilter({ query: '' }));
    dispatch(fetchAllCustomers({ page: 1 }));
  };

  const handleSearchEnterEvent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateCustomerFilter({ query }));
    dispatch(fetchAllCustomers({ page: 1 }));
  };

  const handleChangePage = (page: number) => {
    dispatch(fetchAllCustomers({ page }));
  };

  const handleClick = (id: number) => navigate(`/clientes/detalle/${id}`);
  useEffect(() => {
    dispatch(fetchAllCustomers({}));
  }, [dispatch]);

  return {
    customers,
    handleSearchChange,
    handleQueryClear,
    handleSearchEnterEvent,
    handleChangePage,
    query,
    isLoading,
    totalPages,
    currentPage,
    handleClick,
  };
};
