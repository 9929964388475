import axios from 'axios';
import { UNEXPECTED_ERROR } from 'core/constants/error-services.constants';

interface IServiceError {
  data: unknown;
  error: {
    code: number;
    message: string;
    details: Array<unknown>;
  };
  success: boolean;
}

const isGeneralError = (error: unknown): error is Error =>
  typeof error === 'object' && error !== null && 'message' in error;

export const handleErrorMessage = (error: unknown, messageError?: string): string => {
  // eslint-disable-next-line no-console
  console.error(error);
  if (axios.isAxiosError<IServiceError>(error) && error?.response) return error.response.data.error.message;
  if (isGeneralError(error) && error.message) return error.message;
  if (messageError) return messageError;
  return UNEXPECTED_ERROR;
};
