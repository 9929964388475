import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, FormGroup } from '@alicorpdigital/dali-components-react';
import { ESuggestedProductStateType } from 'core/model/enums/suggested-product.enum';
import { SuggestionError } from '../suggestion-error/suggestion-error';
import { SuggestionTypes } from '../suggestion-types/suggestion-types';

interface ISuggestionGeneralDetailsProps {
  name: string;
  sublevelName?: string;
  watchedValue: boolean;
  titleLabel: string;
  titlePlaceholder?: string;
  typesSelection?: boolean;
  limitSuggestions?: boolean;
  stateTitle?: string;
}
export const SuggestionDetails = ({
  name,
  sublevelName,
  watchedValue,
  titleLabel,
  titlePlaceholder,
  typesSelection = false,
  limitSuggestions = true,
  stateTitle,
}: ISuggestionGeneralDetailsProps) => {
  const {
    control,
    register,
    trigger,
    formState: { errors },
  } = useFormContext();
  const fieldName = sublevelName ? `${name}.${sublevelName}` : name;

  useEffect(() => {
    trigger(name);
  }, [name, trigger, watchedValue]);
  return (
    <>
      <div className="suggested-product-form__section">
        <FormGroup>
          <Form.Label>{titleLabel}</Form.Label>
          <Form.Input placeholder={titlePlaceholder} {...register(`${fieldName}.title`)} />
          <SuggestionError errors={errors} name={name} sublevelName={sublevelName} field="title" />
        </FormGroup>
        {limitSuggestions && (
          <FormGroup>
            <Form.Label>N° máximo de sugerencias</Form.Label>
            <Form.Input {...register(`${fieldName}.maxSuggestions`)} />
            <SuggestionError errors={errors} name={name} sublevelName={sublevelName} field="maxSuggestions" />
          </FormGroup>
        )}
      </div>
      {typesSelection ? (
        <div>
          <h4 className="suggested-product-form__subtitle">2. Elegir opción a mostrar:</h4>
          <div className="suggested-product-form__section">
            <SuggestionTypes name={fieldName} />
            <SuggestionError errors={errors} name={name} field="option" />
          </div>
        </div>
      ) : (
        <Form.Input {...register(`${fieldName}.type`)} hidden />
      )}

      {stateTitle && <h4 className="suggested-product-form__subtitle">{stateTitle}</h4>}
      <Form.Group className="suggested-product-form__state">
        <Controller
          name={`${fieldName}.state`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <Form.Check
              size="sm"
              type="switch"
              label={watchedValue ? ESuggestedProductStateType.ENABLED : ESuggestedProductStateType.DISABLED}
              checked={value}
              onChange={onChange}
              id={`${fieldName}State`}
            />
          )}
        />
      </Form.Group>
    </>
  );
};
