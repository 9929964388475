import { useCSS } from '@insuma/mpp-ui/hooks';

import './version-badge.scss';

export const VersionBadge = () => {
  const css = useCSS('version-badge');
  return (
    <p className={css()} aria-label="Versión de la aplicación">
      V. {__APP_VERSION__ || 'Sin versión'}
    </p>
  );
};
