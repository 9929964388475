import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import {
  IGetDistributorRequestDTO,
  IGetDistributorResponseDTO,
  IGetDistributorsDataResponse,
  IGetDistributorsParams,
  IGetSalesForceResponseDTO,
  IRequestDistributorUpdateDTO,
  IUpdateDistributorParams,
} from './distributors.dto';

const distributorsBaseURL = '/gtm-distributor-management/v1/distributors';

export const distributorsData = {
  getDistributors: async (options: Partial<IGetDistributorsParams>): Promise<IGetDistributorsDataResponse> => {
    const { data } = await https.get<IServiceSuccess<IGetDistributorsDataResponse>>(distributorsBaseURL, {
      params: options,
    });
    return data.data;
  },
  getDistributorDetail: async ({
    branchCode,
    distributorCode,
  }: IGetDistributorRequestDTO): Promise<IGetDistributorResponseDTO> => {
    const { data } = await https.get<IServiceSuccess<IGetDistributorResponseDTO>>(
      `${distributorsBaseURL}/${distributorCode}/branches/${branchCode}`,
    );
    return data.data;
  },
  getExternalSalesForces: async ({
    branchCode,
    distributorCode,
  }: IGetDistributorRequestDTO): Promise<IGetSalesForceResponseDTO> => {
    const { data } = await https.get<IServiceSuccess<IGetSalesForceResponseDTO>>(
      `${distributorsBaseURL}/${distributorCode}/branches/${branchCode}/sales-forces`,
    );
    return data.data;
  },
  updateDistributor: async ({ branchCode, distributorCode, body }: IUpdateDistributorParams): Promise<void> => {
    await https.post<IServiceSuccess<IRequestDistributorUpdateDTO>>(
      `${distributorsBaseURL}/${distributorCode}/branches/${branchCode}`,
      body,
    );
  },
};
