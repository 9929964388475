import { Button, Form, SearchBox } from '@alicorpdigital/dali-components-react';

import './search.scss';

interface ISearchProps {
  query: string;
  placeholder?: string;
  size?: 'sm' | 'lg' | 'md';
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleQueryClear: () => void;
  handleSearchEnterEvent: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const Search = ({
  query,
  placeholder = 'Buscar',
  size = 'sm',
  handleSearchChange,
  handleQueryClear,
  handleSearchEnterEvent,
}: ISearchProps) => (
  <div className="search">
    <Form onSubmit={handleSearchEnterEvent} className="search__form">
      <SearchBox
        className="search__searchbox"
        size={size}
        placeholder={placeholder}
        value={query}
        onChange={handleSearchChange}
        onDeleteContent={handleQueryClear}
      />
      <Button isIconOnly iconWeight="bold" iconName="magnifying-glass" size={size} type="submit" />
    </Form>
  </div>
);
