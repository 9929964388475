import { stringToBase64 } from 'shared/utils/encode.utils';
import { deliveryConditionData } from './delivery-condition.data';
import {
  IGetDeliveryConditionsParams,
  TCreateDeliveryCondition,
  TEditDeliveryCondition,
} from './delivery-condition.dto';
import { deliveryConditionToFormData } from './delivery-condition.mappers';

export const deliveryConditionService = {
  getDeliveryConditions: (options?: IGetDeliveryConditionsParams) =>
    deliveryConditionData.getDeliveryConditions(options),
  removeDeliveryCondition: (id: number) => deliveryConditionData.removeDeliveryCondition(id),
  getDeliveryConditionById: async (id: string) => {
    const response = await deliveryConditionData.getDeliveryConditionById(id);
    return deliveryConditionToFormData(response);
  },
  createDeliveryCondition: (body: TCreateDeliveryCondition) =>
    deliveryConditionData.createDeliveryCondition({ ...body, description: stringToBase64(body.description) }),
  updateDeliveryCondition: (body: TEditDeliveryCondition) =>
    deliveryConditionData.updateDeliveryCondition({ ...body, description: stringToBase64(body.description) }),
};
