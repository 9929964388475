import { useFormContext } from 'react-hook-form';
import { Form } from '@alicorpdigital/dali-components-react';
import { HIERARCHY_ASSOCIATION_TYPES } from 'core/constants/hierarchy.constants';
import { EHierarchyAssociation } from 'core/model/enums/hierarchy.enum';
import type {
  IHierarchyTreeCategory,
  IHierarchyTreeCategoryCreateForm,
  IHierarchyTreeFamily,
} from 'core/model/interfaces/navigation/hierarchy.interface';
import { useHierarchyTreeFamilies } from 'screens/products/navigation-tree/hierarchy-tree/hooks';
import { AutocompleteForm, InputValueForm } from 'shared/components/forms';

import './category-form-subcategory-fields.scss';

interface IHierarchyCategorySubcategoryFieldsProps {
  selectedCategory?: IHierarchyTreeCategory;
  treeId: string;
}

export const HierarchyCategorySubcategoryFields = ({
  selectedCategory,
  treeId,
}: IHierarchyCategorySubcategoryFieldsProps) => {
  const {
    formState: { errors },
    register,
    trigger,
    watch,
  } = useFormContext<IHierarchyTreeCategoryCreateForm>();
  const associationType = watch('associationType');
  const { familiesList, loading: loadingFamilies } = useHierarchyTreeFamilies({
    treeId,
    enabled: !selectedCategory,
  });

  const isOptionDisabled = (family: IHierarchyTreeFamily) => !family.available;

  return (
    <>
      <InputValueForm
        label="Nombre de la subcategoría"
        name="subcategoryName"
        onChange={() => trigger('subcategoryName')}
      />
      <Form.Group>
        <Form.Label>Tipo de Asociación</Form.Label>
        <AutocompleteForm
          source={HIERARCHY_ASSOCIATION_TYPES}
          name="associationType"
          getOptionValue={option => option.id}
          getOptionLabel={option => option.content}
          isInvalid={!!errors.associationType}
          isSearchable={false}
          isClearable={false}
          aria-label="Tipo de Asociación"
        />
        {errors.associationType && <Form.HelperText isInvalid>{errors.associationType.message}</Form.HelperText>}
      </Form.Group>
      {associationType === EHierarchyAssociation.BY_FAMILIES && (
        <Form.Group className="autocomplete-families">
          <Form.Label>Familias asociadas a la subcategoría</Form.Label>
          <AutocompleteForm
            source={familiesList}
            name="families"
            isLoading={loadingFamilies}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.description}
            isMulti
            isOptionDisabled={isOptionDisabled}
            isInvalid={!!errors.families}
            placeholder="Selecciona las familias"
            aria-label="Familias asociadas a la subcategoría"
          />
          {errors.families && (
            <Form.HelperText className="hierarchy-category-form-subcategory-fields__families-error" isInvalid>
              {errors.families.message}
            </Form.HelperText>
          )}
        </Form.Group>
      )}
      {associationType === EHierarchyAssociation.BY_SKUS && (
        <Form.Group>
          <Form.Label>SKUs asociados a la subcategoría</Form.Label>
          <Form.Textarea
            {...register('skus')}
            isInvalid={!!errors.skus}
            placeholder="Escribe los SKU"
            aria-label="SKUs asociados a la subcategoría"
          />
          {errors.skus && <Form.HelperText isInvalid>{errors.skus.message}</Form.HelperText>}
        </Form.Group>
      )}
    </>
  );
};
