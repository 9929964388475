import * as yup from 'yup';
import {
  CHECK_SHOW_IN_MARKETPLACE_ERROR,
  MAX_SALE_LESS_THAN_MIN_SALE,
  numberFieldMaxError,
  TEXT_FIELD_REQUIRED_ERROR,
  TEXT_NUMBER_INT,
  TEXT_NUMBER_MIN_1_ERROR,
  TEXT_NUMBER_TYPE_ERROR,
} from 'core/constants/error-messages.constants';

export interface IProductFormData {
  minimumSaleQuantity: number;
  maximumSaleQuantity: number;
  showSaleUnit: boolean;
  showBaseUnit: boolean;
}

export const schema = yup.object().shape(
  {
    minimumSaleQuantity: yup
      .number()
      .emptyStringToNull()
      .required(TEXT_FIELD_REQUIRED_ERROR)
      .typeError(TEXT_NUMBER_TYPE_ERROR)
      .min(1, TEXT_NUMBER_MIN_1_ERROR)
      .max(999999, numberFieldMaxError(999999))
      .test('integer', TEXT_NUMBER_INT, str => Number(str) % 1 === 0)
      .when('maximumSaleQuantity', ([maximumSaleQuantity], schema) =>
        schema.max(maximumSaleQuantity, MAX_SALE_LESS_THAN_MIN_SALE),
      ),

    maximumSaleQuantity: yup
      .number()
      .emptyStringToNull()
      .required(TEXT_FIELD_REQUIRED_ERROR)
      .typeError(TEXT_NUMBER_TYPE_ERROR)
      .min(1, TEXT_NUMBER_MIN_1_ERROR)
      .max(999999, numberFieldMaxError(999999))
      .test('integer', TEXT_NUMBER_INT, str => Number(str) % 1 === 0)
      .when('minimumSaleQuantity', ([minimumSaleQuantity], schema) =>
        schema.min(minimumSaleQuantity, MAX_SALE_LESS_THAN_MIN_SALE),
      ),

    showSaleUnit: yup.boolean().when('showBaseUnit', {
      is: false,
      then: schema => schema.oneOf([true], CHECK_SHOW_IN_MARKETPLACE_ERROR),
    }),

    showBaseUnit: yup.boolean(),
  },
  [['minimumSaleQuantity', 'maximumSaleQuantity', 'showSaleUnit', 'showBaseUnit']],
);
