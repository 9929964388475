import * as yup from 'yup';

yup.addMethod(yup.object, 'atLeastOneOf', function atLeastOneOf(list: Array<string>, errorMessage: string) {
  return this.test({
    name: 'atLeastOneOf',
    message: errorMessage,
    exclusive: false,
    params: { keys: list.join(', ') },
    test: (value: Record<string, any | Array<any>>) =>
      value == null ||
      list.some(key => {
        if (Array.isArray(value[key])) return value[key].length > 0;
        return value[key] != null && value[key] !== '';
      }),
  });
});

yup.addMethod(yup.number, 'emptyStringToNull', function emptyStringToNull() {
  return this.transform((value: number, originalValue: number | string) => (originalValue === '' ? null : value));
});
