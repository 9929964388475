import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, SearchBox } from '@alicorpdigital/dali-components-react';
import { Table } from '@insuma/mpp-ui/components/table';
import { notification } from '@insuma/mpp-ui/notifications';
import axios from 'axios';
import { itemsPerPage } from 'core/constants/pagination.constants';
import { deliveryConditionRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import type { IDisplayDeliveryCondition } from 'core/model/interfaces/delivery-condition';
import { deliveryConditionService } from 'core/services/delivery-condition/delivery-condition.service';
import { useAppDispatch, useAppSelector } from 'core/store';
import { fetchDeliveryConditions, updateFilters } from 'core/store/logistics/delivery-condition';
import { ConfirmationModal } from 'shared/components/confirmation-modal';
import { DeleteConfirmationModal } from '../components/delete-confirmation-modal';
import { getDeliveryConditionColumns } from '../delivery-condition.utils';

import './delivery-conditions-list.scss';

export const DeliveryConditionsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { deliveryConditions, isLoading, totalPages, currentPage, filters } = useAppSelector(
    state => state.deliveryConditions,
  );
  const [query, setQuery] = useState<string>(filters.q);
  const [errorRestrictionModal, setErrorRestrictionModal] = useState<{ view: boolean; message: string }>({
    view: false,
    message: '',
  });
  const [itemToDelete, setItemToDelete] = useState<IDisplayDeliveryCondition | null>(null);
  const route = `${logisticsRoutePath}${deliveryConditionRoutePath}/`;
  const onNewDeliveryConditionClick = () => navigate(`${route}crear`);
  const onEditDeliveryConditionClick = (id: number) => navigate(`${route}editar/${id}`);

  const tableColumns = getDeliveryConditionColumns(onEditDeliveryConditionClick, row => {
    setItemToDelete(row);
  });

  const handleSearchEnterEvent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateFilters({ q: query }));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleQueryClear = () => {
    setQuery('');
    dispatch(updateFilters({ q: '' }));
  };

  const onDeleteConfirmation = async (id: number) => {
    try {
      await deliveryConditionService.removeDeliveryCondition(id);
      dispatch(fetchDeliveryConditions());
      notification.success('Fecha de entrega eliminada exitosamente');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data?.error?.code === 'SFE0905')
          setErrorRestrictionModal({ view: true, message: error.response?.data?.error?.message });
        else notification.error(error.response?.data?.error?.message ?? 'Error eliminando condición de entrega');
      }
    }
  };

  useEffect(() => {
    dispatch(fetchDeliveryConditions());
  }, [filters, dispatch]);

  return (
    <>
      {errorRestrictionModal.view && (
        <ConfirmationModal
          head="Eliminar condición de entrega"
          message={errorRestrictionModal.message}
          onCancel={() => setErrorRestrictionModal({ ...errorRestrictionModal, view: false })}
        />
      )}

      <div className="delivery-conditions-list">
        <h2 className="delivery-conditions-list__title">Condición de fecha de entrega</h2>
        <div className="delivery-conditions-list__actions">
          <Form onSubmit={handleSearchEnterEvent} className="delivery-conditions-list__search">
            <SearchBox
              className="delivery-conditions-list__searchbox"
              size="sm"
              placeholder="Buscar por nombre"
              value={query}
              onChange={handleSearchChange}
              onDeleteContent={handleQueryClear}
            />
            <Button isIconOnly iconWeight="bold" iconName="magnifying-glass" size="sm" type="submit" />
          </Form>
          <Button iconWeight="bold" size="sm" onClick={onNewDeliveryConditionClick} hasIcon="left" iconName="plus">
            Nueva Condición
          </Button>
        </div>
        <div>
          <DeleteConfirmationModal
            selectedItem={itemToDelete}
            setSelectedItem={setItemToDelete}
            onDeleteConfirmation={onDeleteConfirmation}
          />
          <Table
            isLoading={isLoading}
            columns={tableColumns}
            rows={deliveryConditions}
            paginationModel={{
              totalPages,
              currentPage: currentPage - 1,
              onChangePage: page => {
                dispatch(fetchDeliveryConditions({ page }));
              },
              pageSize: itemsPerPage,
            }}
            noDataMessage="No se encontraron resultados de condiciones de fecha entrega."
          />
        </div>
      </div>
    </>
  );
};
