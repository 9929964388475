import { defaultSuggestedProductConfiguration } from 'core/constants/suggested-product.constants';
import { ESuggestedProductViewName, ESuggestion } from 'core/model/enums/suggested-product.enum';
import type { ISuggestion, ISuggestionLocation } from 'core/model/interfaces/suggested-product';
import type {
  IGetSuggestedProductConfigDTO,
  ISuggestedProductConfigDTO,
  ISuggestedProductViewConfigDTO,
  IUpdateSuggestedProductConfigDTO,
} from './suggested-product.dto';

const suggestedProductViewConfigDTOToSuggestionLocation = ({
  active,
  maxSuggestions,
  ...rest
}: ISuggestedProductViewConfigDTO): ISuggestionLocation => {
  const maxSuggestionsValue = maxSuggestions === 0 ? undefined : maxSuggestions;
  return {
    ...rest,
    maxSuggestions: active ? maxSuggestions : maxSuggestionsValue,
    state: active,
  };
};

const getSuggestionLocation = <T>({
  config,
  fallbackLocation,
}: {
  config?: ISuggestedProductViewConfigDTO;
  fallbackLocation: T;
}) => {
  if (config) return suggestedProductViewConfigDTOToSuggestionLocation(config);
  return fallbackLocation;
};

const suggestionLocationToSuggestedProductViewConfigDTO = ({
  state,
  ...rest
}: ISuggestionLocation): ISuggestedProductViewConfigDTO => ({
  ...rest,
  active: state,
});

export const suggestedProductConfigDTOToSuggestion = (config: IGetSuggestedProductConfigDTO): ISuggestion => {
  const { results, files } = config;
  let homepageView: ISuggestedProductConfigDTO | undefined;
  let fixedBannerView: ISuggestedProductConfigDTO | undefined;
  let shoppingCartView: ISuggestedProductConfigDTO | undefined;
  let internalPageView: ISuggestedProductConfigDTO | undefined;
  let enhancersConfig: ISuggestedProductViewConfigDTO | undefined;
  let idealSelectionConfig: ISuggestedProductViewConfigDTO | undefined;
  let unforgivablesConfig: ISuggestedProductViewConfigDTO | undefined;

  results.forEach(view => {
    if (view.view === ESuggestedProductViewName.HOMEPAGE) homepageView = view;
    else if (view.view === ESuggestedProductViewName.FIXED_BANNER) fixedBannerView = view;
    else if (view.view === ESuggestedProductViewName.SHOPPING_CART) shoppingCartView = view;
    else if (view.view === ESuggestedProductViewName.INTERNAL_PAGE) {
      internalPageView = view;
      view.config.forEach(config => {
        if (config.type === ESuggestion.ENHANCERS) enhancersConfig = config;
        else if (config.type === ESuggestion.IDEAL_SELECTION) idealSelectionConfig = config;
        else if (config.type === ESuggestion.UNFORGIVABLES) unforgivablesConfig = config;
      });
    }
  });

  const homePageConfig = homepageView?.config[0];
  const fixedBannerConfig = fixedBannerView?.config[0];
  const shoppingCartConfig = shoppingCartView?.config[0];

  if (!homepageView?.id && !fixedBannerView?.id && !shoppingCartView?.id && !internalPageView?.id) {
    throw new Error(
      'homepageView, fixedBannerView, shoppingCartView, internalPageView should not be undefined or null',
    );
  }

  const homePageLocation = getSuggestionLocation({
    config: homePageConfig,
    fallbackLocation: defaultSuggestedProductConfiguration.homepage,
  });
  const fixedBannerLocation = getSuggestionLocation({
    config: fixedBannerConfig,
    fallbackLocation: defaultSuggestedProductConfiguration.fixedBanner,
  });
  const shoppingCartLocation = getSuggestionLocation({
    config: shoppingCartConfig,
    fallbackLocation: defaultSuggestedProductConfiguration.shoppingCart,
  });

  const enhacersLocation = getSuggestionLocation({
    config: enhancersConfig,
    fallbackLocation: defaultSuggestedProductConfiguration.internalPage.enhancers,
  });
  const idealSelectionLocation = getSuggestionLocation({
    config: idealSelectionConfig,
    fallbackLocation: defaultSuggestedProductConfiguration.internalPage.idealSelection,
  });
  const unforgivablesLocation = getSuggestionLocation({
    config: unforgivablesConfig,
    fallbackLocation: defaultSuggestedProductConfiguration.internalPage.unforgivables,
  });

  return {
    homepage: homePageLocation,
    fixedBanner: fixedBannerLocation,
    shoppingCart: shoppingCartLocation,
    internalPage: {
      enhancers: enhacersLocation,
      idealSelection: idealSelectionLocation,
      unforgivables: unforgivablesLocation,
    },
    homepageId: homepageView?.id ?? 3,
    fixedBannerId: fixedBannerView?.id ?? 4,
    shoppingCartId: shoppingCartView?.id ?? 2,
    internalPageId: internalPageView?.id ?? 1,
    templateUrl: files?.templateUrl,
    exportUrl: files?.lastUploadUrl,
  };
};

export const suggestionToUpdateSuggestedProductConfigDTO = (
  suggestion: ISuggestion,
): IUpdateSuggestedProductConfigDTO => {
  if (suggestion.homepageId && suggestion.fixedBannerId && suggestion.shoppingCartId && suggestion.internalPageId) {
    return {
      views: [
        {
          id: suggestion.fixedBannerId,
          config: [
            suggestionLocationToSuggestedProductViewConfigDTO({
              ...suggestion.fixedBanner,
              maxSuggestions: undefined,
              type: undefined,
            }),
          ],
        },
        {
          id: suggestion.homepageId,
          config: [suggestionLocationToSuggestedProductViewConfigDTO(suggestion.homepage)],
        },
        {
          id: suggestion.shoppingCartId,
          config: [suggestionLocationToSuggestedProductViewConfigDTO(suggestion.shoppingCart)],
        },
        {
          id: suggestion.internalPageId,
          config: [
            suggestionLocationToSuggestedProductViewConfigDTO(suggestion.internalPage.enhancers),
            suggestionLocationToSuggestedProductViewConfigDTO(suggestion.internalPage.idealSelection),
            suggestionLocationToSuggestedProductViewConfigDTO(suggestion.internalPage.unforgivables),
          ],
        },
      ],
    };
  }
  throw new Error('homepageId, fixedBannerId, shoppingCartId, internalPageId should not be undefined or null');
};
