import { itemsPerPage } from 'core/constants/pagination.constants';
import type { IDisplayOrdersList } from 'core/model/interfaces/orders';
import { apiService } from 'core/services';
import type { IGetOrdersRequest } from 'core/services/orders/orders.dto';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.helpers';
import { SLICE_NAMESPACE } from '../constants';
import type { IOrdersState } from '../types';

export const fetchOrders = createAppAsyncThunk<IDisplayOrdersList, { page?: number }>(
  `${SLICE_NAMESPACE}/fetchOrders`,
  async ({ page }, { getState }) => {
    const { orders } = getState();
    const { query, currentPage } = orders;

    const params: IGetOrdersRequest = {
      perPage: itemsPerPage,
      page: page ?? currentPage,
      query,
    };

    return apiService.orders.getOrders(params);
  },
);

export const fetchOrdersReducer: TSliceExtraReducer<IOrdersState> = builder =>
  builder
    .addCase(fetchOrders.pending, state => {
      state.isLoading = true;
    })
    .addCase(fetchOrders.fulfilled, (state, action) => {
      state.orders = action.payload.orders;
      state.totalPages = action.payload.pagination.totalPages;
      state.currentPage = action.payload.pagination.currentPage;
      state.isLoading = false;
      state.error = '';
    })
    .addCase(fetchOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.orders = [];
      state.error = action.error.message ?? '';
    });
