import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { IAutocompleteDistributor, IDistributor } from 'core/model/interfaces/distributors';
import { apiService } from 'core/services';
import { parseDistributorId } from 'shared/utils/distributors.utils';
import { getErrorFromService } from 'shared/utils/https';

export const useDistributorsOptions = () => {
  const [distributorsList, setDistributorsList] = useState<Array<IAutocompleteDistributor>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchDistributors = async () => {
      setLoading(true);

      try {
        const { distributors } = await apiService.distributors.getDistributors({});
        setDistributorsList(
          distributors.map((d: IDistributor) => ({
            id: parseDistributorId(d.distributorCode, d.branchCode),
            name: d.branchName,
          })),
        );
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo las opciones de operadores'));
      } finally {
        setLoading(false);
      }
    };

    fetchDistributors();

    return () => {
      setDistributorsList([]);
    };
  }, []);
  return { distributorsList, loading, error };
};
