import type { IDeliveryCondition, TDeliveryConditionFormData } from 'core/model/interfaces/delivery-condition';

export const deliveryConditionToFormData = (deliveryCondition: IDeliveryCondition): TDeliveryConditionFormData => {
  const {
    additionalDaysForRestrictedDates,
    availableDeliveryDays,
    dateOptionsCount,
    deliveryConfigurationName,
    description,
    purchaseDayDeliveryConfigurations,
    restrictedDeliveryDates,
  } = deliveryCondition;

  return {
    additionalDaysForRestrictedDates,
    availableDeliveryDays,
    dateOptionsCount,
    deliveryConfigurationName,
    description,
    purchaseDayDeliveryConfigurations,
    restrictedDeliveryDates,
  };
};
