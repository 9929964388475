import { useFormContext } from 'react-hook-form';
import { Check } from '@alicorpdigital/dali-components-react';
import type { IDistributorForm } from 'core/model/interfaces/distributors';
import { DistributorSection } from 'screens/logistics/distributors/components/distributor-section';

export const DistributorWspPurchaseBanner = () => {
  const { register, watch } = useFormContext<IDistributorForm>();
  const isWhatsappButtonEnabled = watch('isWhatsappButtonEnabled');

  return (
    <DistributorSection subtitle="4. Envío de pedido por WhatsApp">
      <Check
        type="switch"
        {...register('isWhatsappButtonEnabled')}
        id="isWhatsappButtonEnabled"
        label={
          isWhatsappButtonEnabled
            ? 'Apagar botón de: envío de pedido por WhatsApp'
            : 'Encender botón de: envío de pedido por WhatsApp'
        }
      />
    </DistributorSection>
  );
};
