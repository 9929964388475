import { Form, Text } from '@alicorpdigital/dali-components-react';
import type { ICustomerBasicInfo } from 'core/model/interfaces/customers';
import {
  CustomerFormContainer,
  CustomerFormLayout,
  CustomerFormSubtitle,
  CustomerFormSwitchContainer,
} from 'screens/customers/customer-form/components';

interface ICustomerBasicDataProps {
  title: string;
  basicInfo: ICustomerBasicInfo;
}
export const CustomerBasicData = ({ title, basicInfo }: ICustomerBasicDataProps) => (
  <CustomerFormContainer type="outlined">
    <CustomerFormLayout smallGaps>
      <CustomerFormSwitchContainer>
        <CustomerFormSubtitle>{title}</CustomerFormSubtitle>
        <Form.Check type="switch" checked={basicInfo.status} disabled>
          <Text as="span">{basicInfo.status ? 'Activo' : 'Inactivo'}</Text>
        </Form.Check>
      </CustomerFormSwitchContainer>
      <CustomerFormLayout>
        <Form.Group>
          <Form.Label>Código del cliente</Form.Label>
          <Form.Input value={basicInfo.customerCode} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Fecha de creación clientes</Form.Label>
          <Form.Input value={basicInfo.creationDate} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Fecha de modificación</Form.Label>
          <Form.Input value={basicInfo.updateDate} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Nombre del operador</Form.Label>
          <Form.Input value={basicInfo.operatorName} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Código de la compañía</Form.Label>
          <Form.Input value={basicInfo.companyCode} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Código de sucursal</Form.Label>
          <Form.Input value={basicInfo.branchCode} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Subsegmento</Form.Label>
          <Form.Input value={basicInfo.subsegment} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Segmento B2B</Form.Label>
          <Form.Input value={basicInfo.b2bSegment} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Segmento potencial</Form.Label>
          <Form.Input value={basicInfo.potencialSegment} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Grupo de precios-pricing</Form.Label>
          <Form.Input value={basicInfo.pricesGroup} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Grupo de precios promocional</Form.Label>
          <Form.Input value={basicInfo.promotionalPricesGroup} disabled />
        </Form.Group>
      </CustomerFormLayout>
    </CustomerFormLayout>
  </CustomerFormContainer>
);
