import { createPortal } from 'react-dom';
import { accessibleOnClick } from 'shared/utils/a11y.utils';

import './modal.scss';

const modalContainer = document.querySelector('#modal-container') as Element;

interface IModalProps {
  children: React.ReactNode;
  onOverlayClick?(): void;
  position?: 'center' | 'top';
}

export const Modal = ({ children, onOverlayClick, position = 'center' }: IModalProps) =>
  modalContainer
    ? createPortal(
        <div className="mpp-modal">
          <div className="mpp-modal__overlay" {...accessibleOnClick(() => onOverlayClick && onOverlayClick())} />
          <div className={`mpp-modal__content mpp-modal__content--${position}`}>{children}</div>
        </div>,
        modalContainer,
      )
    : null;
