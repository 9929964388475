export enum EExportsStates {
  PROCESSING = 'Procesando',
  GENERATED = 'Generado',
  PENDING = 'Pendiente',
  ERROR = 'Error',
}

export enum EExportListType {
  SUGGESTED_PRODUCT = 1,
  PRODUCT = 2,
  ORDER = 3,
}
