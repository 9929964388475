import msalPublicClientApplication from 'core/constants/msal.constants';
import {
  customersRoutePath,
  customizationRoutePath,
  deliveryConditionRoutePath,
  distributorsRoutePath,
  groupingRulesRoutePath,
  logisticsRoutePath,
  masiveLoadsRoutePath,
  navigationTreeRoutePath,
  ordersRoutePath,
  productsRoutePath,
  staticPagesRoutePath,
  suggestedProductRoutePath,
} from 'core/constants/routes.constants';
import { ENavItemType } from 'core/model/enums/nav-item.enum';
import type { INavItem } from 'shared/components/side-nav/components';
import monitoringInstance from 'shared/utils/monitoring-provider.utils';

export const menuLinks: Array<INavItem> = [
  {
    type: ENavItemType.LINK,
    link: {
      label: 'Clientes',
      icon: 'globe',
      to: customersRoutePath,
    },
  },
  {
    type: ENavItemType.DROPDOWN,
    link: {
      label: 'Productos',
      icon: 'handbag',
      to: '/productos',
      sublinks: [
        {
          label: 'Gestión de productos',
          to: productsRoutePath,
        },
        {
          label: 'Árbol de navegación',
          to: `${productsRoutePath}${navigationTreeRoutePath}`,
        },
      ],
    },
  },
  {
    type: ENavItemType.LINK,
    link: {
      label: 'Pedidos',
      icon: 'shopping-cart',
      to: ordersRoutePath,
    },
  },
  {
    type: ENavItemType.DROPDOWN,
    link: {
      label: 'Logística',
      icon: 'truck',
      to: '/logistica',
      sublinks: [
        {
          label: 'Operadores',
          to: `${logisticsRoutePath}${distributorsRoutePath}`,
        },
        {
          label: 'Fecha de Entrega',
          to: `${logisticsRoutePath}${deliveryConditionRoutePath}`,
        },
        {
          label: 'Reglas de Agrupación',
          to: `${logisticsRoutePath}${groupingRulesRoutePath}`,
        },
      ],
    },
  },
  {
    type: ENavItemType.LINK,
    link: {
      label: 'Producto sugerido',
      icon: 'package',
      to: suggestedProductRoutePath,
    },
  },
  {
    type: ENavItemType.LINK,
    link: {
      label: 'Personalización',
      icon: 'flag',
      to: customizationRoutePath,
    },
  },
  {
    type: ENavItemType.LINK,
    link: {
      label: 'Páginas estáticas',
      icon: 'file',
      to: staticPagesRoutePath,
    },
  },
  {
    type: ENavItemType.LINK,
    link: {
      label: 'Exportaciones masivas',
      icon: 'laptop',
      to: `${masiveLoadsRoutePath}/masive-exportations`,
    },
  },
  {
    type: ENavItemType.DIVIDER,
  },
  {
    type: ENavItemType.BUTTON,
    link: {
      label: 'Cerrar Sesión',
      icon: 'sign-out',
      action: async () => {
        await msalPublicClientApplication.logoutPopup({
          postLogoutRedirectUri: '/login',
        });
        monitoringInstance.setUserId(null);
      },
    },
  },
];
