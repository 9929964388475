import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import {
  IGetResponseGroupingRuleListDTO,
  IGroupingRuleParams,
  IPutPriorityGroupingRuleParams,
  TGroupingRuleDetailDTO,
} from './grouping-rules.dto';

const logisticBaseURL = '/gtm-logistic-management/v1/delivery-grouping-rules';

export const groupingRulesData = {
  getGroupingRuleList: async (): Promise<IGetResponseGroupingRuleListDTO> => {
    const { data } = await https.get<IServiceSuccess<IGetResponseGroupingRuleListDTO>>(logisticBaseURL);
    return data.data;
  },

  createGroupingRule: async (data: IGroupingRuleParams): Promise<void> => https.post(logisticBaseURL, data),

  getGroupingRuleById: async (id: string): Promise<TGroupingRuleDetailDTO> => {
    const { data } = await https.get<IServiceSuccess<TGroupingRuleDetailDTO>>(`${logisticBaseURL}/${id}`);
    return data.data;
  },

  updateGroupingRule: async (options: IGroupingRuleParams, id: string): Promise<void> =>
    https.put(`${logisticBaseURL}/${id}`, options),

  updatePriority: async (deliveryGroupingRules: Array<IPutPriorityGroupingRuleParams>) => {
    await https.put(`${logisticBaseURL}/priorities`, { deliveryGroupingRules });
  },
};
