import * as yup from 'yup';
import { FIELD_REQUIRED_ERROR, FILE_NAME_ERROR, MAX_NUMBER_NAME_ERROR } from 'core/constants/import-modal.constants';
import { FILE_NAME_REGX } from 'core/constants/regex.constant';

export interface IExportFormData {
  fileName: string;
  dateRange?: [Date, Date];
}

export const schema = (hasDateRange: boolean) =>
  yup.object().shape({
    fileName: yup
      .string()
      .trim()
      .required(FIELD_REQUIRED_ERROR)
      .matches(FILE_NAME_REGX, FILE_NAME_ERROR)
      .max(500, MAX_NUMBER_NAME_ERROR),
    dateRange: hasDateRange
      ? yup.array().of(yup.string()).nullable().required(FIELD_REQUIRED_ERROR)
      : yup.array().of(yup.string()),
  });
