import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, FormGroup, HelperText } from '@alicorpdigital/dali-components-react';
import { useCSS } from '@insuma/mpp-ui/hooks';
import type { IDistributorForm, IFormDataMinimumPurcharse } from 'core/model/interfaces/distributors';
import { DistributorSection } from 'screens/logistics/distributors/components/distributor-section';
import { MinimumPurchaseForm } from 'screens/logistics/distributors/components/minimum-purchase-form/minimum-purchase-form';
import { MinimumPurchaseItem } from 'screens/logistics/distributors/components/minimum-purchase-item/minimum-purchase-item';
import { defaultMinimumPurchase } from 'screens/logistics/distributors/distributors.constants';
import { isThereEditingMinimumPurchase } from './distributor-minimum-purchase.utils';

import './distributor-minimum-purchase.scss';

export const DistributorMinimumPurchase = () => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<IDistributorForm>();
  const css = useCSS('distributor-minimum-purchase');

  const [initialMinimumPurchaseState, setInitialMinimumPurchaseState] =
    useState<IFormDataMinimumPurcharse>(defaultMinimumPurchase);

  const { fields, remove, update, append } = useFieldArray({
    control,
    name: 'minimumPurchases',
  });

  const minimumPurchases = watch('minimumPurchases');

  const handleSave = (index: number) => {
    const minimumPurchase = minimumPurchases[index];
    update(index, { ...minimumPurchase, isEditing: false, isNew: false });
  };

  const handleToEditMinimumPurchase = (index: number) => {
    const selectedMinimumPurchase = minimumPurchases[index];

    setInitialMinimumPurchaseState(selectedMinimumPurchase);
    update(index, { ...selectedMinimumPurchase, isEditing: true });
  };

  const onDeletePurchase = (index: number) => {
    remove(index);
  };

  const handleCancelBanner = (index: number) => {
    const selectedMinimumPurchase = minimumPurchases[index];

    if (selectedMinimumPurchase.isNew) remove(index);
    else update(index, { ...initialMinimumPurchaseState, isEditing: false });
  };

  const onAddMinimumPurcharse = () => {
    append(defaultMinimumPurchase);
  };

  return (
    <DistributorSection subtitle="3. Configurar compra mínima">
      <div className={css()}>
        <div className={css('list')}>
          {fields.map((purchaseItem, index) => (
            <div key={index}>
              {purchaseItem.isEditing ? (
                <MinimumPurchaseForm onSaveClick={handleSave} index={index} onCancelClick={handleCancelBanner} />
              ) : (
                <MinimumPurchaseItem onEdit={handleToEditMinimumPurchase} onDelete={onDeletePurchase} index={index} />
              )}
            </div>
          ))}
        </div>
        <div className="distributor-minimum-purchase__actions">
          <Button
            onClick={onAddMinimumPurcharse}
            hasIcon="left"
            iconName="plus"
            variant="tertiary"
            disabled={isThereEditingMinimumPurchase(minimumPurchases)}
          >
            Añadir compra mínima
          </Button>
        </div>
        <FormGroup>
          {errors.minimumPurchases && (
            <>
              <br />
              <HelperText isInvalid>{errors.minimumPurchases.message}</HelperText>
            </>
          )}
        </FormGroup>
      </div>
    </DistributorSection>
  );
};
