import type { IHierarchyTreeCategoryEditForm } from 'core/model/interfaces/navigation/hierarchy.interface';

export const defaultValues: IHierarchyTreeCategoryEditForm = {
  id: '',
  name: '',
  iconUrl: '',
  collapsed: false,
  treeId: '',
  image: { url: '' },
};
