import { ENavItemType } from 'core/model/enums/nav-item.enum';
import { SideNavButton } from './components/side-nav-button';
import { SideNavDivider } from './components/side-nav-divider';
import { SideDropdown } from './components/side-nav-dropdown';
import { INavLink, SideNavLink, TSubNavLink } from './components/side-nav-link';

export interface INavItem {
  type: ENavItemType;
  link?: INavLink;
  action?: () => void;
  sublinks?: Array<TSubNavLink>;
}

interface ISideNavListItemProps {
  item: INavItem;
  currentPath: string;
}

export const SideNavListItem = ({ item, currentPath }: ISideNavListItemProps) => {
  const isActive = item.link?.to ? currentPath.startsWith(item.link?.to) : false;

  if (item.type === ENavItemType.HEADER) return null;
  if (item.type === ENavItemType.DIVIDER) return <SideNavDivider />;
  if (item.type === ENavItemType.BUTTON) return <SideNavButton isActive={isActive} link={item.link} />;

  if (item.type === ENavItemType.LINK && item.link) return <SideNavLink isActive={isActive} link={item.link} />;

  if (item.type === ENavItemType.DROPDOWN && item.link) {
    return <SideDropdown currentPath={currentPath} link={item.link} />;
  }

  return null;
};
