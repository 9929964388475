import { ReactNode } from 'react';

import './customer-form-layout.scss';

interface ICustomerFormLayoutProps {
  children: ReactNode;
  columns?: 'two' | 'three';
  smallGaps?: boolean;
}

export const CustomerFormLayout = ({ children, columns, smallGaps }: ICustomerFormLayoutProps) => {
  const classNames = ['customer-form-layout'];

  if (columns) {
    classNames.push(`customer-form-layout--${columns}-columns`);
  }

  if (smallGaps) {
    classNames.push('customer-form-layout--small-gaps');
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};
