import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import { DEFAULT_GROUPING_RULES } from 'core/constants/logistics.constants';
import type { IGroupingRulesFormData } from 'core/model/interfaces/grouping-rules';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useGroupingRulesDetail = (id?: string) => {
  const [groupingRulesDetail, setGroupingRulesDetail] = useState<IGroupingRulesFormData | null>(DEFAULT_GROUPING_RULES);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchGroupingRulesDetail = async () => {
      if (!id) throw new Error('Id should not be undefined or null');
      try {
        setLoading(true);
        const remoteGroupingRule = await apiService.groupingRules.getGroupingRuleById(id);
        setGroupingRulesDetail(remoteGroupingRule);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo el detalle de la regla de agrupación'));
      } finally {
        setLoading(false);
      }
    };

    fetchGroupingRulesDetail();

    return () => {
      setGroupingRulesDetail(null);
    };
  }, [id]);

  return { groupingRulesDetail, loading, error };
};
