import { Icon } from '@insuma/mpp-ui/components/icon';
import { useCSS } from '@insuma/mpp-ui/hooks';

import './main-list-item.scss';

interface IMainListItemProps {
  name: string;
}

export const MainListItem = ({ name }: IMainListItemProps) => {
  const css = useCSS('main-list-item');

  return (
    <div className={css()}>
      <span>{name}</span>
      <div className={css('icon-container')}>
        <div className={css('icon')}>
          <Icon name="star" size="sm" variant="lightest" />
        </div>
      </div>
    </div>
  );
};
