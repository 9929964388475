import type { IGroupingRulesFormData } from 'core/model/interfaces/grouping-rules';
import { groupingRulesData } from './grouping-rules.data';
import type { IPutPriorityGroupingRuleParams } from './grouping-rules.dto';
import { toFormGroupingRule, toParamsService } from './grouping-rules.mappers';

export const groupingRulesService = {
  getGroupingRuleList: async () => {
    const { deliveryGroupingRules } = await groupingRulesData.getGroupingRuleList();
    return deliveryGroupingRules;
  },

  updatePriority: (groupingRules: Array<IPutPriorityGroupingRuleParams>) =>
    groupingRulesData.updatePriority(groupingRules),

  createGroupingRule: async (data: IGroupingRulesFormData) =>
    groupingRulesData.createGroupingRule(toParamsService(data)),

  updateGroupingRule: async (data: IGroupingRulesFormData, id: string) =>
    groupingRulesData.updateGroupingRule(toParamsService(data), id),

  getGroupingRuleById: async (id: string) => toFormGroupingRule(await groupingRulesData.getGroupingRuleById(id)),
};
