import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { ICustomizationFormData } from 'core/model/interfaces/customization';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useCustomizationDetail = (id: string) => {
  const [formData, setFormData] = useState<ICustomizationFormData>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    apiService.customization
      .getCustomizationById(id)
      .then(remoteCustomization => {
        setFormData(remoteCustomization);
      })
      .catch(error => {
        setError(error);
        notification.error(getErrorFromService(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  return {
    formData,
    isLoading,
    error,
  };
};
