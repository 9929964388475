import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import type {
  IGetProductsDTO,
  IProductDetailDTO,
  IProductDetailResponseDTO,
  IProductsOptions,
  IUpdateProduct,
} from './products.dto';

const productBaseURL = '/gtm-product-management/v1/products';

export const productsData = {
  getProducts: async (options: IProductsOptions): Promise<IGetProductsDTO> => {
    const {
      data: { data },
    } = await https.get<IServiceSuccess<IGetProductsDTO>>(productBaseURL, { params: options });

    return data;
  },

  getProductById: async (sku: string): Promise<IProductDetailDTO> => {
    const {
      data: {
        data: { productDetail },
      },
    } = await https.get<IServiceSuccess<IProductDetailResponseDTO>>(`${productBaseURL}/${sku}`);

    return productDetail;
  },

  updateProduct: async (options: IUpdateProduct): Promise<void> => {
    const { id, ...rest } = options;
    await https.post<IServiceSuccess<void>>(`${productBaseURL}/${options.id}`, rest);
  },
};
