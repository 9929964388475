import { Button } from '@alicorpdigital/dali-components-react';
import type { IColumnProps } from '@insuma/mpp-ui/components/table';
import type { INavigationTreeListItem } from 'core/model/interfaces/navigation';
import { MainListItem } from 'shared/components/main-list-item';

export const getTableColumns = ({
  onDuplicateNavigationTreeClick,
  onViewNavigationTreeClick,
  onEditNavigationTreeClick,
  onDeleteNavigationTreeClick,
}: {
  onDuplicateNavigationTreeClick: (item: INavigationTreeListItem) => void;
  onViewNavigationTreeClick: (id: number) => void;
  onEditNavigationTreeClick: (id: number) => void;
  onDeleteNavigationTreeClick: (item: INavigationTreeListItem) => void;
}): Array<IColumnProps<INavigationTreeListItem>> => [
  {
    accessor: 'name',
    header: 'Nombre del árbol',
    render: (row: INavigationTreeListItem) =>
      row.default ? <MainListItem name={row.name} /> : <span>{row.name}</span>,
  },
  {
    accessor: 'status',
    header: 'Estado',
  },
  {
    accessor: 'events',
    header: '',
    render: (row: INavigationTreeListItem) => (
      <div className="navigation-tree-list__table__row-actions">
        <Button
          isIconOnly
          variant="tertiary"
          size="sm"
          iconName="copy"
          onClick={() => onDuplicateNavigationTreeClick(row)}
        />
        <Button
          isIconOnly
          variant="tertiary"
          size="sm"
          iconName="load-balancer-classic"
          onClick={() => onViewNavigationTreeClick(row.treeId)}
        />
        {!row.default && (
          <Button
            isIconOnly
            variant="tertiary"
            size="sm"
            iconName="pencil"
            onClick={() => onEditNavigationTreeClick(row.treeId)}
          />
        )}
        {!row.default && (
          <Button
            isIconOnly
            variant="tertiary"
            size="sm"
            iconName="trash"
            onClick={() => onDeleteNavigationTreeClick(row)}
          />
        )}
      </div>
    ),
  },
];
