import type { ICustomerBasicInfo, ICustomerCommercialData } from 'core/model/interfaces/customers';
import { CustomerFormContainer, CustomerFormLayout, CustomerFormSubtitle } from '../../components';
import { CustomerCommercialData, CustomerDualBasicData } from './sections';

import './customer-main-information.scss';

interface ICustomerMainInformationProps {
  apudexBasicInfo: ICustomerBasicInfo;
  dexBasicInfo: ICustomerBasicInfo;
  commercialData: ICustomerCommercialData;
}

export const CustomerMainInformation = ({
  apudexBasicInfo,
  dexBasicInfo,
  commercialData,
}: ICustomerMainInformationProps) => (
  <div className="customer-main-information">
    <CustomerFormContainer>
      <CustomerFormLayout>
        <CustomerFormSubtitle>1. Datos básicos</CustomerFormSubtitle>
        <CustomerDualBasicData apudexBasicInfo={apudexBasicInfo} dexBasicInfo={dexBasicInfo} />
      </CustomerFormLayout>
    </CustomerFormContainer>
    <CustomerFormContainer>
      <CustomerFormLayout>
        <CustomerFormSubtitle>2. Datos comerciales</CustomerFormSubtitle>
        <CustomerCommercialData commercialData={commercialData} />
      </CustomerFormLayout>
    </CustomerFormContainer>
  </div>
);
