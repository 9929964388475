import { useIsAuthenticated, useMsal } from '@azure/msal-react';

export const useAuth = () => {
  const isAuth = useIsAuthenticated();
  const { accounts } = useMsal();
  const account = accounts[0];

  return {
    isAuth,
    name: account?.name || '',
    username: account?.username || '',
  };
};
