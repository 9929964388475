import * as yup from 'yup';
import {
  TEXT_ANY_FIELD_REQUIRED,
  TEXT_FIELD_REQUIRED_ERROR,
  TEXT_GROUPINGNAME_ERROR as TEXT_RULENAME_ERROR,
  textFieldMaxError,
} from 'core/constants/error-messages.constants';
import { VALID_RULENAME_REGEX } from 'core/constants/regex.constant';

export const schema = yup.object().shape({
  deliveryGroupingRuleName: yup
    .string()
    .trim()
    .required(TEXT_FIELD_REQUIRED_ERROR)
    .max(200, textFieldMaxError(200))
    .matches(VALID_RULENAME_REGEX, TEXT_RULENAME_ERROR),
  conditions: yup
    .object()
    .shape({
      externalPriceGroupIds: yup.array().of(yup.string().required(TEXT_FIELD_REQUIRED_ERROR)),
      externalPriceListIds: yup.array().of(yup.string().required(TEXT_FIELD_REQUIRED_ERROR)),
      externalProductCategoryIds: yup.array().of(yup.string().required(TEXT_FIELD_REQUIRED_ERROR)),
      externalDistributors: yup.array().of(yup.string().required(TEXT_FIELD_REQUIRED_ERROR)),
    })
    .atLeastOneOf(
      ['externalPriceGroupIds', 'externalPriceListIds', 'externalProductCategoryIds', 'externalDistributors'],
      TEXT_ANY_FIELD_REQUIRED,
    ),
  useCustomerDeliveryDates: yup.string().required(TEXT_FIELD_REQUIRED_ERROR),
  deliveryConfiguration: yup
    .object()
    .shape({
      id: yup.number().required(TEXT_FIELD_REQUIRED_ERROR),
      deliveryConfigurationName: yup.string().required(TEXT_FIELD_REQUIRED_ERROR),
    })
    .nullable()
    .required(TEXT_FIELD_REQUIRED_ERROR),
});
