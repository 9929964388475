import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { initialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

const exportsSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState,
  reducers,
  extraReducers,
});

export const { updateExportsFilters } = exportsSlice.actions;

export default exportsSlice.reducer;
