import { Route, Routes } from 'react-router-dom';
import { CustomizationBanner } from './customization-module/customization-banners';
import { CustomizationModuleList } from './customization-module/customization-module-list';
import { CustomizationShortcuts } from './customization-module/customization-shortcuts';
import { CustomizationSuggestedProduct } from './customization-module/customization-suggested-product';
import { CreateCustomization } from './create-customization';
import { CustomizationList } from './customization-list';
import { EditCustomization } from './edit-customization';

const CustomizationRoutes = () => (
  <Routes>
    <Route path="/" element={<CustomizationList />} />
    <Route path="/crear" element={<CreateCustomization />} />
    <Route path="/editar/:id" element={<EditCustomization />} />
    <Route path="/:customizationId/modulos" element={<CustomizationModuleList />} />
    <Route path="/:customizationId/modulos/:moduleId/shortcuts" element={<CustomizationShortcuts />} />
    <Route path="/:customizationId/modulos/:moduleId/banners" element={<CustomizationBanner />} />
    <Route path="/:customizationId/modulos/:moduleId/productosSugeridos" element={<CustomizationSuggestedProduct />} />
  </Routes>
);

export default CustomizationRoutes;
