import type { IProductsState } from './types';

export const SLICE_NAMESPACE = 'products';

export const initialState: IProductsState = {
  areFiltersApplied: false,
  products: [],
  filter: '',
  currentPage: 1,
  totalPages: 0,
  isLoading: false,
};
