import { Controller, useFormContext } from 'react-hook-form';
import { Form, FormGroup } from '@alicorpdigital/dali-components-react';
import { Autocomplete } from '@insuma/mpp-ui/components/form';
import { useDeliveryConfigurations } from 'screens/logistics/grouping-rules/hooks/use-delivery-configurations.hooks';

import './grouping-rules-delivery-date.scss';

const GroupingRulesDeliveryDate = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { deliveryConfigurationList, loading: loadingDeliveryConfiguration } = useDeliveryConfigurations();

  return (
    <div className="row-container">
      <h4 className="row-container__subtitle">4. Fechas de entrega asociadas a esta agrupación</h4>
      <div className="delivery-date">
        <FormGroup>
          <Form.Label>Fecha de entrega</Form.Label>
          <Controller
            control={control}
            name="deliveryConfiguration"
            render={({ field: { ref, ...props } }) => (
              <Autocomplete
                {...props}
                inputRef={ref}
                source={deliveryConfigurationList}
                isLoading={loadingDeliveryConfiguration}
                placeholder="Selecciona nombre fecha de entrega"
                getOptionLabel={option => option.deliveryConfigurationName}
                getOptionValue={option => `${option.id}`}
                isClearable={false}
              />
            )}
          />
          {errors.deliveryConfiguration && (
            <div className="delivery-date__error-container">
              <Form.HelperText isInvalid>{errors.deliveryConfiguration.message}</Form.HelperText>
            </div>
          )}
        </FormGroup>
      </div>
    </div>
  );
};

export default GroupingRulesDeliveryDate;
