import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { httpBlobStorage, https } from 'shared/utils/https';
import {
  ICreateDownloadSignedUrlsParams,
  ICreateUploadSignedUrlsParams,
  ICreateUploadSignedUrlsResponse,
  IUploadSignedUrlsParams,
} from './g2m-functions.dto';

const g2mFunctionsUrl = '/gtm-static-management/v1';

export const g2mFunctionsData = {
  createUploadSignedUrls: async (body: ICreateUploadSignedUrlsParams) => {
    const { data } = await https.post<IServiceSuccess<ICreateUploadSignedUrlsResponse>>(
      `${g2mFunctionsUrl}/generate-upload-urls`,
      body,
    );
    return data.data;
  },
  createDownloadSignedUrls: async (body: ICreateDownloadSignedUrlsParams) => {
    const { data } = await https.post<IServiceSuccess<ICreateUploadSignedUrlsResponse>>(
      `${g2mFunctionsUrl}/generate-download-urls`,
      body,
    );
    return data.data;
  },
  uploadSignedUrls: async (files: Array<IUploadSignedUrlsParams>, getFullUrl = false) => {
    const promises = files.map(({ url, file }) => {
      let finalUrl = '';
      if (getFullUrl) finalUrl = url;
      else {
        const cutUrl = url.split('?')?.shift();
        if (!cutUrl) throw new Error('Url should not be undefined or null');
        finalUrl = new URL(cutUrl).pathname;
      }

      return httpBlobStorage
        .put(url, file, { headers: { 'Content-Type': file.type } })
        .then(() => ({
          url: finalUrl,
          file,
        }))
        .catch(error => {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw { file, resason: error };
        });
    });
    const results = await Promise.allSettled(promises);
    return results;
  },
};
