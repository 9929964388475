import type { ICustomizationState } from './types';

export const SLICE_NAMESPACE = 'customization';

export const initialState: ICustomizationState = {
  filters: {
    q: '',
    page: 1,
    limit: 10,
  },
};
