import type { IExportsState } from './types';

export const SLICE_NAMESPACE = 'exports';

export const initialState: IExportsState = {
  isExportsLoading: false,
  exports: [],
  currentPage: 1,
  totalPages: 0,
  filters: {
    query: '',
  },
};
