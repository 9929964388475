import { IHierarchyTreeFamily } from 'core/model/interfaces/navigation/hierarchy.interface';
import { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import type {
  IExternalProductFamiliesResponse,
  IGetHierarchyTreeResponse,
  IPatchHierarchyTreeItemsWeight,
  IPostHierarchyTreeCategoryBody,
  IPostHierarchyTreeSubcategoryBody,
  IPutHierarchyTreeCategoryBody,
  IPutHierarchyTreeSubcategoryBody,
} from './hierarchy.dto';

const treesPath = '/gtm-classification-management/v1/trees';

export const hierarchyData = {
  getHierarchyTree: async (id: string): Promise<IGetHierarchyTreeResponse> => {
    const {
      data: { data: treeDetailData },
    } = await https.get<IServiceSuccess<IGetHierarchyTreeResponse>>(`${treesPath}/${id}/items`);
    return treeDetailData;
  },

  createHierachyCategory: async (body: IPostHierarchyTreeCategoryBody): Promise<void> => {
    const { treeId, ...restOfParams } = body;
    await https.post(`${treesPath}/${treeId}/items`, restOfParams);
  },

  editHierachyCategory: async (body: IPutHierarchyTreeCategoryBody): Promise<void> => {
    const { treeId, categoryId, ...restOfParams } = body;
    await https.patch(`${treesPath}/${treeId}/items/${categoryId}`, restOfParams);
  },

  createHierachySubcategory: async (body: IPostHierarchyTreeSubcategoryBody): Promise<void> => {
    const { treeId, categoryId, ...restOfParams } = body;
    await https.post(`${treesPath}/${treeId}/items/${categoryId}/items`, restOfParams);
  },

  editHierachySubcategory: async (body: IPutHierarchyTreeSubcategoryBody): Promise<void> => {
    const { treeId, itemId, categoryId, ...restOfParams } = body;
    await https.patch(`${treesPath}/${treeId}/items/${itemId}`, restOfParams);
  },

  deleteHierarchyItem: async (id: string, treeId: string): Promise<void> => {
    await https.delete<void>(`${treesPath}/${treeId}/items/${id}`);
  },

  publishHierachyTrees: async (id: string): Promise<void> => {
    await https.patch<void>(`${treesPath}/${id}`, { publish: true });
  },

  updateHierarchyItemsWeight: async (body: IPatchHierarchyTreeItemsWeight) => {
    const { treeId, ...restOfParams } = body;
    await https.patch(`${treesPath}/${treeId}/items`, restOfParams);
  },

  getFamiliesForHierarchyTree: async (treeId: string): Promise<Array<IHierarchyTreeFamily>> => {
    const {
      data: {
        data: { externalProductFamilies },
      },
    } = await https.get<IServiceSuccess<IExternalProductFamiliesResponse>>(
      `${treesPath}/${treeId}/external-product-families`,
    );

    return externalProductFamilies;
  },
};
