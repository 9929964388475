import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { navigationTreeRoutePath, productsRoutePath } from 'core/constants/routes.constants';
import type { INavigationTreeDetailCreateForm } from 'core/model/interfaces/navigation';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';
import { NavigationTreeForm } from '../navigation-tree-form/navigation-tree-form';
import { defaultValues } from './create-navigation-tree.constants';
import { schema } from './create-navigation-tree.schema';

export const CreateNavigationTree = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const onCreate = async (data: INavigationTreeDetailCreateForm) => {
    try {
      setIsSaving(true);
      await apiService.navigation.createNavigationTree(data);
      notification.success('Nuevo árbol creado exitosamente');
      setIsSaving(false);
      navigate(`${productsRoutePath}${navigationTreeRoutePath}`);
    } catch (error) {
      setIsSaving(false);
      notification.error(getErrorFromService(error, 'Error inesperado mientras se creaba el arbol de navegación'));
    }
  };

  return (
    <NavigationTreeForm onSubmit={onCreate} schema={schema} defaultValues={defaultValues}>
      <NavigationTreeForm.Title title="Nuevo Árbol" />
      <NavigationTreeForm.MainFields />
      <br />
      <NavigationTreeForm.CategoryFields />
      <NavigationTreeForm.SubmitButton label="Guardar" disabled={isSaving} />
    </NavigationTreeForm>
  );
};
