import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@alicorpdigital/dali-components-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DEFAULT_GROUPING_RULES } from 'core/constants/logistics.constants';
import { groupingRulesRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import type { IGroupingRulesFormData } from 'core/model/interfaces/grouping-rules';
import { Link } from 'shared/components/link';
import GroupingRulesConditions from './steps/grouping-rules-conditions/grouping-rules-conditions';
import GroupingRulesDeliveryDate from './steps/grouping-rules-delivery-date/grouping-rules-delivery-date';
import GroupingRulesDeliveryTime from './steps/grouping-rules-delivery-time/grouping-rules-delivery-time';
import GroupingRulesName from './steps/grouping-rules-name/grouping-rules-name';
import { schema } from './grouping-rules.schema';

import './grouping-rules-form.scss';

interface IGroupingRulesFormProps {
  onSubmit(data: IGroupingRulesFormData): void;
  groupingRules?: IGroupingRulesFormData;
  isSaving?: boolean;
}

export const GroupingRulesForm = ({ isSaving = false, groupingRules, onSubmit }: IGroupingRulesFormProps) => {
  const defaultValues: IGroupingRulesFormData = structuredClone(groupingRules || DEFAULT_GROUPING_RULES);
  const isEdit = !!groupingRules;

  const formMethods = useForm<IGroupingRulesFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { handleSubmit } = formMethods;

  return (
    <div className="grouping-rules-form">
      <Link icon="caret-left" to={`${logisticsRoutePath}${groupingRulesRoutePath}`}>
        Atrás
      </Link>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="grouping-rules-form__title">{isEdit ? 'Editar' : 'Nueva'} agrupación</h2>

        <FormProvider {...formMethods}>
          <div className="grouping-rules-form__steps">
            <GroupingRulesName />
            <GroupingRulesConditions />
            <GroupingRulesDeliveryTime />
            <GroupingRulesDeliveryDate />
          </div>

          <div className="grouping-rules-form__actions">
            <Button size="md" type="submit" isDisabled={isSaving}>
              Guardar
            </Button>
          </div>
        </FormProvider>
      </form>
    </div>
  );
};
