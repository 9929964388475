import type { ICategory, IFamily, IPriceGroup, IPriceList, IZones } from 'core/model/interfaces/master-entities';
import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import {
  IGetCategoriesResponse,
  IGetFamiliesResponse,
  IGetPriceGroupsResponse,
  IGetPriceListsResponse,
  IGetZonesResponse,
} from './master-entities.dto';

const masterEntitiesBaseURL = '/gtm-master-management/v1';

export const masterEntitiesData = {
  getFamilies: async (): Promise<Array<IFamily>> => {
    const {
      data: {
        data: { externalProductFamilies },
      },
    } = await https.get<IServiceSuccess<IGetFamiliesResponse>>(`${masterEntitiesBaseURL}/product-families`);

    return externalProductFamilies;
  },

  getPricesGroup: async (): Promise<Array<IPriceGroup>> => {
    const {
      data: {
        data: { priceGroups },
      },
    } = await https.get<IServiceSuccess<IGetPriceGroupsResponse>>(`${masterEntitiesBaseURL}/price-groups`);

    return priceGroups;
  },

  getZones: async (): Promise<Array<IZones>> => {
    const {
      data: {
        data: { externalZones },
      },
    } = await await https.get<IServiceSuccess<IGetZonesResponse>>(`${masterEntitiesBaseURL}/zones`);

    return externalZones;
  },

  getPriceLists: async (selectedDexPricesIds?: string): Promise<Array<IPriceList>> => {
    const params = selectedDexPricesIds ? { priceGroupCode: selectedDexPricesIds } : undefined;
    const {
      data: {
        data: { priceLists },
      },
    } = await https.get<IServiceSuccess<IGetPriceListsResponse>>(`${masterEntitiesBaseURL}/price-lists`, {
      params,
    });

    return priceLists;
  },

  getCategories: async (): Promise<Array<ICategory>> => {
    const {
      data: {
        data: { externalProductCategories },
      },
    } = await https.get<IServiceSuccess<IGetCategoriesResponse>>(`${masterEntitiesBaseURL}/product-categories`);

    return externalProductCategories;
  },
};
