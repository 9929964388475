import './table.scss';

interface ITableProps {
  children: React.ReactNode;
  isLoading?: boolean;
  skeleton?: JSX.Element;
  className?: string;
}

export const Table = ({ children, isLoading = false, skeleton, className }: ITableProps) => (
  <div className={`mpp-table ${className || ''}`}>
    {isLoading && skeleton !== undefined ? skeleton : <table className="mpp-table__table">{children}</table>}
  </div>
);

export const THead: React.FC = ({ children }) => <thead className="mpp-table__thead">{children}</thead>;

export const TBody: React.FC = ({ children }) => <tbody className="mpp-table__tbody">{children}</tbody>;

export const TR: React.FC = ({ children }) => <tr className="mpp-table__tr">{children}</tr>;

export const TH: React.FC = ({ children }) => <th className="mpp-table__th">{children}</th>;

export type TDVariant = 'bold' | 'primary' | 'warning' | 'danger' | 'opaque';

export interface ITDProps {
  variant?: TDVariant;
  className?: string;
  colSpan?: number;
}

export const TD: React.FC<ITDProps> = ({ children, variant, colSpan, className = '' }) => (
  <td colSpan={colSpan} className={`mpp-table__td ${variant ? `mpp-table__td--${variant}` : ''} ${className}`}>
    {children}
  </td>
);
