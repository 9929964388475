import { useNavigate } from 'react-router-dom';
import { Button } from '@alicorpdigital/dali-components-react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Drag from 'assets/drag.svg';
import { groupingRulesRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import type { TGroupingRuleItemListDisplay } from 'core/model/interfaces/grouping-rules';

import './sortable-item.scss';

interface ISortableItemProps {
  item: TGroupingRuleItemListDisplay;
}

export const SortableItem = ({ item }: ISortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id });
  const navigate = useNavigate();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div className="sortable-item">
        <img {...attributes} {...listeners} src={Drag} alt="" />
        <span>{item.deliveryGroupingRuleName}</span>
        <div className="sortable-item__actions">
          <Button
            isIconOnly
            iconName="pencil"
            variant="tertiary"
            onClick={() => navigate(`${logisticsRoutePath}${groupingRulesRoutePath}/editar/${item.id}`)}
          />
        </div>
      </div>
    </div>
  );
};
