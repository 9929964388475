export const loginRoutePath = '/';
export const customizationRoutePath = '/personalizacion';
export const customersRoutePath = '/clientes';
export const logisticsRoutePath = '/logistica';
export const distributorsRoutePath = '/operadores';
export const deliveryConditionRoutePath = '/condicion-de-entrega';
export const groupingRulesRoutePath = '/reglas-de-agrupacion';
export const ordersRoutePath = '/pedidos';
export const productsRoutePath = '/productos';
export const navigationTreeRoutePath = '/arbol-de-navegacion';
export const masiveLoadsRoutePath = '/cargas-masivas';
export const staticPagesRoutePath = '/paginas-estaticas';
export const suggestedProductRoutePath = '/producto-sugerido';
