import { IMAGES_ROUTES } from './general.constants';

export const ImageRoutes = {
  // CUSTOMIZATION
  REFERENCE: `${IMAGES_ROUTES}/backoffice/statics/images/customization/customization_reference.jpg`,
  BANNER_REFERENCE: `${IMAGES_ROUTES}/backoffice/statics/images/customization/customization_reference_banner.jpg`,
  SHORTCUT_REFERENCE: `${IMAGES_ROUTES}/backoffice/statics/images/customization/customization_reference_shortcut.jpg`,
  SUGGESTED_PRODUCT_REFERENCE: `${IMAGES_ROUTES}/backoffice/statics/images/customization/customization_reference_suggested_product.jpg`,

  // PRODUCTS
  DEFAULT_PRODUCT: `${IMAGES_ROUTES}/backoffice/statics/images/products/products_default-product.jpg`,
};
