export const InsumaLogo = () => (
  <svg width="167" height="54" viewBox="0 0 167 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1418_14572)">
      <path
        d="M3.83194 15.8561C3.32973 15.8658 2.83067 15.7762 2.36454 15.5924C1.8984 15.4086 1.47476 15.1345 1.11889 14.7864C0.76449 14.4406 0.483238 14.0294 0.291314 13.5765C0.0993886 13.1235 0.000579834 12.6378 0.000579834 12.1472C0.000579834 11.6566 0.0993886 11.1709 0.291314 10.7179C0.483238 10.265 0.76449 9.85378 1.11889 9.50798C1.47468 9.15969 1.89827 8.88533 2.3644 8.70128C2.83052 8.51723 3.32963 8.42725 3.83194 8.43671C4.32607 8.43066 4.81637 8.52239 5.27359 8.70645C5.73082 8.8905 6.1456 9.1631 6.4932 9.50798C6.8476 9.85378 7.12885 10.265 7.32078 10.7179C7.5127 11.1709 7.61151 11.6566 7.61151 12.1472C7.61151 12.6378 7.5127 13.1235 7.32078 13.5765C7.12885 14.0294 6.8476 14.4406 6.4932 14.7864C6.14552 15.1311 5.73069 15.4034 5.27346 15.5872C4.81622 15.771 4.32596 15.8624 3.83194 15.8561ZM0.639737 43.194V18.5946H7.02577V43.186L0.639737 43.194Z"
        fill="#EC3A0E"
      />
      <path
        d="M12.1343 43.1866V18.5952H18.4685V22.4877C19.1063 21.1992 20.1439 20.1629 21.5814 19.3788C23.0189 18.5947 24.6954 18.2026 26.6109 18.2026C29.8031 18.2026 32.2955 19.143 34.088 21.0238C35.8805 22.9046 36.7762 25.4827 36.7751 28.7579V43.1866H30.3891V29.6528C30.3891 27.8419 29.8835 26.4114 28.8723 25.3613C27.8611 24.3113 26.4684 23.7889 24.6943 23.7942C22.8845 23.7942 21.3947 24.36 20.2249 25.4917C19.055 26.6233 18.4696 28.0951 18.4685 29.9071V43.1866H12.1343Z"
        fill="#EC3A0E"
      />
      <path
        d="M64.6098 32.7346V18.5889H71.0492V32.2069C71.0492 34.1926 71.5813 35.6729 72.6453 36.6477C73.7094 37.6226 75.1285 38.1105 76.9027 38.1116C78.6769 38.1116 80.0965 37.6152 81.1617 36.6223C82.2268 35.6295 82.7588 34.1402 82.7578 32.1545V18.5952H89.1972V32.582C89.1972 36.2387 88.0263 39.034 85.6845 40.9678C83.3427 42.9015 80.3981 43.8684 76.8509 43.8684C73.3025 43.8684 70.3753 42.9106 68.0691 40.9948C65.7629 39.079 64.6098 36.3256 64.6098 32.7346Z"
        fill="#EC3A0E"
      />
      <path
        d="M93.9872 43.1868V18.5955H100.32V22.4101C100.892 21.1171 101.859 20.0307 103.088 19.3012C104.366 18.5181 105.856 18.1261 107.559 18.125C111.213 18.125 113.714 19.6582 115.062 22.7248C116.587 19.6582 119.514 18.125 123.844 18.125C126.789 18.125 129.077 19.0659 130.709 20.9478C132.341 22.8297 133.157 25.4077 133.158 28.6819V43.1916H126.77V29.564C126.77 27.7531 126.354 26.3227 125.521 25.2726C124.688 24.2225 123.508 23.6996 121.98 23.7038C121.251 23.6852 120.527 23.8291 119.862 24.1248C119.197 24.4204 118.609 24.8601 118.142 25.4109C117.148 26.5425 116.652 28.0143 116.653 29.8262V43.1868H110.319V29.564C110.319 27.7531 109.902 26.3227 109.069 25.2726C108.236 24.2225 107.056 23.6996 105.529 23.7038C104.04 23.7038 102.799 24.2787 101.806 25.4283C100.813 26.578 100.316 28.0413 100.315 29.8183V43.1789L93.9872 43.1868Z"
        fill="#EC3A0E"
      />
      <path
        d="M140.506 40.0985C138.053 37.6232 136.827 34.561 136.829 30.9117C136.832 27.2624 138.057 24.2081 140.506 21.7487C142.956 19.2883 145.991 18.0581 149.608 18.0581C151.668 18.0581 153.426 18.4491 154.882 19.2311C156.338 20.0131 157.301 20.9906 157.771 22.1636V18.5953H163.856V35.7991C163.856 36.8418 164 37.5835 164.289 38.0243C164.577 38.4651 165.058 38.6849 165.733 38.6839C166.157 38.6804 166.581 38.6475 167.002 38.5853V43.3218C166.325 43.5824 165.327 43.7128 164.009 43.7128C162.555 43.7128 161.372 43.4521 160.46 42.9308C159.924 42.5999 159.463 42.1646 159.105 41.6517C158.747 41.1388 158.501 40.5593 158.38 39.9491C156.589 42.5239 153.665 43.8108 149.608 43.8097C145.991 43.8119 142.956 42.5748 140.506 40.0985ZM145.105 25.6189C143.69 27.0314 142.983 28.7956 142.984 30.9117C142.985 33.0277 143.692 34.7914 145.105 36.2028C146.519 37.6163 148.291 38.322 150.421 38.3199C152.579 38.3199 154.401 37.6142 155.887 36.2028C157.373 34.7914 158.114 33.0277 158.108 30.9117C158.108 28.7925 157.368 27.0282 155.887 25.6189C154.407 24.2097 152.584 23.504 150.421 23.5018C148.29 23.5018 146.518 24.2075 145.105 25.6189Z"
        fill="#EC3A0E"
      />
      <path
        d="M58.6932 41.3333C54.1752 45.3736 44.7718 44.4581 41.2785 39.5675C41.0421 39.2385 40.8576 38.8761 40.5938 38.4294C42.0765 37.6268 43.5674 36.6366 45.1263 35.7831C46.6674 38.4008 49.0906 39.1113 51.9008 38.76C52.7102 38.6615 53.5682 38.5169 54.2351 38.0671C54.902 37.6173 55.2857 36.9783 55.3359 36.3553C55.3211 36.1435 55.261 35.9371 55.1594 35.7497C55.0157 35.5006 54.8263 35.2797 54.6009 35.098C54.357 34.8911 54.0891 34.7129 53.8029 34.5672C53.4245 34.3858 53.0328 34.2328 52.6309 34.1094C52.1323 33.9505 51.6904 33.8043 51.3003 33.6723C50.9101 33.5404 50.3759 33.3831 49.7042 33.1955C49.0324 33.008 48.461 32.8284 47.9996 32.6678C47.9753 32.6608 47.9516 32.6523 47.9284 32.6424C47.9224 32.6428 47.9165 32.6428 47.9106 32.6424C47.1118 32.4492 46.3276 32.2022 45.5634 31.9033C41.6314 30.2885 40.257 26.315 42.0166 22.9422C42.0668 22.8437 42.1186 22.7404 42.1785 22.6418L42.1915 22.6164L42.2546 22.5052C42.2546 22.4924 42.2676 22.4845 42.2708 22.4765C42.3339 22.3732 42.3971 22.2699 42.465 22.1714C42.7047 21.8229 42.9803 21.4997 43.2874 21.2066C43.556 20.9411 43.8468 20.6981 44.1567 20.4802C45.9805 19.178 48.2225 18.5269 50.8826 18.5269C53.3804 18.5269 55.3634 19.0911 57.1489 20.0559C59.2193 21.1812 60.4107 23.2681 60.4107 23.3253C60.4107 23.3348 56.2165 25.7921 56.2165 25.7921L55.9898 25.5203C54.8178 24.1703 53.0788 23.4954 50.7725 23.4954C49.7819 23.4954 48.9924 23.6961 48.4043 24.0977C47.8215 24.5014 47.5269 25.0244 47.5269 25.6681C47.5247 25.9065 47.5704 26.1431 47.6613 26.3642C47.7588 26.5867 47.9153 26.7794 48.1145 26.9221C48.3214 27.0788 48.5376 27.2232 48.762 27.3544C49.0751 27.5284 49.404 27.6731 49.7446 27.7868C50.1898 27.9457 50.5961 28.084 50.97 28.2C51.344 28.316 51.8571 28.4718 52.5111 28.6768L52.7377 28.7515C53.0615 28.8294 53.3723 28.9105 53.6879 28.9947C54.6495 29.2554 55.6305 29.4938 56.5596 29.8482C62.0149 31.9351 63.0363 37.4536 58.6932 41.3333Z"
        fill="#EC3A0E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1418_14572">
        <rect width="167" height="35.4375" fill="white" transform="translate(0 8.4375)" />
      </clipPath>
    </defs>
  </svg>
);
