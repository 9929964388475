import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { IFamily } from 'core/model/interfaces/master-entities';
import { masterEntitiesService } from 'core/services/master-entities/master-entities.service';
import { getErrorFromService } from 'shared/utils/https';

interface IUseMasterFamiliesProps {
  enabled?: boolean;
}
export const useMasterFamilies = ({ enabled = true }: IUseMasterFamiliesProps) => {
  const [familiesList, setFamiliesList] = useState<Array<IFamily>>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchMasterFamilies = async () => {
      setLoading(true);

      try {
        const masterFamiliesList = await masterEntitiesService.getFamilies();
        setFamiliesList(masterFamiliesList);
        setLoading(false);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo las opciones de familias.'));
      } finally {
        setLoading(false);
      }
    };

    if (enabled) fetchMasterFamilies();

    return () => {
      setFamiliesList([]);
    };
  }, [enabled]);

  return { familiesList, loading, error };
};
