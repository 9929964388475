import { Button } from '@alicorpdigital/dali-components-react';
import type { IColumnProps } from '@insuma/mpp-ui/components/table';
import { DEFAULT_CURRENCY } from 'core/constants/currency.constants';
import { EOrderStatus } from 'core/model/enums/orders.enum';
import type { IDisplayOrder } from 'core/model/interfaces/orders';
import { formatDate } from 'shared/utils/date.utils';
import { formatNumberWithCommas } from 'shared/utils/format-numbers.utils';

export const getOrdersColumns = (onView: (orderId: number) => void): Array<IColumnProps<IDisplayOrder>> => [
  {
    header: 'Razón social cliente',
    accessor: 'businessName',
  },
  {
    header: 'Código de pedido',
    accessor: 'orderNumber',
  },
  {
    header: 'Fecha de pedido',
    accessor: 'orderDate',
    render: row => <span>{`${formatDate(row.orderDate, 'DD/MM/YYYY')}`}</span>,
  },
  {
    header: 'Importe',
    accessor: 'totalAmount',
    render: row => <span>{`${DEFAULT_CURRENCY.symbol} ${formatNumberWithCommas(row.totalAmount, 2)}`}</span>,
  },
  {
    header: 'Estado',
    accessor: 'status',
  },
  {
    header: '',
    accessor: 'events',
    render: row => (
      <div className="orders-list__table-actions">
        <Button isIconOnly size="sm" iconName="eye" variant="tertiary" onClick={() => onView(row.orderId)} />
      </div>
    ),
  },
];

export const getStatus = (status: EOrderStatus) => {
  switch (status) {
    case EOrderStatus.SUCCESSFUL:
      return 'success';
    case EOrderStatus.REJECTED:
      return 'rejected';
    default:
      return null;
  }
};
