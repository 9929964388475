import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { groupingRulesRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import type { IGroupingRulesFormData } from 'core/model/interfaces/grouping-rules';
import { groupingRulesService } from 'core/services/grouping-rules/grouping-rules.service';
import { getErrorFromService } from 'shared/utils/https';
import { GroupingRulesForm } from '../grouping-rules-form/grouping-rules-form';

export const CreateGroupingRules = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async (data: IGroupingRulesFormData) => {
    try {
      setIsSaving(true);
      await groupingRulesService.createGroupingRule(data);

      notification.success('Regla de agrupación creada exitosamente');
      setIsSaving(false);
      navigate(`${logisticsRoutePath}${groupingRulesRoutePath}`);
    } catch (error) {
      setIsSaving(false);
      notification.error(getErrorFromService(error, 'Error creando la regla de agrupación'));
    }
  };

  return <GroupingRulesForm onSubmit={onSubmit} isSaving={isSaving} />;
};
