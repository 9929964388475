import { useEffect } from 'react';
import { DefaultValues, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { navigationTreeRoutePath, productsRoutePath } from 'core/constants/routes.constants';
import { Link } from 'shared/components/link';
import {
  NavigationTreeFormCategoryFields,
  NavigationTreeFormMainFields,
  NavigationTreeFormSubmitButton,
  NavigationTreeFormTitle,
} from './components';

import './navigation-tree-form.scss';

interface INavigationTreeFormProps<T extends FieldValues> {
  children: React.ReactNode;
  defaultValues: DefaultValues<T>;
  schema: yup.AnyObjectSchema;
  onSubmit: (data: T) => void;
  navigationTree?: T;
}
export const NavigationTreeForm = <T extends FieldValues>({
  children,
  onSubmit,
  defaultValues,
  schema,
  navigationTree,
}: INavigationTreeFormProps<T>) => {
  const formMethods = useForm<T>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (navigationTree) reset(navigationTree);
  }, [navigationTree, reset]);

  return (
    <FormProvider {...formMethods}>
      <div className="navigation-tree-form">
        <Link icon="caret-left" to={`${productsRoutePath}${navigationTreeRoutePath}`}>
          Atrás
        </Link>
        <form onSubmit={handleSubmit(onSubmit)} aria-label="Formulario de árbol de navegación">
          {children}
        </form>
      </div>
    </FormProvider>
  );
};

NavigationTreeForm.MainFields = NavigationTreeFormMainFields;
NavigationTreeForm.CategoryFields = NavigationTreeFormCategoryFields;
NavigationTreeForm.Title = NavigationTreeFormTitle;
NavigationTreeForm.SubmitButton = NavigationTreeFormSubmitButton;
