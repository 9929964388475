import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { IHierarchyTreeFamily } from 'core/model/interfaces/navigation/hierarchy.interface';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

interface IUseHierarchyTreeFamiliesProps {
  enabled?: boolean;
  treeId: string;
}
export const useHierarchyTreeFamilies = ({ enabled = true, treeId }: IUseHierarchyTreeFamiliesProps) => {
  const [familiesList, setFamiliesList] = useState<Array<IHierarchyTreeFamily>>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchMasterFamilies = async () => {
      setLoading(true);

      try {
        const hierarchyFamiliesList = await apiService.hierarchy.getFamiliesForHierarchyTree(treeId);
        setFamiliesList(hierarchyFamiliesList);
        setLoading(false);
      } catch (e) {
        setError(e);
        notification.error(getErrorFromService(e, 'Error obteniendo las opciones de familias.'));
      } finally {
        setLoading(false);
      }
    };

    if (enabled) fetchMasterFamilies();

    return () => {
      setFamiliesList([]);
    };
  }, [enabled, treeId]);

  return { familiesList, loading, error };
};
