import { getFileExtension } from 'shared/utils/file.utils';
import { g2mFunctionsData } from './g2m-functions.data';
import type { IUploadFilesParams, IUploadFilesResponse } from './g2m-functions.dto';
import { mapProcessedFiles, mergeFilesWithUrls } from './g2m-functions.mapper';

export const g2mFunctionsService = {
  uploadFiles: async ({ file, context, getFullFileUrl = false }: IUploadFilesParams): Promise<IUploadFilesResponse> => {
    const files = Array.isArray(file) ? [...file] : [file];
    const extension = getFileExtension(files[0]);
    if (!extension) throw new Error('The file has no extension');
    if (!files.every(fileItem => getFileExtension(fileItem) === extension))
      throw new Error('The file extensions must be the same');
    const params = {
      extension,
      context,
      quantity: files.length,
    };
    const { urls } = await g2mFunctionsData.createUploadSignedUrls(params);
    const mergedFiles = mergeFilesWithUrls(files, urls);
    const processedFiles = await g2mFunctionsData.uploadSignedUrls(mergedFiles, getFullFileUrl);
    return mapProcessedFiles(processedFiles);
  },
  getDownloadUrls: async (urls: Array<string>) => {
    if (!urls) throw new Error('urls parameter is required');

    const { urls: downloadUrls } = await g2mFunctionsData.createDownloadSignedUrls({ urls });
    return downloadUrls;
  },
};
