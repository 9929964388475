import * as yup from 'yup';
import { TEXT_FIELD_REQUIRED_ERROR, textFieldMaxError } from 'core/constants/error-messages.constants';

export const schema = yup.object().shape({
  shortcuts: yup.array().of(
    yup.object({
      title: yup.string().trim().required('Debes agregar un título para este shortcut').max(20, textFieldMaxError(20)),
      link: yup.string().trim().required('Debes agregar un enlace'),
      buttonName: yup
        .string()
        .trim()
        .required('Debes agregar un título para este botón')
        .max(20, textFieldMaxError(20)),
      image: yup
        .object({
          blob: yup.mixed().nullable(),
          url: yup.string().required(TEXT_FIELD_REQUIRED_ERROR),
        })
        .required(TEXT_FIELD_REQUIRED_ERROR),
    }),
  ),
});
