import { Form } from '@alicorpdigital/dali-components-react';
import type { ICustomerDeliveryData } from 'core/model/interfaces/customers';
import { CustomerFormLayout } from 'screens/customers/customer-form/components';

interface ICustomerDeliveryDataProps {
  deliveryData: ICustomerDeliveryData;
}
export const CustomerDeliveryData = ({ deliveryData }: ICustomerDeliveryDataProps) => (
  <CustomerFormLayout columns="two">
    <Form.Group>
      <Form.Label>Fecha de entrega 1</Form.Label>
      <Form.Input value={deliveryData.deliveryDate1} disabled />
    </Form.Group>
    <Form.Group>
      <Form.Label>Fecha de entrega 2</Form.Label>
      <Form.Input value={deliveryData.deliveryDate2} disabled />
    </Form.Group>
    <Form.Group>
      <Form.Label>Fecha de entrega 3</Form.Label>
      <Form.Input value={deliveryData.deliveryDate3} disabled />
    </Form.Group>
  </CustomerFormLayout>
);
