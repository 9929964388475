import { formatNumberWithCommas } from 'shared/utils/format-numbers.utils';

export const getAmount = (value?: number): string => {
  if (!value) return '';
  return `S/ ${formatNumberWithCommas(value, 2)}`;
};

export const getCustomerGroup = (id?: string, description?: string): string => {
  let customerGroup = '';
  if (id) customerGroup = id;
  if (description) customerGroup = `${customerGroup} (${description})`;
  return customerGroup;
};
