import { useFormContext } from 'react-hook-form';
import { Form, FormGroup } from '@alicorpdigital/dali-components-react';
import { useDistributorsOptions, usePriceList } from 'core/hooks/master-entities';
import { AutocompleteForm, InputValueForm } from 'shared/components/forms';
import { NavigationTreeFormRowContainer } from './navigation-tree-form-row-container';
import { NavigationTreeFormSection } from './navigation-tree-form-section';
import { NavigationTreeFormSubtitle } from './navigation-tree-form-subtitle';

interface INavigationTreeFormMainFieldsProps {
  blockFields?: boolean;
}
export const NavigationTreeFormMainFields = ({ blockFields }: INavigationTreeFormMainFieldsProps) => {
  const { distributorsList, loading: loadingDistributors } = useDistributorsOptions();
  const { priceList: priceLists, loading: loadingPriceLists } = usePriceList();
  const {
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <NavigationTreeFormRowContainer>
      <NavigationTreeFormSubtitle text="1. Información del árbol" />
      <NavigationTreeFormSection>
        <InputValueForm label="Nombre del árbol" name="name" onChange={() => trigger('name')} />
        {!blockFields && (
          <>
            <FormGroup>
              <Form.Label>Nombre de operador</Form.Label>
              <AutocompleteForm
                source={distributorsList}
                name="externalDistributors"
                isLoading={loadingDistributors}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                isInvalid={!!errors.externalDistributors}
                isMulti
                aria-label="Nombre de operador"
              />
              {errors.externalDistributors && (
                <Form.HelperText isInvalid>{errors.externalDistributors.message}</Form.HelperText>
              )}
            </FormGroup>
            <FormGroup>
              <Form.Label>Lista de precios DEX</Form.Label>
              <AutocompleteForm
                source={priceLists}
                name="externalPriceListIds"
                isLoading={loadingPriceLists}
                getOptionValue={option => option.priceListCode}
                getOptionLabel={option => option.priceListDescription}
                isMulti
                isInvalid={!!errors.externalPriceListIds}
                aria-label="Lista de precios DEX"
              />
              {errors.externalPriceListIds && (
                <Form.HelperText isInvalid>{errors.externalPriceListIds.message}</Form.HelperText>
              )}
            </FormGroup>
          </>
        )}
      </NavigationTreeFormSection>
    </NavigationTreeFormRowContainer>
  );
};
