import { Button } from '@alicorpdigital/dali-components-react';

interface INavigationTreeFormSubmitButtonProps {
  label: string;
  disabled?: boolean;
}
export const NavigationTreeFormSubmitButton = ({ label, disabled = false }: INavigationTreeFormSubmitButtonProps) => (
  <div className="navigation-tree-form__actions">
    <Button size="md" type="submit" disabled={disabled}>
      {label}
    </Button>
  </div>
);
