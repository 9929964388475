import { UPLOAD_IMAGES_ERROR } from 'core/constants/error-services.constants';
import type { ICustomFile } from 'core/model/interfaces/custom-file';
import { apiService } from 'core/services';
import type { ISuccessfulFile, IUploadFilesParams } from 'core/services/g2m-functions/g2m-functions.dto';

const getImagesToUpload = (images: Array<ICustomFile>) => {
  const indexesToReplace: Array<number> = [];
  const imagesToUpload = images
    .map((image, index) => {
      if (image.blob) {
        indexesToReplace.push(index);
        return image.blob;
      }
      return null;
    })
    .filter((blob): blob is File => blob !== null);

  return { imagesToUpload, indexesToReplace };
};

const getModifiedImages = (
  images: Array<ICustomFile>,
  successfulFiles: Array<ISuccessfulFile>,
  indexesToReplace: Array<number>,
) => {
  const modifiedImages: Array<ICustomFile> = [...images];

  for (let i = 0; i < indexesToReplace.length; i += 1) {
    const indexToReplace = indexesToReplace[i];
    modifiedImages[indexToReplace] = {
      blob: successfulFiles[i].file,
      url: successfulFiles[i].url,
    };
  }

  return modifiedImages;
};

export const uploadImages = async (images: Array<ICustomFile>, context: IUploadFilesParams['context']) => {
  const { imagesToUpload, indexesToReplace } = getImagesToUpload(images);
  if (imagesToUpload.length === 0) {
    return images;
  }

  const { successfulFiles, rejectedFiles } = await apiService.g2mFunctions.uploadFiles({
    file: imagesToUpload,
    context,
  });

  if (rejectedFiles.length !== 0) {
    throw new Error(UPLOAD_IMAGES_ERROR);
  }

  const modifiedImages = getModifiedImages(images, successfulFiles, indexesToReplace);

  return modifiedImages;
};
