import { Navigate } from 'react-router-dom';
import { Button } from '@alicorpdigital/dali-components-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { notification } from '@insuma/mpp-ui/notifications';
import insumaLoginImage from 'assets/insuma-login-image.svg';
import { msalScopes } from 'core/constants/msal.constants';
import { customersRoutePath } from 'core/constants/routes.constants';
import { useAuth } from 'core/hooks/auth/use-auth.hooks';
import { InsumaLogo } from 'shared/components/insuma-logo';

import './login.scss';

const Login = () => {
  const { isAuth } = useAuth();
  const { instance, inProgress } = useMsal();
  const css = useCSS('login');

  const handleMsalLogin = () => {
    triggerLogin();
  };

  const triggerLogin = async () => {
    try {
      await instance.ssoSilent({
        scopes: msalScopes,
      });
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          await instance.loginRedirect({
            scopes: msalScopes,
          });
        } catch (error) {
          notification.error('Error al iniciar sesión');
        }
      } else {
        notification.error('Error inesperado mientras se intentaba iniciar sesión');
      }
    }
  };

  if (inProgress === InteractionStatus.None && !isAuth) {
    triggerLogin();
  }

  if (isAuth) return <Navigate to={customersRoutePath} />;

  return (
    <div className={css()}>
      <div className={css('picture-side')}>
        <img className={css('picture-img')} src={insumaLoginImage} alt="" />
        <p className={css('copy')}>M++ © 2022 Todos los derechos reservados.</p>
      </div>
      <div className={css('form-side')}>
        <div className={css('logo-wrapper')}>
          <InsumaLogo />
        </div>
        <h2 className={css('subtitle')}>Back Office</h2>
        <div className={css('onboarding')}>
          <h4 className={css('onboarding-title')}>¡Te damos la Bienvenida!</h4>
          <p className={css('onboarding-text')}>Ingresa y descubre todos los beneficios que te brindamos</p>
        </div>
        <form className={css('form')}>
          <Button size="lg" type="button" className={css('submit-button')} onClick={handleMsalLogin}>
            Ingresar
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
