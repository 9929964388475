import { ESizeCustomizationModuleImage } from 'core/model/enums/customization.enum';
import { ESuggestedProductView } from 'core/model/enums/suggested-product.enum';
import type { IBannerForm, IBannerListForm } from 'core/model/interfaces/banners';
import type { ICustomFile } from 'core/model/interfaces/custom-file';
import type {
  ICustomizationFormData,
  ICustomizationModule,
  ICustomizationSuggestedProductFormData,
  IDisplayCustomization,
  IShortcutItemFormData,
} from 'core/model/interfaces/customization';
import { createDate, formatDate } from 'shared/utils/date.utils';
import { parseDistributorId, unParseDistributorId } from 'shared/utils/distributors.utils';
import type {
  ICreateCustomizationDTO,
  ICustomizationDetailDTO,
  ICustomizationDTO,
  ICustomizationSuggestedProductConfigDTO,
  IGetCustomizationModuleDetailDTO,
  IModuleDetailDTO,
  IModuleDTO,
  ISuggestedProductConfigurationDTO,
  IUpdateBannerDTO,
  IUpdateShorcutDTO,
} from './customization.dto';
import { extractImageUrl } from './customization.utils';

export const customizationDTOToDisplayCustomization = (customization: ICustomizationDTO): IDisplayCustomization => {
  const { themeId, name, externalDistributors, externalPriceLists } = customization;

  return {
    id: themeId,
    name,
    externalDistributors: externalDistributors.map(ed => ed.description).join(', '),
    externalPriceLists: externalPriceLists.map(ep => ep.description).join(', '),
  };
};

export const moduleDTOToModuleDisplay = (modules: Array<IModuleDTO>): Array<ICustomizationModule> =>
  modules.map(({ themeModuleType, ...rest }) => ({
    customizationModuleType: themeModuleType,
    ...rest,
  }));

export const customizationFormDataToCreateCustomization = (
  customization: ICustomizationFormData,
): ICreateCustomizationDTO => {
  const { name, conditions, externalExcludedCustomers } = customization;

  return {
    name,
    externalDistributors: conditions.externalDistributors?.map(unParseDistributorId) ?? [],
    externalPriceListIds: conditions.externalPriceLists ?? [],
    externalPriceGroupIds: conditions.externalPriceGroups ?? [],
    externalZoneIds: conditions.externalZones ?? [],
    externalCustomerIds: conditions.externalCustomers.split('\n').filter(ec => ec.trim() !== ''),
    externalExcludedCustomerIds: externalExcludedCustomers.split('\n').filter(eec => eec.trim() !== ''),
  };
};

export const customizationDTOToFormData = (customization: ICustomizationDetailDTO): ICustomizationFormData => {
  const {
    name,
    externalDistributors,
    externalPriceGroups,
    externalPriceLists,
    externalCustomers,
    externalExcludedCustomers,
    externalZones,
  } = customization;

  return {
    name,
    conditions: {
      externalDistributors: externalDistributors.map(ed => parseDistributorId(ed.id, ed.externalBranchId)),
      externalPriceLists,
      externalPriceGroups,
      externalZones,
      externalCustomers: externalCustomers?.join('\n') ?? '',
    },
    externalExcludedCustomers: externalExcludedCustomers?.join('\n') ?? '',
  };
};

export const bannerDTOToShortcutFormData = (banner: IModuleDetailDTO): IShortcutItemFormData => {
  const { id, title, urlRedirect, buttonText, images } = banner;

  return {
    id,
    title,
    link: urlRedirect || '',
    buttonName: buttonText || '',
    image: {
      url: images[0]?.url,
    },
  };
};

export const shortcutFormDataToUpdateModuleDetailDTO = (shortcutFormData: IShortcutItemFormData): IUpdateShorcutDTO => {
  const { title, link, buttonName, id, image } = shortcutFormData;

  return {
    id,
    title,
    urlRedirect: btoa(link),
    buttonText: buttonName,
    images: [{ url: extractImageUrl(image) }],
  };
};

export const bannerListDtoToBannerListForm = (moduleResponse: IGetCustomizationModuleDetailDTO): IBannerListForm => {
  const bannerListForm: Array<IBannerForm> = moduleResponse.banners.map(banner => ({
    id: banner.id,
    name: banner.title,
    desktopImage: {
      url: banner.images.find(img => img.size === ESizeCustomizationModuleImage.DESKTOP)?.url || '',
    },
    mobileImage: {
      url: banner.images.find(img => img.size === ESizeCustomizationModuleImage.MOBILE)?.url || '',
    },
    dateRange: [createDate(banner.validFrom), createDate(banner.validUntil)],
    viewOrder: banner.weight || 0,
    link: banner.urlRedirect,
    isEditing: false,
    isNew: false,
  }));

  return { banners: bannerListForm };
};

export const bannerListFormToBannerListRequest = (
  bannerList: IBannerListForm,
  uploadedImages: Array<ICustomFile>,
): Array<IUpdateBannerDTO> => {
  const bannerListForm = bannerList.banners;

  // TODO: make a refactor for setting uploadedImages in images
  const bannerListRequest: Array<IUpdateBannerDTO> = bannerListForm.map((bannerForm, index) => {
    if (!bannerForm.dateRange || !bannerForm.viewOrder)
      throw new Error('Date range, viewOrder should not be undefined or null');
    return {
      id: bannerForm.id,
      title: bannerForm.name,
      urlRedirect: bannerForm.link ? btoa(bannerForm.link) : undefined,
      validFrom: formatDate(bannerForm.dateRange[0]),
      validUntil: formatDate(bannerForm.dateRange[1]),
      weight: bannerForm.viewOrder,

      // In the case of banners, two images are required for each item (desktop and mobile),
      // so in each iteration two consecutive uploadImages are taken with index*2 and index*2+1
      images: [
        {
          url: extractImageUrl(uploadedImages[index * 2]),
          size: ESizeCustomizationModuleImage.DESKTOP,
        },
        {
          url: extractImageUrl(uploadedImages[index * 2 + 1]),
          size: ESizeCustomizationModuleImage.MOBILE,
        },
      ],
    };
  });

  return bannerListRequest;
};

export const configSuggestedProductDTOToSuggestedProductFormData = (
  config: ICustomizationSuggestedProductConfigDTO,
): ICustomizationSuggestedProductFormData => ({
  ...config,
});

export const suggestedProductFormDataToSuggestedProductConfigurationDTO = (
  suggestedProductFormData: ICustomizationSuggestedProductFormData,
): ISuggestedProductConfigurationDTO => ({
  viewId: ESuggestedProductView.HOMEPAGE,
  config: [
    {
      ...suggestedProductFormData,
    },
  ],
});
