import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { exportNotification } from 'core/constants/exports.constants';
import { ordersRoutePath } from 'core/constants/routes.constants';
import { EExportListType } from 'core/model/enums/exports.enum';
import { apiService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'core/store';
import { fetchOrders } from 'core/store/orders';
import { formatDate } from 'shared/utils/date.utils';
import { handleErrorMessage } from 'shared/utils/errors.utils';

export const useOrdersList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orders = [], isLoading, totalPages, currentPage } = useAppSelector(state => state.orders);

  const handleChangePage = (page: number) => {
    dispatch(fetchOrders({ page }));
  };

  const handleViewClick = (orderId: number) => {
    navigate(`${ordersRoutePath}/detalle/${orderId}`);
  };

  const handleExportAgree = async (fileName: string, dateRange?: [Date, Date]) => {
    if (!dateRange) throw new Error('date range should not be undefined or null');
    try {
      await apiService.exports.createFileExport({
        fileName,
        exportListTypeId: EExportListType.ORDER,
        metadata: {
          startDate: formatDate(dateRange[0]),
          endDate: formatDate(dateRange[1]),
        },
      });
      exportNotification(navigate);
    } catch (error) {
      notification.error(handleErrorMessage(error));
    }
  };

  useEffect(() => {
    dispatch(fetchOrders({}));
  }, [dispatch]);

  return {
    orders,
    handleChangePage,
    isLoading,
    totalPages,
    currentPage,
    handleViewClick,
    handleExportAgree,
  };
};
