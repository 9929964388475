import type { TCustomersFilters } from 'core/model/interfaces/customers';
import type { TSliceReducer } from 'core/store';
import type { ICustomersState } from './types';

const setFiltersApplied: TSliceReducer<ICustomersState, boolean> = (state, action) => {
  state.areFiltersApplied = action.payload;
};

const updateCustomerFilter: TSliceReducer<ICustomersState, Partial<TCustomersFilters>> = (state, action) => {
  state.filters = {
    ...state.filters,
    ...action.payload,
  };
};

export const reducers = {
  updateCustomerFilter,
  setFiltersApplied,
};
