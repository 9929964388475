import { ReactNode } from 'react';
import { Button } from '@alicorpdigital/dali-components-react';
import { Icon } from '@insuma/mpp-ui/components/icon';

import './file-input-area.scss';

interface IFileInputAreaProps {
  className?: string;
  setFile: (file: File) => void;
  accept?: string;
  file: File | null;
  filePickerHelperText: ReactNode;
}

export const FileInputArea = ({ className = '', setFile, accept, file, filePickerHelperText }: IFileInputAreaProps) => {
  const turnOffDefaultBehaivor = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const importHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      setFile(file);
    }
  };
  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    turnOffDefaultBehaivor(event);
    const { files } = event.dataTransfer;
    if (files && files.length > 0) {
      const file = files[0];
      setFile(file);
    }
  };

  return (
    <div
      className={`${className} file-input-area`}
      onDrop={dropHandler}
      onDragEnter={turnOffDefaultBehaivor}
      onDragLeave={turnOffDefaultBehaivor}
      onDragOver={turnOffDefaultBehaivor}
    >
      <input className="file-input-area__input" onChange={importHandler} type="file" name="file" accept={accept} />
      <div className="file-input-area__container">
        <div className="file-input-area__icon-container">
          <Icon className="file-input-area__icon" name="upload" size="lg" />
        </div>
        <div>
          <Button className="file-input-area__action-button" size="sm" variant="tertiary">
            {file ? <span>{file.name}</span> : <span>Selecciona</span>}
          </Button>
          <span> o arrastra tu archivo</span>
          <div className="file-input-area__subtitle">{filePickerHelperText}</div>
        </div>
      </div>
    </div>
  );
};
