import { Route, Routes } from 'react-router-dom';
import { CreateStaticPage } from './static-pages-create';
import { EditStaticPage } from './static-pages-edit';
import { StaticPagesList } from './static-pages-list';

const StaticPagesRoutes = () => (
  <Routes>
    <Route path="/" element={<StaticPagesList />} />
    <Route path="/crear" element={<CreateStaticPage />} />
    <Route path="/editar/:id" element={<EditStaticPage />} />
  </Routes>
);

export default StaticPagesRoutes;
