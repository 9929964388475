import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { IPriceGroup } from 'core/model/interfaces/master-entities';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const usePricesGroup = () => {
  const [pricesGroupList, setPricesGroupList] = useState<Array<IPriceGroup>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchPriceGroup = async () => {
      setLoading(true);

      try {
        const priceGroupsLists = await apiService.masterEntities.getPricesGroup();
        setPricesGroupList(priceGroupsLists);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo las opciones de grupo de precios'));
      } finally {
        setLoading(false);
      }
    };

    fetchPriceGroup();

    return () => {
      setPricesGroupList([]);
    };
  }, []);

  return { pricesGroupList, loading, error };
};
