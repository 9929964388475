import { ReactNode } from 'react';

import './customer-form-list.scss';

interface ICustomerFormListProps {
  children: ReactNode;
}

export const CustomerFormList = ({ children }: ICustomerFormListProps) => (
  <div className="customer-form-list customer-form-list--disabled">{children}</div>
);
