import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { ICustomerForm } from 'core/model/interfaces/customers';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

const useCustomerDetail = () => {
  const { id: customerId } = useParams();
  const [customer, setCustomer] = useState<ICustomerForm | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        setLoading(true);
        const data = await apiService.customer.getCustomerById(Number(customerId));
        setCustomer(data);
      } catch (err) {
        setError(err);
        notification.error(getErrorFromService(err, 'Error cargando el detalle de cliente'));
      } finally {
        setLoading(false);
      }
    };

    fetchCustomer();
  }, [customerId]);

  return { customer, loading, error };
};

export default useCustomerDetail;
