import { Button } from '@alicorpdigital/dali-components-react';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import Drag from 'assets/drag.svg';

import './subcategory-list-item.scss';

interface ISubcategoryListItemProps {
  subcategory: any;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
  isDeleteDisabled?: boolean;
  onDelete?(id: string): void;
  onEdit?(): void;
}
export const SubcategoryListItem = ({
  subcategory,
  attributes,
  listeners,
  onDelete,
  onEdit,
  isDeleteDisabled = false,
}: ISubcategoryListItemProps) => (
  <div className="subcategory-list-item">
    <div className="subcategory-list-item__card">
      <img src={Drag} alt="" {...attributes} {...listeners} />
      <span className="subcategory-list-item__title">{subcategory.name}</span>
      <div className="subcategory-list-item__actions">
        <Button iconName="pencil" isIconOnly variant="tertiary" onClick={() => onEdit && onEdit()} />
        <Button
          iconName="trash"
          isIconOnly
          variant="tertiary"
          onClick={() => onDelete && onDelete(subcategory.guid)}
          isDisabled={isDeleteDisabled}
        />
      </div>
    </div>
  </div>
);
