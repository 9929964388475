import { Route, Routes } from 'react-router-dom';
import { OrderDetail } from './order-detail';
import { OrdersList } from './orders-list';

export const ordersPath = '/orders';

const OrdersRoutes = () => (
  <Routes>
    <Route path="/" element={<OrdersList />} />
    <Route path="/detalle/:id" element={<OrderDetail />} />
  </Routes>
);

export default OrdersRoutes;
