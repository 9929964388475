import { ImageRoutes } from 'core/constants/image-routes.constants';
import { EModuleType } from 'core/model/enums/static-pages.enum';

export const getReferenceImagesByModule = (moduleType: EModuleType): Array<string> => {
  switch (moduleType) {
    case EModuleType.BANNER:
      return [ImageRoutes.BANNER_REFERENCE];
    case EModuleType.SHORTCUT:
      return [ImageRoutes.SHORTCUT_REFERENCE];
    case EModuleType.SUGGESTED_PRODUCT:
      return [ImageRoutes.SUGGESTED_PRODUCT_REFERENCE];
    default:
      return [];
  }
};

export const getPathToModuleList = (type: EModuleType) => {
  switch (type) {
    case EModuleType.BANNER:
      return 'banners';
    case EModuleType.SHORTCUT:
      return 'shortcuts';
    case EModuleType.SUGGESTED_PRODUCT:
      return 'productosSugeridos';
    default:
  }
};
