import { get, useFormContext } from 'react-hook-form';
import { Form, FormGroup } from '@alicorpdigital/dali-components-react';
import { HIERARCHY_ASSOCIATION_TYPES } from 'core/constants/hierarchy.constants';
import { useMasterFamilies } from 'core/hooks/master-entities';
import { EHierarchyAssociation } from 'core/model/enums/hierarchy.enum';
import { INavigationTreeDetailCreateForm } from 'core/model/interfaces/navigation';
import { AutocompleteForm, DragAndDropImageUploaderForm, InputValueForm } from 'shared/components/forms';
import { NavigationTreeFormRowContainer } from '../navigation-tree-form-row-container';
import { NavigationTreeFormSection } from '../navigation-tree-form-section';
import { NavigationTreeFormSubtitle } from '../navigation-tree-form-subtitle';

import './navigation-tree-form-category-fields.scss';

export const NavigationTreeFormCategoryFields = () => {
  const { familiesList, loading: loadingFamilies } = useMasterFamilies({ enabled: true });

  const {
    trigger,
    watch,
    formState: { errors },
    register,
  } = useFormContext<INavigationTreeDetailCreateForm>();
  const associationType = watch('associationType');

  return (
    <div className="navigation-tree-form-category-fields">
      <NavigationTreeFormRowContainer>
        <NavigationTreeFormSubtitle text="2. Estructura del árbol" />
        <NavigationTreeFormSection>
          <div>
            <InputValueForm
              label="Nombre de la categoría"
              name="categoryName"
              onChange={() => trigger('categoryName')}
            />
            <InputValueForm
              label="Nombre de la subcategoría"
              name="subcategoryName"
              onChange={() => trigger('subcategoryName')}
            />
            <FormGroup>
              <Form.Label>Tipo de Asociación</Form.Label>
              <AutocompleteForm
                source={HIERARCHY_ASSOCIATION_TYPES}
                name="associationType"
                getOptionValue={option => option.id}
                getOptionLabel={option => option.content}
                isInvalid={!!errors.associationType}
                isSearchable={false}
                isClearable={false}
                aria-label="Tipo de Asociación"
              />
              {errors.associationType && <Form.HelperText isInvalid>{errors.associationType.message}</Form.HelperText>}
            </FormGroup>
            {associationType === EHierarchyAssociation.BY_FAMILIES && (
              <FormGroup>
                <Form.Label>Familias asociadas a la subcategoría</Form.Label>
                <AutocompleteForm
                  source={familiesList}
                  name="families"
                  isLoading={loadingFamilies}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.description}
                  isMulti
                  isInvalid={!!errors.families}
                  placeholder="Selecciona las familias"
                  aria-label="Familias asociadas a la subcategoría"
                />
                {errors.families && <Form.HelperText isInvalid>{errors.families.message}</Form.HelperText>}
              </FormGroup>
            )}
            {associationType === EHierarchyAssociation.BY_SKUS && (
              <FormGroup>
                <Form.Label>SKUs asociados a la subcategoría</Form.Label>
                <Form.Textarea
                  {...register('skus')}
                  isInvalid={!!errors.skus}
                  placeholder="Escribe los SKU"
                  aria-label="SKUs asociados a la subcategoría"
                />
                {errors.skus && <Form.HelperText isInvalid>{errors.skus.message}</Form.HelperText>}
              </FormGroup>
            )}
          </div>
          <div>
            <FormGroup className="navigation-tree-form-category-fields__icon-section">
              <Form.Label>Icono</Form.Label>
              <DragAndDropImageUploaderForm name="categoryIcon" maxSize={500} />
              {errors.categoryIcon && (
                <Form.HelperText isInvalid>{get(errors, 'categoryIcon.blob.message')}</Form.HelperText>
              )}
            </FormGroup>
          </div>
        </NavigationTreeFormSection>
      </NavigationTreeFormRowContainer>
    </div>
  );
};
