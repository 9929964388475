import { EHierarchyAssociation } from 'core/model/enums/hierarchy.enum';
import {
  IHierarchyTree,
  IHierarchyTreeCategoryCreateForm,
  IHierarchyTreeCategoryEditForm,
  IHierarchyTreeSubcategory,
} from 'core/model/interfaces/navigation/hierarchy.interface';
import {
  IGetHierarchyTreeResponse,
  IPostHierarchyTreeCategoryBody,
  IPostHierarchyTreeSubcategoryBody,
  IPutHierarchyTreeCategoryBody,
  IPutHierarchyTreeSubcategoryBody,
} from './hierarchy.dto';
import { getHierarchyAssociation } from './hierarchy.utils';

export const mapTreeHierarchy = (treeResponse: IGetHierarchyTreeResponse): IHierarchyTree => ({
  state: treeResponse.status,
  id: treeResponse.treeId,
  name: treeResponse.name,
  categories: treeResponse.items.map(category => ({
    treeId: treeResponse.treeId,
    collapsed: true,
    id: `${category.itemId}`,
    iconUrl: category.image,
    image: { url: category.image },
    name: category.name,
    subcategories: category.items.map(subcategory => ({
      id: `${subcategory.itemId}`,
      name: subcategory.name,
      families: subcategory.externalProductFamilyIds,
      skus: subcategory.externalProductIds ? subcategory.externalProductIds.join('\n') : undefined,
      associationType: subcategory.externalProductFamilyIds
        ? EHierarchyAssociation.BY_FAMILIES
        : EHierarchyAssociation.BY_SKUS,
      treeId: treeResponse.treeId,
      categoryId: `${category.itemId}`,
    })),
  })),
});

export const toPostHierarchyTreeCategoryBody = (
  body: IHierarchyTreeCategoryCreateForm,
  imageUrl: string,
): IPostHierarchyTreeCategoryBody => ({
  name: body.name,
  image: imageUrl,
  items: [
    {
      name: body.subcategoryName,
      ...getHierarchyAssociation(body.associationType, body.families, body.skus),
    },
  ],
  treeId: body.treeId,
});

export const toPutHierarchyTreeCategoryBody = (
  body: IHierarchyTreeCategoryEditForm,
): IPutHierarchyTreeCategoryBody => ({
  name: body.name,
  image: body.iconUrl !== '' ? body.iconUrl : undefined,
  treeId: body.treeId,
  categoryId: body.id,
});

export const toPostHierarchyTreeSubcategoryBody = (
  body: Omit<IHierarchyTreeSubcategory, 'id'>,
): IPostHierarchyTreeSubcategoryBody => ({
  name: body.name,
  ...getHierarchyAssociation(body.associationType, body.families, body.skus),
  treeId: body.treeId,
  categoryId: body.categoryId,
});

export const toPutHierarchyTreeSubcategoryBody = (
  body: IHierarchyTreeSubcategory,
): IPutHierarchyTreeSubcategoryBody => ({
  name: body.name,
  ...getHierarchyAssociation(body.associationType, body.families, body.skus),
  treeId: body.treeId,
  categoryId: body.categoryId,
  itemId: body.id,
});
