import { INavItem, SideNavListItem } from '../side-nav-list-item';

import './side-nav-list.scss';

interface ISideNavListProps {
  items: Array<INavItem>;
  currentPath: string;
}

export const SideNavList = ({ items, currentPath }: ISideNavListProps) => (
  <ul className="sidenav-list">
    {items.map((item, index) => (
      <li key={`nav-item-${index}`}>
        <SideNavListItem currentPath={currentPath} item={item} />
      </li>
    ))}
  </ul>
);
