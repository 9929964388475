import type { IStaticPageFormData } from './schema';

export const defaultStaticPage: IStaticPageFormData = {
  content: '',
  title: '',
  slug: '',
  images: [],
  active: true,
  isPublic: false,
  trees: [],
  inFooterEnabled: false,
  inSidebarEnabled: false,
  inNavigationTree: false,
};
