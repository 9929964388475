import { EHierarchyAssociation } from 'core/model/enums/hierarchy.enum';

export const getHierarchyAssociation = (
  associationType: EHierarchyAssociation,
  families: Array<string> | undefined,
  skus: string | undefined,
) => ({
  externalProductFamilyIds: associationType === EHierarchyAssociation.BY_FAMILIES ? families : undefined,
  externalProductIds:
    associationType === EHierarchyAssociation.BY_SKUS ? skus?.split('\n').filter(ec => ec.trim() !== '') : undefined,
});
