import { Dropdown, DropdownItemType } from '@alicorpdigital/dali-components-react';
import { Table } from 'shared/components/table';

import './delivery-holidays-table.scss';

interface IDeliveryHolidaysTableProps {
  value: number;
  onChange: (value: number) => void;
}

// TODO: Replace for MPPUI Table
export const DeliveryHolidaysTable = ({ value, onChange }: IDeliveryHolidaysTableProps) => {
  const items: Array<DropdownItemType> = [
    { id: '1', content: '+1 Día' },
    { id: '2', content: '+2 Días' },
    { id: '3', content: '+3 Días' },
    { id: '4', content: '+4 Días' },
    { id: '5', content: '+5 Días' },
    { id: '6', content: '+6 Días' },
    { id: '7', content: '+7 Días' },
  ];
  const customOnChange = (item: DropdownItemType) => {
    onChange(+item.id);
  };
  return (
    <Table className="delivery-holidays-table">
      <colgroup>
        <col className="delivery-holidays-table__first-column" />
        <col className="delivery-holidays-table__second-column" />
      </colgroup>
      <thead>
        <tr className="delivery-holidays-table__row">
          <th>{}</th>
          <th className="delivery-holidays-table__title">Tiempo de entrega</th>
        </tr>
      </thead>
      <tbody>
        <tr className="delivery-holidays-table__row">
          <th>Festivos</th>
          <td className="delivery-holidays-table__input">
            <Dropdown
              items={items}
              selectedItem={{ id: value.toString(), content: value === 1 ? '+1 Día' : `+${value} Días` }}
              handleSelectedItemChange={({ selectedItem }) => {
                if (selectedItem) return customOnChange(selectedItem);
              }}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
