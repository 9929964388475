import axios from 'axios';
import { API_BASE_URL, COMMON_HEADERS } from './constants';
import { injectHeaders } from './interceptors.utils';

export const https = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    ...COMMON_HEADERS,
    'Content-Type': 'application/json',
  },
});

export const httpsMultipart = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    ...COMMON_HEADERS,
    'Content-Type': 'multipart/form-data',
  },
});

export const httpBlobStorage = axios.create({
  headers: {
    'x-ms-blob-type': 'BlockBlob',
  },
});

https.interceptors.request.use(injectHeaders);
httpsMultipart.interceptors.request.use(injectHeaders);

export const setToken = (token: string) => {
  https.defaults.headers.Authorization = `Bearer ${token}`;
  httpsMultipart.defaults.headers.Authorization = `Bearer ${token}`;
};
