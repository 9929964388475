import * as yup from 'yup';
import {
  numberFieldMaxError,
  numberFieldMinError,
  PRODUCT_AT_LEAST_ONE_TYPE_SELECTED,
  TEXT_FIELD_REQUIRED_ERROR,
  TEXT_NUMBER_INT,
  TEXT_NUMBER_TYPO_ERROR,
} from 'core/constants/error-messages.constants';

interface ISuggestionSection {
  state: boolean;
  title: string;
  maxSuggestions: number;
}

interface IInternalPage {
  unforgivables?: Partial<ISuggestionSection>;
  enhancers?: Partial<ISuggestionSection>;
  idealSelection?: Partial<ISuggestionSection>;
}

const isTypeValid = (field: string) =>
  yup.string().when(['state'], {
    is: true,
    then: schema => schema.required(`Opción para ${field}`).nullable(),
  });

const isMaxSuggestionValid = () =>
  yup
    .number()
    .typeError(TEXT_NUMBER_TYPO_ERROR)
    .nullable()
    .transform((_, value) => (value === null || value === '' ? null : Number(value)))
    .min(1, numberFieldMinError(1))
    .max(50, numberFieldMaxError(50))
    .integer(TEXT_NUMBER_INT)
    .when(['state'], {
      is: true,
      then: schema =>
        schema
          .typeError(TEXT_NUMBER_TYPO_ERROR)
          .test('maxSuggestions', TEXT_NUMBER_INT, name => Number.isInteger(name))
          .required(TEXT_FIELD_REQUIRED_ERROR),
    });

const isTitleValid = () =>
  yup
    .string()
    .nullable()
    .when(['state'], {
      is: true,
      then: schema => schema.required(TEXT_FIELD_REQUIRED_ERROR),
    });

const suggestionSection = () => ({
  state: yup.boolean(),
  title: isTitleValid(),
  maxSuggestions: isMaxSuggestionValid(),
});

const atLeastOneTypeSelected = (suggestion: IInternalPage) => {
  if (suggestion.unforgivables?.state || suggestion.enhancers?.state || suggestion.idealSelection?.state) {
    return true;
  }
  return new yup.ValidationError(PRODUCT_AT_LEAST_ONE_TYPE_SELECTED, null, 'internalPage.atLeastOneType');
};

export const schema = yup.object().shape({
  internalPage: yup
    .object()
    .shape({
      unforgivables: yup.object().shape({
        ...suggestionSection(),
      }),
      enhancers: yup.object().shape({
        ...suggestionSection(),
      }),
      idealSelection: yup.object().shape({
        ...suggestionSection(),
      }),
    })
    .test('atLeastOneType', null, atLeastOneTypeSelected),
  shoppingCart: yup.object().shape({
    ...suggestionSection(),
    type: isTypeValid('carrito'),
  }),
  homepage: yup.object().shape({
    ...suggestionSection(),
    type: isTypeValid('homepage'),
  }),
  fixedBanner: yup.object().shape({
    state: yup.boolean(),
    title: isTitleValid(),
  }),
  internalPageId: yup.number(),
  shoppingCartId: yup.number(),
  homepageId: yup.number(),
  fixedBannerId: yup.number(),
  templateUrl: yup.string(),
  exportUrl: yup.string(),
});
