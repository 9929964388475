import { useParams } from 'react-router-dom';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { customizationRoutePath } from 'core/constants/routes.constants';
import { useModules } from 'screens/customization/hooks/use-modules.hooks';
import { Link } from 'shared/components/link';
import { Loading } from 'shared/components/loading';
import { getReferenceImagesByModule } from '../customization-module.utils';
import { ModuleItem } from './components/module-item/module-item';

import './customization-module-list.scss';

export const CustomizationModuleList = () => {
  const css = useCSS('customization-module');
  const { customizationId } = useParams();
  const { moduleList, loading: loadingModules } = useModules(Number(customizationId));

  return (
    <div className={css()}>
      <Link icon="caret-left" to={`${customizationRoutePath}/`}>
        Atrás
      </Link>
      <h2 className={css('title')}>Personalización</h2>

      {loadingModules ? (
        <Loading />
      ) : (
        <div className={css('list')}>
          {moduleList.map(module => (
            <ModuleItem
              key={module.id}
              id={module.id}
              customizationId={Number(customizationId)}
              title={module.name}
              active={module.active}
              images={getReferenceImagesByModule(module.customizationModuleType)}
              customizationModuleType={module.customizationModuleType}
            />
          ))}
        </div>
      )}
    </div>
  );
};
