import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { productsRoutePath } from 'core/constants/routes.constants';
import type { IProductDetail } from 'core/model/interfaces/products';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';
import type { IProductFormData } from '../product-form/product-form.schema';

export const useProductView = (sku?: string) => {
  const [remoteData, setRemoteData] = useState<IProductDetail | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const onSubmit = async (data: IProductFormData) => {
    if (!sku) throw Error('Sku should not be undefined or null');
    try {
      setIsSaving(true);
      await apiService.products.updateProduct(sku, data);
      notification.success('La información se ha guardado exitosamente');
      navigate(productsRoutePath);
    } catch (error) {
      notification.error('No se pudo guardar la información. Por favor, intente nuevamente');
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const getProductBySku = async () => {
      if (!sku) throw Error('Sku should not be undefined or null');
      try {
        setLoading(true);
        const productData = await apiService.products.getProductById(sku);
        if (productData) {
          setRemoteData(productData);
        } else {
          notification.error('Error obteniendo el detalle del producto');
        }
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo el detalle del producto'));
      } finally {
        setLoading(false);
      }
    };

    getProductBySku();
  }, [sku]);

  return {
    remoteData,
    loading,
    onSubmit,
    isSaving,
    error,
  };
};
