import { useNavigate } from 'react-router-dom';
import { Button } from '@alicorpdigital/dali-components-react';
import { groupingRulesRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import type { TGroupingRuleItemListDisplay } from 'core/model/interfaces/grouping-rules';
import { groupingRulesService } from 'core/services/grouping-rules/grouping-rules.service';
import { Loading } from 'shared/components/loading';
import { getOrderedItemsToService } from '../grouping-rules.utils';
import { useGroupingRules } from '../hooks/use-grouping-rules.hooks';
import { SortableList } from './sortable-list/sortable-list';

import './grouping-rules-list.scss';

export const GroupingRulesList = () => {
  const { groupingRuleList, setGroupingRuleList, loading } = useGroupingRules();
  const navigate = useNavigate();

  const handleItemsChange = async (items: Array<TGroupingRuleItemListDisplay>) => {
    setGroupingRuleList(items);
    const orderedItems = getOrderedItemsToService(items);
    await groupingRulesService.updatePriority(orderedItems);
  };

  return (
    <div className="grouping-rules-list">
      <h2 className="grouping-rules-list__title">Regla de agrupación</h2>
      <p className="grouping-rules-list__description">
        Condiciones por las cuales se agruparán los productos en paquetes de entrega. En caso de que un producto
        coincida con más de una regla, éste será agrupado en la regla más prioritaria.
      </p>
      <div className="grouping-rules-list__actions">
        <Button
          hasIcon="left"
          iconName="plus"
          variant="primary"
          size="sm"
          onClick={() => navigate(`${logisticsRoutePath}${groupingRulesRoutePath}/crear`)}
        >
          Nueva agrupación DEX
        </Button>
      </div>
      <div>{loading ? <Loading /> : <SortableList items={groupingRuleList} onChange={handleItemsChange} />}</div>
    </div>
  );
};
