import * as yup from 'yup';
import { BIG_FILE_ERROR, FILE_FORMAT_ERROR, FILE_REQUIRED_ERROR } from 'core/constants/import-modal.constants';

const isValidFileType = (fileName: string, fileExtension: string): boolean =>
  fileName?.split('.')?.pop() === fileExtension;

export const getImportModalFormSchema = (maxFileSize: number, fileExtension: string) =>
  yup.object().shape({
    file: yup
      .mixed<File>()
      .required(FILE_REQUIRED_ERROR)
      .test('is-valid-size', BIG_FILE_ERROR, value => value && value.size <= maxFileSize)
      .test('is-valid-type', FILE_FORMAT_ERROR, value =>
        isValidFileType(value && value.name.toLowerCase(), fileExtension),
      ),
  });
