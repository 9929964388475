import * as yup from 'yup';
import {
  NO_SPECIAL_CHARACTERS_ERROR,
  TEXT_FIELD_REQUIRED_ERROR,
  textFieldMaxError,
} from 'core/constants/error-messages.constants';
import { NO_SPECIAL_CHARACTER_REGEX } from 'core/constants/regex.constant';
import type { IDistributor } from 'core/model/interfaces/distributors';
import type { IPriceList } from 'core/model/interfaces/master-entities';

export const basicSchema = {
  name: yup
    .string()
    .trim()
    .required(TEXT_FIELD_REQUIRED_ERROR)
    .max(200, textFieldMaxError(200))
    .matches(NO_SPECIAL_CHARACTER_REGEX, NO_SPECIAL_CHARACTERS_ERROR),
  externalDistributors: yup.array().when('externalPriceListIds', {
    is: (externalPriceListIds: Array<IPriceList>) => !externalPriceListIds || externalPriceListIds.length === 0,
    then: schema =>
      schema
        .of(yup.string())
        .min(1, 'Este campo es requerido si no selecciona alguna lista de precios.')
        .required(TEXT_FIELD_REQUIRED_ERROR),
    otherwise: schema => schema,
  }),
  externalPriceListIds: yup.array().when('externalDistributors', {
    is: (externalDistributors: Array<IDistributor>) => !externalDistributors || externalDistributors.length === 0,
    then: schema =>
      schema
        .of(yup.string())
        .min(1, 'Este campo es requerido si no selecciona algún operador')
        .required(TEXT_FIELD_REQUIRED_ERROR),
    otherwise: schema => schema,
  }),
};
