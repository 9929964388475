export enum EPaymentMethodType {
  TRANSFERENCIA = 'Transferencia',
  EFECTIVO = 'Efectivo',
  PAGO_CUOTAS = 'Pago en cuotas',
}

export enum EOrderStatus {
  SUCCESSFUL = 'Aceptado',
  REJECTED = 'Rechazado',
}
