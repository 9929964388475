import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from '@alicorpdigital/dali-components-react';
import type { IBannerListForm } from 'core/model/interfaces/banners';

import './banner-view.scss';

interface IBannerViewProps {
  index: number;
  onEditClick(index: number): void;
  onDeleteClick(index: number): void;
  isActionsDisabled: boolean;
}

export const BannerView = ({ index, onDeleteClick, onEditClick, isActionsDisabled }: IBannerViewProps) => {
  const { control } = useFormContext<IBannerListForm>();

  const banner = useWatch<IBannerListForm, 'banners'>({
    control,
    name: 'banners',
  })[index];

  return (
    <div className="banner-view">
      <div className="banner-view__info">
        <img src={banner.desktopImage.url} alt="Imagen" width={60} />
        <span>{banner.name}</span>
      </div>
      <div className="banner-view__actions">
        <Button
          type="button"
          iconName="pencil"
          hasIcon="left"
          variant="tertiary"
          disabled={isActionsDisabled}
          onClick={() => onEditClick(index)}
        >
          Editar
        </Button>

        <Button
          type="button"
          iconName="trash"
          hasIcon="left"
          variant="tertiary"
          disabled={isActionsDisabled}
          onClick={() => onDeleteClick(index)}
        >
          Eliminar
        </Button>
      </div>
    </div>
  );
};
