import {
  IHierarchyTreeCategoryCreateForm,
  IHierarchyTreeCategoryEditForm,
  IHierarchyTreeSubcategory,
} from 'core/model/interfaces/navigation/hierarchy.interface';
import { g2mFunctionsService } from '../g2m-functions/g2m-functions.service';
import { hierarchyData } from './hierarchy.data';
import { IPatchHierarchyTreeItemsWeight, IPutHierarchyTreeCategoryBody } from './hierarchy.dto';
import {
  mapTreeHierarchy,
  toPostHierarchyTreeCategoryBody,
  toPostHierarchyTreeSubcategoryBody,
  toPutHierarchyTreeCategoryBody,
  toPutHierarchyTreeSubcategoryBody,
} from './hierarchy.mapper';

export const hierarchyService = {
  getHierarchyTree: async (id: string) => mapTreeHierarchy(await hierarchyData.getHierarchyTree(id)),

  createHierachyCategory: async (body: IHierarchyTreeCategoryCreateForm) => {
    if (body.image.blob) {
      const { rejectedFiles, successfulFiles } = await g2mFunctionsService.uploadFiles({
        file: body.image.blob,
        context: 'icons',
      });
      if (rejectedFiles.length !== 0) {
        throw new Error('Error subiendo la imagen');
      }
      const mappedBody = toPostHierarchyTreeCategoryBody(body, successfulFiles[0].url);
      return hierarchyData.createHierachyCategory(mappedBody);
    }
  },

  editHierachyCategory: async (body: IHierarchyTreeCategoryEditForm) => {
    let mappedBody: IPutHierarchyTreeCategoryBody;
    if (body.image?.blob) {
      const { rejectedFiles, successfulFiles } = await g2mFunctionsService.uploadFiles({
        file: body.image.blob,
        context: 'icons',
      });
      if (rejectedFiles.length !== 0) {
        throw new Error('Error subiendo la imagen');
      }
      mappedBody = toPutHierarchyTreeCategoryBody({ ...body, iconUrl: successfulFiles[0].url });
    } else {
      mappedBody = toPutHierarchyTreeCategoryBody({ ...body, iconUrl: '' });
    }
    return hierarchyData.editHierachyCategory(mappedBody);
  },

  createHierachySubcategory: (body: Omit<IHierarchyTreeSubcategory, 'id'>) => {
    const mappedBody = toPostHierarchyTreeSubcategoryBody(body);
    return hierarchyData.createHierachySubcategory(mappedBody);
  },

  editHierachySubcategory: (body: IHierarchyTreeSubcategory) => {
    const mappedBody = toPutHierarchyTreeSubcategoryBody(body);
    return hierarchyData.editHierachySubcategory(mappedBody);
  },

  deleteHierarchyItem: (categoryGuid: string, treeGuid: string) =>
    hierarchyData.deleteHierarchyItem(categoryGuid, treeGuid),

  publishHierachyTrees: (id: string) => hierarchyData.publishHierachyTrees(id),

  updateHierarchyItemsWeight: (body: IPatchHierarchyTreeItemsWeight) => hierarchyData.updateHierarchyItemsWeight(body),

  getFamiliesForHierarchyTree: (id: string) => hierarchyData.getFamiliesForHierarchyTree(id),
};
