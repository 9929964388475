import { Tabs } from '@insuma/mpp-ui/components/tabs';
import type { ICustomerDeliveryAddress } from 'core/model/interfaces/customers';
import DeliveryAddressItem from './customer-address-item';

interface ICustomerDeliveryAddressesProps {
  deliveryAddresses: Array<ICustomerDeliveryAddress>;
}
export const CustomerDeliveryAddresses = ({ deliveryAddresses }: ICustomerDeliveryAddressesProps) => (
  <Tabs defaultTab={0}>
    <Tabs.List>
      {deliveryAddresses.map((item, index) => (
        <Tabs.Tab
          key={`${item.apudexId}-${item.dexId}`}
          value={index}
          title={`Dirección ${index === 0 ? 'principal' : index + 1}`}
          iconName="map-pin"
        />
      ))}
    </Tabs.List>
    {deliveryAddresses?.map((item, index) => (
      <Tabs.Panel key={`${item.apudexId}-${item.dexId}`} value={index}>
        <DeliveryAddressItem address={item} />
      </Tabs.Panel>
    ))}
  </Tabs>
);
