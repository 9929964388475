import type { IStaticPagesState } from './types';

export const SLICE_NAMESPACE = 'static-pages';

export const initialState: IStaticPagesState = {
  isPagesLoading: false,
  staticPages: [],
  currentPage: 1,
  totalPages: 0,
  filters: {
    title: '',
  },
};
