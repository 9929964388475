import { useState } from 'react';
import {
  IHierarchyTreeCategory,
  IHierarchyTreeSubcategory,
} from 'core/model/interfaces/navigation/hierarchy.interface';

interface IUseHierarchyDeleteModalProps {
  setSelectedCategory: React.Dispatch<React.SetStateAction<IHierarchyTreeCategory | null>>;
  setSelectedSubcategory: React.Dispatch<React.SetStateAction<IHierarchyTreeSubcategory | null>>;
}
export const useHierarchyDeleteModal = ({
  setSelectedCategory,
  setSelectedSubcategory,
}: IUseHierarchyDeleteModalProps) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const closeDeleteItemModal = () => {
    setDeleteModalOpen(false);
    setSelectedCategory(null);
    setSelectedSubcategory(null);
  };

  const openDeleteCategoryModal = (item: IHierarchyTreeCategory) => {
    setDeleteModalOpen(true);
    setSelectedCategory(item);
  };

  const openDeleteSubcategoryModal = (item: IHierarchyTreeSubcategory) => {
    setDeleteModalOpen(true);
    setSelectedSubcategory(item);
  };

  return {
    closeDeleteItemModal,
    openDeleteCategoryModal,
    openDeleteSubcategoryModal,
    isDeleteModalOpen,
  };
};
