import { Controller, useForm } from 'react-hook-form';
import { Button, Form } from '@alicorpdigital/dali-components-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker } from '@insuma/mpp-ui/components/form';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { FIELD_REQUIRED_ERROR } from 'core/constants/import-modal.constants';
import { Modal } from 'shared/components/modal';
import { IExportFormData, schema } from './schema';

import './export-modal.scss';

interface IExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onExport: (fileName: string, dateRange?: [Date, Date]) => void | Promise<void>;
  hasDateRange?: boolean;
}

export const ExportModal = ({ isOpen, onClose, onExport, hasDateRange = true }: IExportModalProps) => {
  const formMethods = useForm<IExportFormData>({
    resolver: yupResolver(schema(hasDateRange)),
    mode: 'onChange',
  });
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    control,
    trigger,
    formState: { errors },
  } = formMethods;

  const fileName = watch('fileName');
  const dateRange = watch('dateRange');

  const onSubmit = (data: IExportFormData) => {
    const { fileName, dateRange } = data;
    onExport(fileName, dateRange);
    handleClose();
  };

  const handleClose = (): void => {
    clearErrors('fileName');
    setValue('fileName', '');
    onClose();
  };

  const isSubmitDisabled = (): boolean => {
    const hasentNameErrors = Boolean(errors?.fileName) || !fileName;
    const hasentDateRangeErrors = hasDateRange ? Boolean(errors?.dateRange) || !dateRange : false;

    return hasentNameErrors || hasentDateRangeErrors;
  };

  if (!isOpen) return null;
  return (
    <Modal>
      <div className="export-modal">
        <button type="button" className="export-modal__close-button" onClick={handleClose}>
          <Icon name="x" weight="bold" size="md" />
        </button>
        <span className="export-modal__title">Exportación masiva</span>
        <span className="export-modal__subtitle">
          Podrás descargar la exportación en formato CSV desde la sección de Exportaciones masivas.
        </span>
        <Form onSubmit={handleSubmit(onSubmit)} className="export-modal__form">
          <Form.Group>
            <span className="export-modal__label">Nombre de archivo</span>
            <Form.Input
              isInvalid={Boolean(errors?.fileName)}
              placeholder="Ingresa un nombre de archivo"
              {...register('fileName', {
                onBlur: () => trigger('fileName'),
              })}
            />
            <Form.HelperText isInvalid={Boolean(errors?.fileName)}>
              {errors?.fileName ? errors?.fileName?.message : FIELD_REQUIRED_ERROR}
            </Form.HelperText>
          </Form.Group>

          {hasDateRange && (
            <Form.Group>
              <span className="export-modal__label">Elige un rango de fechas para la exportación</span>
              <Controller
                name="dateRange"
                control={control}
                render={({ field: { ref, onBlur, ...restOfFields } }) => (
                  <DatePicker
                    inputId="dateRange"
                    selectRange
                    onBlur={() => {
                      onBlur();
                      trigger('dateRange');
                    }}
                    onClear={() => {
                      setValue('dateRange', undefined);
                    }}
                    hasIcon
                    inputRef={ref}
                    isInvalid={Boolean(errors?.dateRange)}
                    size="lg"
                    {...restOfFields}
                  />
                )}
              />
              <Form.HelperText className="export-modal__error" isInvalid={Boolean(errors?.dateRange)}>
                {errors?.dateRange ? errors?.dateRange?.message : FIELD_REQUIRED_ERROR}
              </Form.HelperText>
            </Form.Group>
          )}

          <div className="export-modal__action-buttons">
            <Button onClick={handleClose} variant="tertiary">
              Anular
            </Button>
            <Button disabled={isSubmitDisabled()} className="export-modal__confirm" type="submit">
              Confirmar
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
