import type { ICustomersListFilters } from 'core/model/interfaces/customers';
import { customersData } from './customers.data';
import { toGetCustomersParams, toICustomerForm, toIDisplayCustomers } from './customers.mappers';

export const customersService = {
  getCustomers: async (options: ICustomersListFilters) =>
    toIDisplayCustomers(await customersData.getCustomers(toGetCustomersParams(options))),

  getCustomerById: async (customerId: number) => toICustomerForm(await customersData.getCustomerById(customerId)),
};
