import * as yup from 'yup';
import {
  numberFieldMaxError,
  numberFieldMinError,
  TEXT_FIELD_REQUIRED_ERROR,
  TEXT_NUMBER_INT,
  TEXT_NUMBER_TYPO_ERROR,
} from 'core/constants/error-messages.constants';

const imageYup = yup
  .object()
  .shape({ blob: yup.mixed().nullable(), url: yup.string().trim().required(TEXT_FIELD_REQUIRED_ERROR) })
  .required(TEXT_FIELD_REQUIRED_ERROR);

export const schema = yup.object().shape({
  banners: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().required(TEXT_FIELD_REQUIRED_ERROR),
      desktopImage: imageYup,
      mobileImage: imageYup,
      dateRange: yup.array().of(yup.string()).nullable().required(TEXT_FIELD_REQUIRED_ERROR),
      viewOrder: yup
        .number()
        .typeError(TEXT_NUMBER_TYPO_ERROR)
        .nullable()
        .min(1, numberFieldMinError(1))
        .max(20, numberFieldMaxError(20))
        .test('viewOrder', TEXT_NUMBER_INT, name => {
          if (name) return Number.isInteger(name);
          return true;
        })
        .transform((_, value) => (value === '' ? null : Number(value)))
        .required(TEXT_FIELD_REQUIRED_ERROR),
      link: yup.string().trim().nullable(),
    }),
  ),
});
