import type {
  INavigationTreeDetail,
  INavigationTreeDetailCreateForm,
} from 'core/model/interfaces/navigation/navigation.interface';
import { g2mFunctionsService } from '../g2m-functions/g2m-functions.service';
import { navigationData } from './navigation.data';
import { IGetNavigationTreeParams } from './navigation.dto';
import { mapNavigationTreeToPostBody, mapNavigationTreeToPutBody, toINavigationTreeDetail } from './navigation.mappers';

export const navigationService = {
  getNavigationTrees: (params?: IGetNavigationTreeParams) => navigationData.getNavigationTrees(params ?? {}),

  getNavigationTreeById: async (id: number) => {
    const result = await navigationData.getNavigationTreeDetailById(id);
    return toINavigationTreeDetail(result);
  },

  createNavigationTree: async (data: INavigationTreeDetailCreateForm) => {
    if (data.categoryIcon.blob) {
      const { rejectedFiles, successfulFiles } = await g2mFunctionsService.uploadFiles({
        file: data.categoryIcon.blob,
        context: 'icons',
      });
      if (rejectedFiles.length !== 0) {
        throw new Error('Error subiendo la imagen');
      }
      const mappedBody = mapNavigationTreeToPostBody(data, successfulFiles[0].url);
      return navigationData.createNavigationTree(mappedBody);
    }
  },

  editNavigationTree: (id: number, data: INavigationTreeDetail) => {
    const body = mapNavigationTreeToPutBody(data);
    return navigationData.editNavigationTree(id, body);
  },
  deleteNavigationTree: (id: number) => navigationData.deleteNavigationTree(id),
  duplicateNavigationTree: (id: number) => navigationData.duplicateNavigationTree(id),
};
