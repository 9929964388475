import { Form, Text } from '@alicorpdigital/dali-components-react';
import type { ICustomerPaymentTypes } from 'core/model/interfaces/customers';
import {
  CustomerFormContainer,
  CustomerFormLayout,
  CustomerFormList,
  CustomerFormSubtitle,
  CustomerFormSwitchContainer,
} from 'screens/customers/customer-form/components';

interface ICustomerPaymentProps {
  title: string;
  paymentTypes: ICustomerPaymentTypes;
}
export const CustomerPayment = ({ paymentTypes: { credit, cash }, title }: ICustomerPaymentProps) => (
  <CustomerFormContainer type="outlined">
    <CustomerFormLayout smallGaps>
      <CustomerFormSubtitle>{title}</CustomerFormSubtitle>
      <CustomerFormContainer type="outlined">
        <CustomerFormLayout smallGaps>
          <CustomerFormSwitchContainer>
            <CustomerFormSubtitle>Pago al contado</CustomerFormSubtitle>
            <Form.Check type="switch" checked={cash.status} disabled>
              <Text as="span">{cash.status ? 'Activo' : 'Inactivo'}</Text>
            </Form.Check>
          </CustomerFormSwitchContainer>
          <CustomerFormList>
            {cash.paymentMethods?.map((method, index) => (
              <p key={index}>{method.description}</p>
            ))}
          </CustomerFormList>
        </CustomerFormLayout>
      </CustomerFormContainer>
      <CustomerFormContainer type="outlined">
        <CustomerFormLayout smallGaps>
          <CustomerFormSwitchContainer>
            <CustomerFormSubtitle>Pago a crédito</CustomerFormSubtitle>
            <Form.Check type="switch" checked={credit.status} disabled>
              <Text as="span">{credit.status ? 'Activo' : 'Inactivo'}</Text>
            </Form.Check>
          </CustomerFormSwitchContainer>

          <CustomerFormLayout>
            <Form.Group>
              <Form.Label>Importe de linea de crédito</Form.Label>
              <Form.Input value={credit.creditLineAmount} disabled />
            </Form.Group>
            <Form.Group>
              <Form.Label>Importe de la linea de crédito disponible</Form.Label>
              <Form.Input value={credit.availableCreditLineAmount} disabled />
            </Form.Group>
            <Form.Group>
              <Form.Label>Importe de la linea de crédito comprometida</Form.Label>
              <Form.Input value={credit.busyAvailableCreditLineAmount} disabled />
            </Form.Group>
            <Form.Group>
              <Form.Label>Plazo de pago</Form.Label>
              <Form.Input value={credit.paymentTerm} disabled />
            </Form.Group>
            <Form.Group>
              <Form.Label>Motivo de deuda</Form.Label>
              <Form.Input value={credit.debtReason} disabled />
            </Form.Group>
          </CustomerFormLayout>
        </CustomerFormLayout>
      </CustomerFormContainer>
    </CustomerFormLayout>
  </CustomerFormContainer>
);
