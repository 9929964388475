import type { IOrdersState } from './types';

export const SLICE_NAMESPACE = 'orders';

export const initialState: IOrdersState = {
  orders: [],
  areFiltersApplied: false,
  query: '',
  currentPage: 1,
  totalPages: 0,
  isLoading: false,
  error: '',
};
