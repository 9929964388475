import type { IDeliveryConditionFilters } from 'core/model/interfaces/delivery-condition';
import type { TSliceReducer } from 'core/store';
import type { IDeliveryConditionState } from './types';

const updateFilters: TSliceReducer<IDeliveryConditionState, Partial<IDeliveryConditionFilters>> = (state, action) => {
  state.currentPage = 1;
  state.filters = {
    ...state.filters,
    ...action.payload,
  };
};

export const reducers = { updateFilters };
