import React, { ReactNode } from 'react';
import { useCSS } from '@insuma/mpp-ui/hooks';

import './distributor-section.scss';

interface IDistributorSectionProps {
  subtitle: string;
  description?: string;
  children: ReactNode;
}

export const DistributorSection: React.FC<IDistributorSectionProps> = ({
  subtitle,
  description,
  children,
}: IDistributorSectionProps) => {
  const css = useCSS('distributor-section');

  return (
    <div className={css()}>
      <h5 className={css('subtitle')}>{subtitle}</h5>
      {description && <p className={css('description')}>{description}</p>}
      {children}
    </div>
  );
};
