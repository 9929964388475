import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { initialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

const navigationSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState,
  reducers,
  extraReducers,
});

export const { updateQuery } = navigationSlice.actions;

export default navigationSlice.reducer;
