import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import { INavigationTreeListItem } from 'core/model/interfaces/navigation/navigation.interface';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useNavigationTrees = () => {
  const [navigationTrees, setNavigationTrees] = useState<Array<INavigationTreeListItem>>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchNavigationTrees = async () => {
      try {
        setLoading(true);
        const { trees } = await apiService.navigation.getNavigationTrees();
        setNavigationTrees(trees);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo las opciones de arbol de navegación'));
      } finally {
        setLoading(false);
      }
    };

    fetchNavigationTrees();
  }, []);

  return {
    navigationTrees,
    loading,
    error,
  };
};
