import type { IDeliveryCondition } from 'core/model/interfaces/delivery-condition';
import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import type {
  IGetDeliveryConditionsParams,
  IGetDeliveryConditionsResponse,
  TCreateDeliveryCondition,
  TEditDeliveryCondition,
} from './delivery-condition.dto';

const deliveryConditionsBaseURL = '/gtm-logistic-management/v1/delivery-configurations';

export const deliveryConditionData = {
  getDeliveryConditions: async (options?: IGetDeliveryConditionsParams): Promise<IGetDeliveryConditionsResponse> => {
    const { data } = await https.get<IServiceSuccess<IGetDeliveryConditionsResponse>>(deliveryConditionsBaseURL, {
      params: options,
    });
    return data.data;
  },
  removeDeliveryCondition: async (id: number): Promise<void> => {
    const { data } = await https.delete<void>(`${deliveryConditionsBaseURL}/${id}`);
    return data;
  },
  getDeliveryConditionById: async (id: string): Promise<IDeliveryCondition> => {
    const { data } = await https.get<IServiceSuccess<IDeliveryCondition>>(`${deliveryConditionsBaseURL}/${id}`);
    return data.data;
  },
  createDeliveryCondition: async (options: TCreateDeliveryCondition): Promise<void> =>
    https.post(deliveryConditionsBaseURL, options),
  updateDeliveryCondition: async ({ id, ...options }: TEditDeliveryCondition): Promise<void> =>
    https.put(`${deliveryConditionsBaseURL}/${id}`, options),
};
