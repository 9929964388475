import { DEFAULT_CURRENCY } from 'core/constants/currency.constants';
import type { IDisplayOrdersList, IOrderDetailDisplay } from 'core/model/interfaces/orders';
import { createDate, formatDate } from 'shared/utils/date.utils';
import type { IGetOrderDetailResponse, IGetOrdersListDTO } from './orders.dto';

export const toDisplayOrdersList = (response: IGetOrdersListDTO): IDisplayOrdersList => ({
  pagination: response.pagination,
  orders: response.orders.map(order => ({
    orderId: order.orderId,
    businessName: order.businessName,
    orderNumber: order.orderNumber,
    orderDate: order.orderDate,
    totalAmount: order.totalAmount,
    status: order.status,
  })),
});

export const toDisplayOrderDetail = (response: IGetOrderDetailResponse): IOrderDetailDisplay => ({
  orderInfo: {
    orderNumber: response.orderNumber,
    purchaseDate: formatDate(response.createdAt, 'DD/MM/YYYY'),
    deliveryDate: formatDate(createDate(response.deliveryDate), 'DD/MM/YYYY'),
  },
  orderStatusDetails: {
    status: response.status,
    deliveryAddress: response.address,
    paymentMethod: response.paymentMethod,
  },
  customer: {
    businessName: response.businessName,
    userId: response.userId,
  },
  orderCard: {
    id: response.orderNumber,
    paymentType: response.paymentMethod,
    address: response.address,
    deliveryDate: response.deliveryDate,
    descriptionTotalDiscount: 'Total de promociones',
    totalDiscount: response.totalDiscountAmount,
    currencySymbol: DEFAULT_CURRENCY.symbol,
    subTotal: response.subtotalAmount,
    perception: response.perceptionAmount,
    finalTotal: response.totalAmount,
    totalCountProducts: response.totalQuantityProduct,
    products: response.items.map(product => ({
      id: product.externalProductId,
      imageUrl: product.image,
      displayName: product.productName,
      presentation: product.presentation,
      currencySymbol: DEFAULT_CURRENCY.symbol,
      unitPrice: product.price,
      quantity: product.quantity,
      finalPrice: product.totalAmount,
      presentationType: product.unitMeasureCode,
      promos: product.promotions.map(promo => ({ description: promo.description, totalValue: promo.discount })),
    })),
  },
});
