import { EExportsStates } from 'core/model/enums/exports.enum';
import type { IExportsListDisplay } from 'core/model/interfaces/exports';
import { formatDate } from 'shared/utils/date.utils';
import type { IExportDTO, IGetExportsResponseDTO } from './exports.dto';

export const exportListDTOToExportListDisplay = (response: IGetExportsResponseDTO): IExportsListDisplay => ({
  exportList: response.exportList.map((exportDTO: IExportDTO) => ({
    createdAt: formatDate(exportDTO.createdAt, 'DD/MM/YYYY hh:mm a'),
    fileUrl: exportDTO.fileUrl,
    name: exportDTO.name,
    status: exportDTO.status,
    isDownloadEnabled: !!exportDTO.fileUrl && exportDTO.status === EExportsStates.GENERATED,
  })),
  pagination: response.pagination,
});
