import { Button } from '@alicorpdigital/dali-components-react';
import type { IColumnProps } from '@insuma/mpp-ui/components/table';
import { EApplyToOption } from 'core/model/enums/distributor.enum';
import type { IFormDataMinimumPurcharse, TDisplayDistributorList } from 'core/model/interfaces/distributors';

export const defaultMinimumPurchase: IFormDataMinimumPurcharse = {
  isNew: true,
  isEditing: true,
  applyTo: EApplyToOption.CART,
  customers: '',
  externalSalesForce: '',
  priceGroup: [],
  customerPriceList: [],
  zones: [],
  amount: 0,
  amountAlicorp: 0,
  amountAllied: 0,
};

export const distributorColumns = (
  onEdit: (branchCode: string, distributorCode: string) => void,
): Array<IColumnProps<TDisplayDistributorList>> => [
  { header: 'Nombre de operador', accessor: 'branchName' },
  { header: 'Fecha de última modificación', accessor: 'dateUpdate' },
  {
    header: '',
    accessor: 'events',
    render: row => (
      <Button
        size="sm"
        isIconOnly
        iconName="pencil"
        variant="tertiary"
        onClick={() => onEdit(row.branchCode, row.distributorCode)}
      />
    ),
  },
];
