import { useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import { SUPPORTED_FORMAT } from 'core/constants/import-modal.constants';
import { apiService } from 'core/services';
import { ImportModal } from 'shared/components/import-modal';
import { downloadFileFromUrl } from 'shared/utils/download-file.utils';

interface IImportCSVProps {
  setIsImportModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isImportModalOpen: boolean;
  toggleImportModal: () => void;
  setIsReload(state: boolean): void;
  templateUrl?: string;
}
export const ImportCSV = ({
  setIsImportModalOpen,
  isImportModalOpen,
  toggleImportModal,
  setIsReload,
  templateUrl,
}: IImportCSVProps) => {
  const [isImportLoading, setIsImportLoading] = useState(false);

  const handleImportAgree = async (file: File | null) => {
    setIsImportLoading(true);
    let success = false;
    if (file) {
      try {
        await apiService.g2mFunctions.uploadFiles({ file, context: 'suggested-products' });
        notification.success('Tu archivo ha sido importado con éxito.');
        success = true;
      } catch (error) {
        notification.error('Error al actualizar información, intentar nuevamente.');
      } finally {
        setIsImportModalOpen(false);
        setIsImportLoading(false);
        if (success) setIsReload(true);
      }
    } else {
      notification.error('Error al actualizar información, intentar nuevamente.');
      setIsImportModalOpen(false);
      setIsImportLoading(false);
      throw Error('File should not be undefined or null');
    }
  };

  const handleDownloadTemplate = async () => {
    if (!templateUrl) throw new Error('templateUrl is required');
    const urls = await apiService.g2mFunctions.getDownloadUrls([templateUrl]);
    if (urls) downloadFileFromUrl(urls[0]);
  };

  return (
    <ImportModal
      title="Actualizar CSV"
      isOpen={isImportModalOpen}
      onClose={toggleImportModal}
      onAgree={handleImportAgree}
      onDownloadTemplate={handleDownloadTemplate}
      isLoading={isImportLoading}
      maxFileSizeMB={25}
      filePickerHelperText="Tamaño de archivo CSV máximo 25 MB"
      fileFormat={SUPPORTED_FORMAT}
    />
  );
};
