import { useFormContext } from 'react-hook-form';
import { Form, FormGroup } from '@alicorpdigital/dali-components-react';

import './grouping-rules-name.scss';

const GroupingRulesName = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <div className="row-container">
      <h4 className="row-container__subtitle">1. Datos de la agrupación</h4>
      <div className="grouping-name">
        <FormGroup>
          <Form.Label>Nombre de la agrupación</Form.Label>
          <Form.Input {...register('deliveryGroupingRuleName')} />
          {errors.deliveryGroupingRuleName && (
            <Form.HelperText isInvalid>{errors.deliveryGroupingRuleName.message}</Form.HelperText>
          )}
        </FormGroup>
      </div>
    </div>
  );
};

export default GroupingRulesName;
