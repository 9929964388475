import { useEffect } from 'react';
import { DefaultValues, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@insuma/mpp-ui/components/icon';
import * as yup from 'yup';
import { Modal } from 'shared/components/modal';
import { HierarchyCategoryMainFields as MainFields } from './components/hierarchy-category-form-main-fields';
import { HierarchyCategorySubcategoryFields as SubcategoryFields } from './components/hierarchy-category-form-subcategory-fields';
import { HierarchyCategorySubmitButton as SubmitButton } from './components/hierarchy-category-form-submit-button';
import { HierarchyCategoryTitle as Title } from './components/hierarchy-category-form-title';

import './hierarchy-category-modal-form.scss';

interface IHierarchyCategoryModalFormProps<T extends FieldValues> {
  children: React.ReactNode;
  defaultValues: DefaultValues<T>;
  schema: yup.AnyObjectSchema;
  onSubmit: (data: T) => void;
  hierarchyCategory?: T;
  onClose(): void;
  isButtonLoading: boolean;
}
export const HierarchyCategoryModalForm = <T extends FieldValues>({
  children,
  onSubmit,
  defaultValues,
  schema,
  hierarchyCategory,
  onClose,
  isButtonLoading,
}: IHierarchyCategoryModalFormProps<T>) => {
  const formMethods = useForm<T>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (hierarchyCategory) reset(hierarchyCategory);
  }, [hierarchyCategory, reset]);

  return (
    <FormProvider {...formMethods}>
      <Modal position="top">
        <div className="hierarchy-category-modal-form">
          <div className="hierarchy-category-modal-form__top-actions">
            <button
              className="hierarchy-subcategory-modal-form__close-icon"
              type="button"
              onClick={onClose}
              disabled={isButtonLoading}
            >
              <Icon size="lg" name="x" />
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} aria-label="Formulario de categoría">
            {children}
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
};

HierarchyCategoryModalForm.MainFields = MainFields;
HierarchyCategoryModalForm.SubcategoryFields = SubcategoryFields;
HierarchyCategoryModalForm.Title = Title;
HierarchyCategoryModalForm.SubmitButton = SubmitButton;
