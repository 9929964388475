import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { IZones } from 'core/model/interfaces/master-entities';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useZones = () => {
  const [externalZoneList, setExternalZoneList] = useState<Array<IZones>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchZones = async () => {
      setLoading(true);

      try {
        const externalZoneList = await apiService.masterEntities.getZones();
        setExternalZoneList(externalZoneList);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo las opciones de categorias'));
      } finally {
        setLoading(false);
      }
    };

    fetchZones();

    return () => {
      setExternalZoneList([]);
    };
  }, []);

  return { externalZoneList, loading, error };
};
