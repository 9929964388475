import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { useAuth } from 'core/hooks/auth/use-auth.hooks';
import { Header } from 'shared/components/header';
import { SideNav } from 'shared/components/side-nav';
import { menuLinks } from './main-layout.contants';

import './main-layout.scss';

export interface IMainLayoutProps {
  children: React.ReactNode;
  maxWidthEnabled?: boolean;
}

export const MainLayout = ({ children, maxWidthEnabled = false }: IMainLayoutProps) => {
  const css = useCSS('main-layout');
  const { pathname } = useLocation();
  const { name, username } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const toogleSidebar = () => setSidebarOpen(!sidebarOpen);

  const userFullName = name || username;
  const closed = !sidebarOpen;

  return (
    <div className={css()}>
      <header className={css('header')}>
        <Header onButtonClick={toogleSidebar} buttonClicked={sidebarOpen} userFullName={userFullName} />
      </header>
      <div className={css('container')}>
        <aside className={css('sidebar', { closed })}>
          <SideNav userFullName={userFullName} currentPath={pathname} items={menuLinks} />
        </aside>
        <div className={css('content-wrapper')}>
          <div className={css('content', { 'max-content': maxWidthEnabled })}>{children}</div>
        </div>
      </div>
    </div>
  );
};
