import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { SnackbarGlobal } from '@insuma/mpp-ui/components/snackbar';
import { ThemeProvider } from '@insuma/mpp-ui/themes';
import { PersistGate } from 'redux-persist/integration/react';
import msalPublicClientApplication from 'core/constants/msal.constants';
import { diadiaTheme } from 'core/constants/themes.constants';
import store, { persistor } from 'core/store';
import { VersionBadge } from 'shared/components/version-badge';
import 'shared/utils/yup.utils';
import Router from './AppRouter';

const App = () => (
  <>
    <VersionBadge />
    <MsalProvider instance={msalPublicClientApplication}>
      <ThemeProvider theme={diadiaTheme}>
        <SnackbarGlobal />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </MsalProvider>
  </>
);

export default App;
