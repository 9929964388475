import React from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DndSortableItem } from './dnd-sortable-item';

interface IDndTreeListProps<T> {
  items: Array<T>;
  renderItem(item: T, index: number): React.ReactElement;
  onChange?(items: Array<T>): void;
  onDragStart?(): void;
  onDragEnd?(): void;
}

export const DndSortable = <T extends { id: string }>({
  items,
  renderItem,
  onChange,
  onDragStart,
  onDragEnd,
}: IDndTreeListProps<T>) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = () => {
    if (onDragStart) onDragStart();
  };

  const handleDragEnd = (e: DragEndEvent) => {
    if (onDragEnd) onDragEnd();

    const { active, over } = e;
    if (active.id !== over?.id) {
      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over?.id);

      const newItems = arrayMove(items, oldIndex, newIndex);
      if (onChange) onChange(newItems);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext strategy={verticalListSortingStrategy} items={items}>
        {items.map((item, index) => (
          <DndSortableItem key={item.id} id={item.id}>
            {renderItem(item, index)}
          </DndSortableItem>
        ))}
      </SortableContext>
    </DndContext>
  );
};
