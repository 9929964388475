import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { deliveryConditionRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import type { TDeliveryConditionFormData } from 'core/model/interfaces/delivery-condition';
import { apiService } from 'core/services';
import { Loading } from 'shared/components/loading';
import { getErrorFromService } from 'shared/utils/https';
import { DeliveryConditionForm } from '../components/delivery-condition-form/delivery-condition-form';
import { useDeliveryConditionDetail } from '../hooks/use-delivery-condition-detail.hooks';

export const EditDeliveryCondition = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const route = `${logisticsRoutePath}${deliveryConditionRoutePath}`;

  const { deliveryCondition, loading, error } = useDeliveryConditionDetail(id);
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async (data: TDeliveryConditionFormData) => {
    if (!id) throw new Error('Id should not be undefined or null');
    try {
      setIsSaving(true);
      await apiService.deliveryCondition.updateDeliveryCondition({
        ...data,
        id: Number(id),
      });

      notification.success('Condición de entrega editada exitosamente');
      setIsSaving(false);
      navigate(route);
    } catch (error) {
      setIsSaving(false);
      notification.error(getErrorFromService(error));
    }
  };

  if (loading) return <Loading />;
  if (error || !deliveryCondition) return <Navigate to={route} />;

  return (
    <DeliveryConditionForm deliveryCondition={deliveryCondition} onSubmit={onSubmit} isSaving={isSaving} mode="edit" />
  );
};
