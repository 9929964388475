import { Form } from '@alicorpdigital/dali-components-react';
import type { ICustomerTaxData } from 'core/model/interfaces/customers';
import { CustomerFormLayout } from 'screens/customers/customer-form/components';

interface ICustomerTaxesDataProps {
  taxData: ICustomerTaxData;
}
export const CustomerTaxesData = ({ taxData: { affectedByIGV, perceptionAgent } }: ICustomerTaxesDataProps) => (
  <CustomerFormLayout columns="two">
    <Form.Group>
      <Form.Label>Afecto a IGV</Form.Label>
      <Form.Input value={affectedByIGV} disabled />
    </Form.Group>
    <Form.Group>
      <Form.Label>Agente de Percepción</Form.Label>
      <Form.Input value={perceptionAgent} disabled />
    </Form.Group>
  </CustomerFormLayout>
);
