import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import { DEFAULT_DELIVERY_CONDITION } from 'core/constants/logistics.constants';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useDeliveryConditionDetail = (id?: string) => {
  const [deliveryCondition, setDeliveryCondition] = useState(DEFAULT_DELIVERY_CONDITION);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown | null>(null);

  useEffect(() => {
    const fetchDeliveryCondition = async () => {
      if (!id) throw new Error('Id should not be undefined or null');
      try {
        setLoading(true);
        const remoteDeliveryCondition = await apiService.deliveryCondition.getDeliveryConditionById(id);
        setDeliveryCondition(remoteDeliveryCondition);
        setLoading(false);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error));
      } finally {
        setLoading(false);
      }
    };

    fetchDeliveryCondition();
  }, [id]);

  return {
    deliveryCondition,
    loading,
    error,
  };
};
