import { useFormContext } from 'react-hook-form';
import { Form, FormGroup, InputProps } from '@alicorpdigital/dali-components-react';

interface IInputValueFormProps extends InputProps {
  label: string;
  className?: string;
  name: string;
}

export const InputValueForm = ({ label, className, onChange, name, type = 'text', ...rest }: IInputValueFormProps) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <FormGroup className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.Input
        type={type}
        isInvalid={Boolean(errors?.[name])}
        {...register(
          name,
          onChange && {
            onChange,
          },
        )}
        name={name}
        aria-label={label}
        {...rest}
      />
      {errors?.[name] && <Form.HelperText isInvalid>{errors[name]?.message}</Form.HelperText>}
    </FormGroup>
  );
};
