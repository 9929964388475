import { IGetDeliveryConditionsResponse } from 'core/services/delivery-condition/delivery-condition.dto';
import { deliveryConditionService } from 'core/services/delivery-condition/delivery-condition.service';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.helpers';
import { SLICE_NAMESPACE } from '../constants';
import type { IDeliveryConditionState } from '../types';

export const fetchDeliveryConditions = createAppAsyncThunk<
  IGetDeliveryConditionsResponse,
  { page: number } | undefined
>(`${SLICE_NAMESPACE}/fetchDeliveryConditions`, async (args, { getState }) => {
  const {
    deliveryConditions: { filters, currentPage },
  } = getState();

  return deliveryConditionService.getDeliveryConditions({
    ...filters,
    page: args?.page ?? currentPage,
    itemsPerPage: 10,
  });
});

export const fetchDeliveryConditionsReducer: TSliceExtraReducer<IDeliveryConditionState> = builder =>
  builder
    .addCase(fetchDeliveryConditions.pending, state => {
      state.isLoading = true;
    })
    .addCase(fetchDeliveryConditions.rejected, state => {
      state.isLoading = false;
    })
    .addCase(fetchDeliveryConditions.fulfilled, (state, action) => {
      state.deliveryConditions = action.payload.deliveryConfigurations;
      state.totalPages = action.payload.pagination.totalPages;
      state.currentPage = action.payload.pagination.currentPage;
      state.isLoading = false;
    });
