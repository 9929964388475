import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import type { IGetOrderDetailResponse, IGetOrderRequest, IGetOrdersListDTO, IGetOrdersRequest } from './orders.dto';

const orderBaseURL = '/gtm-order-management/v1/order-maintenance';

export const ordersData = {
  getOrders: async (params: IGetOrdersRequest): Promise<IGetOrdersListDTO> => {
    const {
      data: { data },
    } = await https.get<IServiceSuccess<IGetOrdersListDTO>>(orderBaseURL, {
      params,
    });

    return data;
  },

  getOderDetail: async ({ orderId }: IGetOrderRequest): Promise<IGetOrderDetailResponse> => {
    const {
      data: { data },
    } = await https.get<IServiceSuccess<IGetOrderDetailResponse>>(`${orderBaseURL}/${orderId}`);

    return data;
  },
};
