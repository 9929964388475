import { useNavigate } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { staticPagesRoutePath } from 'core/constants/routes.constants';
import { staticPagesService } from 'core/services/static-pages/static-pages.service';
import { getErrorFromService } from 'shared/utils/https';
import type { IStaticPageFormData } from '../static-pages-form/schema';
import { StaticPageForm } from '../static-pages-form/static-pages-form';

export const CreateStaticPage = () => {
  const navigate = useNavigate();

  const onSubmit = ({ images, ...values }: IStaticPageFormData) => {
    const createStaticPage = async () => {
      try {
        await staticPagesService.createStaticPage({ ...values, imagesUrls: [] });
        notification.success('Página estática creada exitosamente');
        navigate(staticPagesRoutePath);
      } catch (error) {
        notification.error(getErrorFromService(error, 'Error creando página estática'));
      }
    };

    createStaticPage();
  };

  return <StaticPageForm mode="create" onSubmit={onSubmit} />;
};
