import { IProductFormData } from 'screens/products/product-management/product-form/product-form.schema';
import { productsData } from './products.data';
import type { IProductsOptions } from './products.dto';
import { productDetailDTOToProductDetailForm, productDetailFormToProductDetailUpdateDTO } from './products.mappers';

export const productsService = {
  getProducts: (options: IProductsOptions) => productsData.getProducts(options),

  getProductById: async (sku: string) => productDetailDTOToProductDetailForm(await productsData.getProductById(sku)),

  updateProduct: (sku: string, formData: IProductFormData) =>
    productsData.updateProduct(productDetailFormToProductDetailUpdateDTO(sku, formData)),
};
