import { useState } from 'react';
import {
  IHierarchyTreeCategory,
  IHierarchyTreeSubcategory,
} from 'core/model/interfaces/navigation/hierarchy.interface';

interface IUseHierarchySubcategoryModalsProps {
  setSelectedCategory: React.Dispatch<React.SetStateAction<IHierarchyTreeCategory | null>>;
  setSelectedSubcategory: React.Dispatch<React.SetStateAction<IHierarchyTreeSubcategory | null>>;
}
export const useHierarchySubcategoryModal = ({
  setSelectedCategory,
  setSelectedSubcategory,
}: IUseHierarchySubcategoryModalsProps) => {
  const [isSubcategoryModalOpen, setSubcategoryModalOpen] = useState(false);

  const openCreateSubcategoryModal = (category: IHierarchyTreeCategory) => {
    setSubcategoryModalOpen(true);
    setSelectedCategory(category);
  };

  const openEditSubcategoryModal = (subcategory: IHierarchyTreeSubcategory, category: IHierarchyTreeCategory) => {
    setSubcategoryModalOpen(true);
    setSelectedCategory(category);
    setSelectedSubcategory(subcategory);
  };

  const closeSubcategoryModal = () => {
    setSubcategoryModalOpen(false);
    setSelectedCategory(null);
    setSelectedSubcategory(null);
  };

  return {
    isSubcategoryModalOpen,
    closeSubcategoryModal,
    openCreateSubcategoryModal,
    openEditSubcategoryModal,
    setSelectedSubcategory,
  };
};
