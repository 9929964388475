import { PublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { customAlphabet } from 'nanoid';
import { spsecMessagesCodesError, UNEXPECTED_ERROR_MESSAGE } from 'core/constants/http.constants';
import msalPublicClientApplication, { msalScopes } from 'core/constants/msal.constants';
import type { IServiceError } from 'core/model/interfaces/service.interface';
import { env, isTestingEnv } from '../env.utils';
import { composePromises } from '../function.utils';
import monitoringInstance from '../monitoring-provider.utils';

export const acquireAccessToken = async (msalInstance: PublicClientApplication) => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0)
    throw new Error('Unauthenticated user is trying to acquire an access token');
  try {
    const token = await msalInstance.acquireTokenSilent({
      account: activeAccount || accounts[0],
      scopes: msalScopes,
    });
    return token.accessToken;
  } catch (error) {
    // eslint-disable-next-line no-console
    if (!isTestingEnv()) console.warn(`Token not found error ${error}`);
    await msalInstance.acquireTokenRedirect({
      account: activeAccount || accounts[0],
      scopes: msalScopes,
    });
  }
};

export const injectToken = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const newConfig = { ...config };

  const token = await acquireAccessToken(msalPublicClientApplication);

  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }
  return newConfig;
};

export const injectTraceParent = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const newConfig = { ...config };
  if (!monitoringInstance.checkIsMonitoringEnabled()) newConfig.headers.Traceparent = getTraceparent();
  return newConfig;
};

export const injectHeaders = composePromises<InternalAxiosRequestConfig>(injectToken, injectTraceParent);

const shouldAvoidNotification = (message: string, response?: AxiosResponse) =>
  spsecMessagesCodesError.includes(response?.data?.code) || message === 'canceled';

export const handleCommonErrors = ({ response, message: messageAxios }: AxiosError<IServiceError>) => {
  if (shouldAvoidNotification(messageAxios, response)) return;
  const message = response?.data?.error?.message || UNEXPECTED_ERROR_MESSAGE;
  // eslint-disable-next-line no-console
  if (!isTestingEnv()) console.error(`Request error`, message);
  // Disabling for excesive notifications
  // notification.error(message);
};

export const getErrorFromService = (error: unknown, defaultErrorMessage?: string) => {
  let errorMessage = defaultErrorMessage || 'Error inesperado';

  if (axios.isAxiosError<IServiceError>(error)) {
    const serviceError = error.response?.data?.error;
    if (serviceError?.message) errorMessage = serviceError.message;
  }

  return errorMessage;
};

export const getTraceparent = () => {
  const nanoid = customAlphabet('0123456789abcdef', 10);
  const version = env('VITE_TRACEPARENT_VERSION_ID');
  const traceId = nanoid(32);
  const id = nanoid(16);
  const flags = env('VITE_TRACEPARENT_FLAGS_ID');

  return `${version}-${traceId}-${id}-${flags}`;
};
