import { useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { IHierarchyTreeCategoryCreateForm } from 'core/model/interfaces/navigation/hierarchy.interface';
import { apiService } from 'core/services';
import { handleErrorMessage } from 'shared/utils/errors.utils';
import { HierarchyCategoryModalForm } from '../hierarchy-category-modal-form';
import { defaultValues } from './create-hierarchy-category-modal-form.constants';
import { schema } from './create-hierarchy-category-modal-form.schema';

export interface ICreateHierarchyCategoryModalProps {
  onClose(): void;
  fetchTreeHierarchy: () => void;
  treeId: string;
}

export const CreateHierarchyCategoryModal = ({
  onClose,
  fetchTreeHierarchy,
  treeId,
}: ICreateHierarchyCategoryModalProps) => {
  const [isButtonLoading, setButtonLoading] = useState(false);

  const onCreate = async (data: IHierarchyTreeCategoryCreateForm) => {
    try {
      setButtonLoading(true);
      await apiService.hierarchy.createHierachyCategory({ ...data, treeId });
      setButtonLoading(false);
      notification('Categoría creada exitosamente');
      onClose();
      await fetchTreeHierarchy();
    } catch (error) {
      setButtonLoading(false);
      notification.error(handleErrorMessage(error, 'Hubo un error creando la categoría'));
    }
  };
  return (
    <HierarchyCategoryModalForm
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={onCreate}
      onClose={onClose}
      isButtonLoading={isButtonLoading}
    >
      <HierarchyCategoryModalForm.Title text="Crear categoría" />
      <HierarchyCategoryModalForm.MainFields />
      <HierarchyCategoryModalForm.SubcategoryFields treeId={treeId} />
      <HierarchyCategoryModalForm.SubmitButton isDisabled={isButtonLoading} />
    </HierarchyCategoryModalForm>
  );
};
