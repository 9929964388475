import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import type { IGetCustomerResponseDTO, IGetCustomersParams, IGetCustomersResponseDTO } from './customers.dto';

const baseURL = '/gtm-user-management/v1/users';

export const customersData = {
  getCustomers: async (params: IGetCustomersParams): Promise<IGetCustomersResponseDTO> => {
    const {
      data: { data },
    } = await https.get<IServiceSuccess<IGetCustomersResponseDTO>>(baseURL, {
      params,
    });

    return data;
  },

  getCustomerById: async (clientId: number): Promise<IGetCustomerResponseDTO> => {
    const {
      data: { data },
    } = await https.get<IServiceSuccess<IGetCustomerResponseDTO>>(`${baseURL}/${clientId}/profile`);

    return data;
  },
};
