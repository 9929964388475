import { Button } from '@alicorpdigital/dali-components-react';
import type { IColumnProps } from '@insuma/mpp-ui/components/table';
import { ENGLISH_DAYS, SPANISH_DAYS, TEnglishDay, TSpanishDay } from 'core/constants/logistics.constants';
import {
  IDisplayDeliveryCondition,
  IDropdownDeliveryOptions,
  TDeliveryByShoppingDayMap,
} from 'core/model/interfaces/delivery-condition';
import { MainListItem } from 'shared/components/main-list-item';
import { getDeliveryOptions } from './get-delivery-options/get-delivery-options';

function getNextDeliveryDay(shoppingDay: TSpanishDay, quantity: number): TSpanishDay {
  const shoppingNumberDay = SPANISH_DAYS.findIndex(d => d === shoppingDay);
  const nextDayIndex = (shoppingNumberDay + quantity) % 7;
  return SPANISH_DAYS[nextDayIndex];
}

export function getDropdownDeliveryOptions(deliveryDays: Array<TEnglishDay>): Array<IDropdownDeliveryOptions> {
  const deliveryOptions = getDeliveryOptions(deliveryDays);
  const deliveryDayData: Array<IDropdownDeliveryOptions> = deliveryOptions.map(deliveryOption => {
    const beforeOptions = deliveryOption.beforeOptions.map(opt => ({
      id: opt.toString(),
      content: `+${opt} ${opt > 1 ? 'Días' : 'Día'} (${getNextDeliveryDay(deliveryOption.day, opt)})`,
    }));
    const afterOptions = deliveryOption.afterOptions.map(opt => ({
      id: opt.toString(),
      content: `+${opt} ${opt > 1 ? 'Días' : 'Día'} (${getNextDeliveryDay(deliveryOption.day, opt)})`,
    }));
    return {
      day: deliveryOption.day,
      beforeOptions,
      afterOptions,
    };
  });
  return deliveryDayData;
}

export function resetDeliveryMap(
  deliveryOptions: Array<IDropdownDeliveryOptions>,
  currentMap: TDeliveryByShoppingDayMap,
): TDeliveryByShoppingDayMap {
  const copyCurrentMap = structuredClone(currentMap) as TDeliveryByShoppingDayMap;
  deliveryOptions.forEach(option => {
    const dayCode = mapSpanishDayToEnglishDay(option.day);
    copyCurrentMap[dayCode].additionalDaysBeforeCutoffTime = Number(option.beforeOptions[0].id);
    copyCurrentMap[dayCode].additionalDaysAfterCutoffTime = Number(option.afterOptions[0].id);
  });
  return copyCurrentMap;
}

export function mapSpanishDayToEnglishDay(day: TSpanishDay): TEnglishDay {
  const dayIndex = SPANISH_DAYS.findIndex(d => d === day);
  return ENGLISH_DAYS[dayIndex];
}

export const getDeliveryConditionColumns = (
  onEdit: (id: number) => void,
  onDelete: (row: IDisplayDeliveryCondition) => void,
): Array<IColumnProps<IDisplayDeliveryCondition>> => [
  {
    header: 'Nombre de condición de entrega',
    accessor: 'deliveryConfigurationName',
    render: (row: IDisplayDeliveryCondition) =>
      row.default ? (
        <MainListItem name={row.deliveryConfigurationName} />
      ) : (
        <span>{row.deliveryConfigurationName}</span>
      ),
  },
  {
    header: '',
    accessor: 'events',
    render: (row: IDisplayDeliveryCondition) => (
      <div className="delivery-conditions-list__table-actions">
        <Button onClick={() => onEdit(row.id)} size="sm" isIconOnly iconName="pencil" variant="tertiary">
          Editar
        </Button>
        {!row.default ? (
          <Button size="sm" isIconOnly iconName="trash" variant="tertiary" onClick={() => onDelete(row)}>
            Eliminar
          </Button>
        ) : null}
      </div>
    ),
  },
];
